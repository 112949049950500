import Checkbox from 'antd/lib/checkbox';

type PropsType = {
    name: string,
    id?: string,
    checked: boolean,
    title?: string,
    onChange: (e: any) => void
}

const CustomCheckbox1: React.FC<PropsType> = ({ ...props }) => {
    return (
        <Checkbox
            name={props.name}
            checked={props.checked}
            onChange={props.onChange}
            title={props.title}
            id={props.id}
        />
    )
}

export default CustomCheckbox1;