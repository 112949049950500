import React from 'react';
import {Tab, TabList, TabPanel, Tabs} from "react-tabs";
import s from "../../../styles/styles.module.css";
import {motion} from "framer-motion";
import ExchangeRates from "./ExchangeRates/ExchangeRates";
import CurrencyExchange from "./CurrencyExchange/CurrencyExchange";

const Currency = () => {
    return (
        <motion.div
            initial={{opacity: 0, scale: 1}}
            animate={{opacity: 1, scale: 1}}
            transition={{duration: 0.5}}
        >
            <Tabs>
                <TabList className={s.tabstitle_block}>
                    <Tab selectedClassName={s.active_tab} className={s.tabtitle}>Exchange Rates</Tab>
                    <Tab selectedClassName={s.active_tab} className={s.tabtitle}>Currency Exchange</Tab>
                </TabList>

                <TabPanel>
                    <ExchangeRates/>
                </TabPanel>
                <TabPanel>
                    <CurrencyExchange/>
                </TabPanel>
            </Tabs>
        </motion.div>
    );
};

export default Currency;
