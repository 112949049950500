import React from 'react';
import s from "./ContactsForAgents.module.css";
import ContactUsForm from "../../../components/ContactUsForm/ContactUsForm";
import bg from "../../../assets/img/contacts_for_agents_bg.jpg";

const ContactsForAgents = () => {
    return (
        <div className={s.wrapper}>
            <div className={s.container}>
                <div className={s.form_wrapper}>
                    <div className={s.title}>
                        Contact for agents
                    </div>
                    <ContactUsForm/>
                </div>
                <div className={s.bg}>
                    <img src={bg} alt=""/>
                </div>
            </div>
        </div>
    );
};

export default ContactsForAgents;
