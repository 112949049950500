import React from 'react';
import {motion} from "framer-motion";
import s from "./AccountConfirmationLetter.module.css";
import CustomDropdownForAccounts
    from "../../../../../components/ui/CustomDropdownForAccounts/CustomDropdownForAccounts";
import {Formik} from "formik";
import {useAccountsForSelect} from "../../../../../hooks/useAccountsForSelect";

const AccountConfirmationLetter = () => {

    const accountsList = useAccountsForSelect()

    return (
        <motion.div
            initial={{opacity: 0, scale: 1}}
            animate={{opacity: 1, scale: 1}}
            transition={{duration: 0.5}}
        >

            <div className={s.statementWrapper} >
                <div className={s.buttonsWrapper}>
                    <p className={s.downloadTitle}>Download as</p>
                    <p className={s.buttonTitle}>PDF</p>
                </div>
            </div>
            <div>
                <Formik
                    initialValues={{
                        accountNumber: '',
                    }}
                    onSubmit={(values) => {
                        const tempData = {

                        }
                    }}
                >
                    {({
                          values,
                          errors,
                          touched,
                          handleChange,
                          handleBlur,
                          handleSubmit
                      }) => (
                        <form className={s.form} onSubmit={handleSubmit}>
                            <div className={s.dropdown_wrapper}>
                                <p className={s.labelText}>Account Number</p>
                                <CustomDropdownForAccounts
                                    items={accountsList}
                                    id={'accountNumber'}
                                    name={'accountNumber'}
                                />
                            </div>
                            <div className={s.data_wrapper}>
                                <div className={s.row}>
                                    <span>Recipient Name</span>
                                    <span><b>Lilia Elvado</b></span>
                                </div>
                                <div className={s.row}>
                                    <span>Recipient Account</span>
                                    <span><b>EVP0 2100 1081 6291</b></span>
                                </div>
                                <div className={s.row}>
                                    <span>BIC Code</span>
                                    <span><b>BOFAUS3N</b></span>
                                </div>
                                <div className={s.row}>
                                    <span>Bank Name</span>
                                    <span><b>AB Mano Bankas</b></span>
                                </div>
                                <div className={s.row}>
                                    <span>Bank Address</span>
                                    <span><b>S. Moniuškos g. 27, LT-08115, Vilnius, Lithuania</b></span>
                                </div>
                            </div>
                        </form>)}
                </Formik>
            </div>
        </motion.div>
    );
};

export default AccountConfirmationLetter;
