import React, {useEffect} from 'react';
import {Link, NavLink, useLocation} from "react-router-dom";
import logo from "../../assets/img/personalAccount/logo.svg";
import s from "./SideMenu.module.css";
import classnames from "classnames";
import {useAppSelector} from "../../hooks/redux";
import './index.css'
import closePopup from "../../assets/img/mobile/close_popup.svg";

type PropsType = {
    setIsActive?: (boolean: boolean) => void
    isSide?: boolean
    handleLogout: () => void
}

const SideMenu = (props: PropsType) => {

    const kycStatus = useAppSelector(state => state.userReducer.user.flags.kycStatus)

    const location = useLocation()

    const kycIsValid = () => {
        if (!kycStatus) {
            return false
        } else if (kycStatus === "New") {
            return false
        } else if (kycStatus === "Rejected") {
            return false
        } else {
            return true
        }
    }

    useEffect(() => {
        return props.setIsActive && props.setIsActive(false)
    }, [location.pathname])

    return (
        <>
            <div className={s.logo_block}>
                <Link to="/">
                    <img src={logo} alt="" className={s.logo}/>
                </Link>
                {
                    props.isSide ? <img src={closePopup} className={s.closeImg} alt="" onClick={() => {
                        // @ts-ignore
                        props.setIsActive(false);
                    }}/> : <></>
                }

            </div>
            <div className={s.links_wrapper}>
                <div className={s.links_title}>services</div>
                <NavLink to={`/personal_account/accounts`}
                         className={({isActive}) => classnames(s.nav_item, isActive ? "active" : 'inactive')}>
                    <div className="img_block">
                        <div className="img_accounts"></div>
                    </div>
                    <div className="text_block">
                        Accounts
                    </div>
                </NavLink>
                <NavLink to={`/personal_account/transfers`}
                         className={({isActive}) => classnames(s.nav_item, isActive ? "active" : 'inactive')}>
                    <div className="img_block">
                        <div className="img_transfers"></div>
                    </div>
                    <div className="text_block">
                        Transfers
                    </div>
                </NavLink>
                <NavLink to={`/personal_account/templates`}
                         className={({isActive}) => classnames(s.nav_item, isActive ? "active" : 'inactive')}>
                    <div className="img_block">
                        <div className="img_templates"></div>
                    </div>
                    <div className="text_block">
                        Templates
                    </div>
                </NavLink>
                <NavLink to={`/personal_account/currency`}
                         className={({isActive}) => classnames(s.nav_item, isActive ? "active" : 'inactive')}>
                    <div className="img_block">
                        <div className="img_currency"></div>
                    </div>
                    <div className="text_block">
                        Currency
                    </div>
                </NavLink>
            </div>
            <div className={s.links_wrapper}>
                <div className={classnames(s.links_title, s.services_block)}>Account</div>

                <NavLink to={`/personal_account/security`}
                         className={({isActive}) => classnames(s.nav_item, isActive ? "active" : 'inactive')}>
                    <div className="img_block">
                        <div className="img_security"></div>
                    </div>
                    <div className="text_block">
                        Security
                    </div>
                </NavLink>
                <NavLink to={`/personal_account/personal/settings`}
                         className={({isActive}) => classnames(s.nav_item, isActive ? "active" : 'inactive')}>
                    <div className="img_block">
                        <div className="img_settings"></div>
                    </div>
                    <div className="text_block">
                        Settings
                    </div>
                </NavLink>

                <div className={classnames(s.logout, 'inactive')} onClick={() => props.handleLogout()}>
                    <div className="img_block">
                        <div className="img_logout"></div>
                    </div>
                    <div className={s.text}>Logout</div>
                </div>
            </div>


        </>
    );
};

export default SideMenu;
