import React from 'react';
import {motion} from "framer-motion";
import s from "./PrivacyPolicy.module.css";

const PrivacyPolicy = () => {
    return (
        <motion.div
            initial={{opacity: 0, scale: 1}}
            animate={{opacity: 1, scale: 1}}
            transition={{duration: 0.5}}
        >
            <div className={s.wrapper}>
                <div className={s.container}>

                    <div className={s.main_subtitle}>
                        Lux International Payment System
                    </div>

                    <div className={s.title}>
                        Privacy Policy
                    </div>

                    <div className={s.desc}>
                        UAB Lux International Payment System, a private limited liability company incorporated under
                        Lithuanian law, listed within Lithuanian Register of Legal Entities with the registration number
                        of 305158007 (hereinafter the Company) is committed to protecting the privacy and security of
                        your personal information. We want to share with you how we treat personal information about you
                        that we receive in connection with your use of our website ( the “Platform”).
                    </div>

                    <div className={s.desc}>
                        This policy, together with the General Terms and Conditions and any other Ancillary agreements
                        between you and the Company constitute an entire legal agreements you accept and consent to when
                        visiting our Platform and/or using our Services (as defined in the General Terms and Conditions)
                        among other things consenting to our use of your personal information as set out in this Privacy
                        Policy. Any definitions used within this Privacy Policy shall have the meaning as defined in the
                        General Terms and Condition.
                    </div>

                    <div className={s.desc}>
                        <b>
                            This policy sets out the basis on which any personal data we collect from you, or that you
                            provide to us, will be processed by us. For legal purposes the data controller is UAB Lux
                            International Payment System, with registration number 305158007, Republic of Lithuania.
                            Company
                            for any Data Protection related issues may be contacted by email at: <a
                            href="mailto:info@luxpay.lt" className={s.link}>info@luxpay.lt.</a>
                        </b>
                    </div>

                    <div className={s.desc}>
                        Although we operate internationally, the Company is organized under the laws of the Republic of
                        Lithuania. The servers that host this Platform and any data collected from you are located in
                        Lithuania, and any personal details you provide to us will be processed by Company in Lithuania.
                        If we transfer personal data out of the European Economic Area we shall ensure that we have in
                        place measures which provide an adequate level of protection as required by applicable laws and
                        regulations. All information you provide to us is stored on our secure servers. Any payment
                        transactions carried out by us or our chosen third-party provider of payment processing services
                        will be encrypted. Where we have given you (or where you have chosen) a password that enables
                        you to access certain parts of our Platform, you are responsible for keeping this password
                        confidential. We ask you not to share a password with anyone. Unfortunately, the transmission of
                        information via the internet is not completely secure. Although we will do our best to protect
                        your personal data, we cannot guarantee the security of your data. When using any
                        social/personal interaction features (if made available by the Company) please ensure that you
                        do not submit any personal data that you do not want to be seen, collected or used by others.
                    </div>

                    <div className={s.body}>

                        <div className={s.body_title}>
                            Information we collect
                        </div>

                        <div className={s.subtitle}>
                            a) Information you submit to us
                        </div>

                        <div className={s.text}>
                            <p>
                                This is information you give us about you by filling in forms on the Platform, or by
                                corresponding with us. It includes information you provide when you register to use the
                                Platform, open an Account, enter into any transaction on the Platform (such as purchase
                                of Electronic Money, Payment Transaction and/or Redemption transaction and others), when
                                you report a problem with the Services, or the Platform.
                            </p>
                            <p>
                                If you contact us, we will keep a record of that correspondence (if contacted via e-mail
                                or any other means made available on the Platform)
                            </p>
                            <p>

                                The information you give us may include: your full name, your billing address, date of
                                birth, e-mail address, phone number or any other identifier for communication (like
                                skype name, WeChat account identifier, etc.), details of your bank account including the
                                bank account number, IBAN, copies of identification documents (collected for the Know
                                Your Customer purpose ar defined in the General Terms and Conditions), copies of
                                documents required to prove source of funds, personal description and photograph and any
                                other information you provide us in order to prove your eligibility to use our services.
                            </p>
                        </div>

                        <div className={s.subtitle}>
                            b) Information we collect about you and your device
                        </div>

                        <div className={s.text}>
                            Each time you visit the Platform (and in some cases disregarding if you access your account
                            on the Platform or not) we will automatically collect the following information: technical
                            information: the internet protocol (IP) address used to connect your device to the Internet,
                            your login information, browser type and version, time zone setting, browser plug-in types
                            and versions, operating system and platform; information about your visit: full uniform
                            resource locators (URL), services you viewed or searched for, page response times, download
                            errors, length of visits to certain pages, page interaction information (such as scrolling,
                            clicks, and mouse-overs), methods used to browse away from the page; transaction details:
                            date, time, amounts, currencies used, exchange rates, Recipient details and details about
                            other third parties involved in transactions (senders and receivers of funds from your
                            Account); details of your visits to the Platform: transaction details relating to your use
                            of Services, including who you have sent funds or Electronic Money to, foreign exchange
                            transactions you have entered into, the time, date and location of the place the transaction
                            was entered into.
                        </div>

                        <div className={s.subtitle}>
                            c) Information we receive from other sources (Third Party Information)
                        </div>

                        <div className={s.text}>
                            We closely work fraud prevention agencies and user third-party databases in order identify
                            possibly fraudulent transactions as well as to identify our customers; sources of funds and
                            complete any other Compliance procedures as requested by laws and regulations. By agreeing
                            to this Policy you consent that we may carry out various searches and checks in order to
                            identify all information required for regulatory purposes. For avoidance of doubt we clearly
                            state hereby that will receive information about you from third parties we use to verify
                            your identity.
                        </div>

                        <div className={s.body_title}>
                            Cookies
                        </div>

                        <div className={s.text}>
                            We use cookies to distinguish you from other users of the Platform. This helps us to provide
                            you with a good experience when you use the Platform and also allows us to improve our
                            services. For detailed information on the cookies we use and the purposes for which we use
                            them, see our cookie policy.
                        </div>

                        <div className={s.body_title}>
                            Rights or Access
                        </div>

                        <div className={s.text}>
                            Following laws and regulations, you have the right to access the personal information we
                            hold about you. You can exercise this right by contacting us on info@luxpay.lt. In addition,
                            at any time you can request that we modify or delete your personal information with the
                            words “UPDATE MY INFORMATION” in the subject line. Please be aware that, to protect your
                            security and privacy, Company will take reasonable steps to verify your identity prior to
                            making changes to your personal information or satisfying a request for access to that
                            information.
                        </div>

                        <div className={s.body_title}>
                            How we use the information we collect
                        </div>

                        <div className={s.text}>
                            Following laws and regulations, you have the right to access the personal information we
                            hold about you. You can exercise this right by contacting us on info@luxpay.lt. In addition,
                            at any time you can request that we modify or delete your personal information with the
                            words “UPDATE MY INFORMATION” in the subject line. Please be aware that, to protect your
                            security and privacy, Company will take reasonable steps to verify your identity prior to
                            making changes to your personal information or satisfying a request for access to that
                            information.
                        </div>

                        <div className={s.body_title}>
                            How we use the information we collect
                        </div>

                        <div className={s.text}>
                            Company uses, data about you (contact and personal data, usage history, etc.) that we
                            collect from you through the Site in order to provide to you with, and develop and improve,
                            the services on our Site. We use the personal information about you that you provide to us
                            for our internal purposes such as responding to your requests for information or materials,
                            delivering newsletters to you, contacting you, assessing your application for a position
                            with Company and entities related to Company, processing and completing orders, processing
                            your payment, administering your account, administering and developing our business
                            relationship with you or the company you represent and to provide you with administrative
                            communications regarding your account. We also use your personal information to communicate
                            with you, such as, for example, newsletters, press releases, or information relating to
                            products, offers, or programs that may be of interest to you. Please see the section on
                            opting in and opting out for more information.
                        </div>

                        <div className={s.body_title}>
                            How do we share information with?
                        </div>

                        <div className={s.text}>
                            <p>
                                Company will not disclose your personal information to third parties except as set out
                                in this Privacy Policy or if you have consented to such sharing. We may share the
                                personal information about you that you provide to us with trusted companies we have
                                hired to provide services for us, such as sending out our newsletters, fulfilling orders
                                you may submit, or collecting and processing information on our behalf. These companies
                                – our vendors – are contractually bound to use personal information that we share with
                                them only to perform the services we have hired them to provide and to have appropriate
                                technological and organisational measures including security in place to protect such
                                personal information.
                            </p>
                            <p>
                                If you enable third party services to be directly integrated into your account (for
                                example Facebook Connect), you agree that we may pass your account information to the
                                providers of such services to enable them to provide you with the services which you
                                have requested. Company does not exercise any control over such service providers, such
                                service providers process your account information independently and in accordance with
                                their own privacy policies.
                            </p>
                            <p>
                                We may also share your personal information in the event that the business of Company is
                                transferred to another entity by way of merger, sale of the company’s assets, or
                                otherwise. If you consent , we may share your information with Company related entities
                                and selected third parties so that they may provide you with or contact you regarding
                                information and materials that may be of interest to you. Please refer to the section
                                below on opting in and out.
                            </p>
                            <p>

                                We may share your personal information with a third party if we are required to do so to
                                comply with applicable law or a court order, or if it is reasonable to do so, to enforce
                                our Terms and Conditions or to assist with a legitimate investigation (for example, in
                                relation to suspected fraud or third party intellectual property infringement).
                            </p>
                        </div>

                        <div className={s.body_title}>
                            The non-personal aggregate data we collect
                        </div>

                        <div className={s.text}>
                            <p>
                                We may also collect and group demographic and preferences information, responses to
                                surveys and other personal information that we collect from you into an aggregate,
                                anonymous form for disclosure to our existing or potential business partners,
                                affiliates, sponsors or other third parties. However, please be assured that this
                                aggregate data will in no way identify you as an individual or any other visitors to the
                                Site.
                            </p>
                            <p>
                                We may collect general, non-personal, statistical information about the use of the Site,
                                such as how many visitors visit a specific page on the Site, how long they stay on that
                                page and which hyperlinks, if any, they “click” on. We collect this information through
                                the use of “cookies” and other tracking technologies.
                            </p>
                            <p>
                                We collect this information in order to determine which areas of the Site are most
                                popular and to enhance the Site for visitors. As with other aggregated data, we may
                                disclose such data to our existing or potential business partners, affiliates, sponsors
                                or other third parties, but this aggregate data will in no way identify you as an
                                individual or any other visitors to the Site.
                            </p>
                        </div>

                        <div className={s.body_title}>
                            Opting in and out
                        </div>

                        <div className={s.text}>
                            When you submit personal information to us through the Registration Page on this Site, you
                            consent to receiving additional information from Company. If you decide that you don’t want
                            to receive information such as promotion and marketing messages, you are given the option to
                            opt out of receiving these messages from us. Company will not send you information from
                            third parties and/or partners unless you specifically consent to this.
                        </div>

                        <div className={s.body_title}>
                            Links to other sites and site account
                        </div>

                        <div className={s.text}>
                            <p>
                                For your convenience, the Company Site may from time to time include links to
                                third-party sites whose information practices may be different than ours. Visitors
                                should consult the other sites’ privacy policies, as we have no control over information
                                that is submitted to, or collected by, these third parties.
                            </p>
                            <p>
                                Site Accounts are pages on certain websites operated by other companies (for example,
                                the Company page on Facebook), where Company content is provided. The Terms and
                                Conditions, this Privacy Policy, the Terms and Conditions and other applicable rules
                                published on this Site are binding on and apply to the users and visitors of Site
                                Accounts. Users and visitors should carefully read the terms and conditions and privacy
                                policies of such websites, over which Company has no control. Company has Site Accounts
                                on the following websites: Facebook, Google+, Twitter, Pinterest, Youtube, and other
                                social networks.
                            </p>
                        </div>

                        <div className={s.body_title}>
                            Security of information
                        </div>

                        <div className={s.text}>
                            Company uses industry standard security measures to safeguard information concerning, and
                            submitted by you. Despite the security measures employed by Company, you should be aware
                            that it is impossible to guarantee absolute security with respect to information sent
                            through the Internet.
                        </div>

                        <div className={s.body_title}>
                            Disclosures
                        </div>

                        <div className={s.text}>
                            Company will, to the extent possible, control your personal information, and, except as
                            otherwise set forth in this Privacy Policy, we will not disclose your personal information
                            to third parties. Although we are committed to maintaining the confidentiality of your
                            personal information, if required by law or regulation, we reserve the right to disclose
                            such information without first obtaining your consent.
                        </div>

                        <div className={s.body_title}>
                            Changes to the Privacy Policy
                        </div>

                        <div className={s.text}>
                            <p>
                                The Site and services may change from time to time. As a result, it may be necessary for
                                Company to make changes to this Privacy Policy from time to time. Company reserves the
                                right to update or modify this Privacy Policy at any time and from time to time without
                                prior notice by updating this page.
                            </p>
                            <p>
                                We ask that you please review the Privacy Policy periodically, and especially before you
                                provide personal information. This Policy was last updated on the date indicated above.
                                Your continued use of this Site subsequent to any modification of this Privacy Policy
                                shall constitute your acceptance of the modified Privacy Policy.
                            </p>
                        </div>

                        <div className={s.body_title}>
                            Contacting Us
                        </div>

                        <div className={s.text}>
                            For all support based enquiries, you can contact the team through <a className={s.link_small} href="mailto:info@luxpay.lt">info@luxpay.lt</a>
                        </div>

                    </div>

                </div>
            </div>
        </motion.div>
    );
};

export default PrivacyPolicy;
