import React, {useState} from 'react';
import s from "../Security.module.css";
import {ConfigProvider, Switch} from "antd";
import {motion} from "framer-motion";
import {ReactComponent as Check} from "../../../../assets/img/personalAccount/checked.svg";
import {ReactComponent as Cross} from "../../../../assets/img/personalAccount/cross.svg";
import Table, {ColumnsType} from "antd/es/table";
import {useAppDispatch, useAppSelector} from "../../../../hooks/redux";
import {
    sendEmailOnLoginHandleChangeThunk,
    setIsLogOutWhenIpWasChangedThunk,
    setIsLogOutWhenUserWasInactiveThunk, setSaveLoginHistoryThunk
} from "../../../../store/reducers/ActionCreators";
import {loginHistoryItem} from "../../../../types/interfaces";
import TransparentButton from "../../../../components/ui/TransparentButton/TransparentButton";
import Icon from "@ant-design/icons";
import {ReactComponent as Back} from "../../../../assets/img/personalAccount/back.svg";

type propsType = {
    isMobile: boolean
}

interface DataType {
    key: string;
    date: JSX.Element;
    transferN: string;
    payer: string;
    recipient: string;
    type: string;
    amount: JSX.Element;
    balance: string;
    description?: string,
}

const columns: ColumnsType<DataType> = [
    {
        title: 'Session Start',
        dataIndex: 'sessionStart',
        sorter: true,
    },
    {
        title: 'IP Address',
        dataIndex: 'IPAddress',
    },
    {
        title: 'Session End',
        dataIndex: 'sessionEnd',
    },
    {
        title: 'Duration',
        dataIndex: 'duration',
    },
];

const Session = (props: propsType) => {

    const dispatch = useAppDispatch()
    const isLogOutWhenUserWasInactive: boolean | undefined = useAppSelector(state => state.userReducer.user.flags.autoLogout)
    const isLogOutWhenIpWasChanged: boolean | undefined = useAppSelector(state => state.userReducer.user.flags.logoutIfIpChanges)
    const isSendEmailOnLogin: boolean | undefined = useAppSelector(state => state.userReducer.user.flags.sendEmailOnLogin)
    const isSaveLoginHistory: boolean | undefined = useAppSelector(state => state.userReducer.user.flags.saveLoginHistory)
    const token = useAppSelector(state => state.userReducer.userToken)
    const accountId = useAppSelector(state => state.userReducer.chosenAccountTypeId)
    const loginHistory = useAppSelector(state => state.userReducer.loginHistory)

    const [isHistoryShow, setIsHistoryShow] = useState(false)

    const tableData: Array<any> = loginHistory.map((item: loginHistoryItem) => {
        return {
            sessionStart: item.sessionStart,
            IPAddress: item.ipAddress,
            sessionEnd: item.sessionEnd,
            duration: item.duration,
        }
    })

    const leaveTheSessionOpenHandleChange = (payload: boolean) => {
        dispatch(setIsLogOutWhenUserWasInactiveThunk(token, payload, accountId))
    }

    const isLogOutWhenIpWasChangedHandleChange = (payload: boolean) => {
        dispatch(setIsLogOutWhenIpWasChangedThunk(token, payload, accountId))
    }

    const sendEmailOnLoginHandleChange = (payload: boolean) => {
        dispatch(sendEmailOnLoginHandleChangeThunk(token, payload, accountId))
    }

    const setSaveLoginHistoryHandleChange = (payload: boolean) => {
        dispatch(setSaveLoginHistoryThunk(token, payload, accountId))
    }

    return (
        <motion.div
            initial={{opacity: 0, scale: 1}}
            animate={{opacity: 1, scale: 1}}
            transition={{duration: 0.5}}
        >
            {
                isHistoryShow
                    ? <div className={s.tableWrapper}>
                        <div className={s.table_title}>
                            Login History
                        </div>
                        <ConfigProvider theme={{
                            components: {
                                Pagination: {
                                    colorPrimary: '#FFFFFF',
                                    colorPrimaryHover: '#FFFFFF',
                                    colorBgContainer: '#4DB887',
                                    colorLink: '#FFFFFF'
                                },
                            },
                        }}>
                            <Table
                                bordered={false}
                                style={{marginTop: 48}}
                                rowClassName={(record, index) => index % 2 !== 0 ? `${s.table_row_dark}` : ''}
                                className={s.statementTable}
                                columns={columns}
                                pagination={{position: ['bottomCenter'], defaultPageSize: 15}}
                                dataSource={tableData}
                            />
                        </ConfigProvider>
                        <div className={s.button_wrapper}>
                            <TransparentButton
                                title={"Back"}
                                isPersonalAccountBtn
                                large
                                icon={<Icon component={() => <Back/>} rev={undefined}/>}
                                onClick={() => setIsHistoryShow(false)}
                            />
                        </div>
                    </div>
                    : <div>
                        <div className={s.autentication_list_block}>
                            <div className={s.autentication_item}>
                                <div className={s.row}>
                                    <span className={s.autentication_title}>Leave the session open</span>
                                    <Switch
                                        rootClassName={s.my_root_switch}
                                        className={s.my_switch}
                                        checked={!isLogOutWhenUserWasInactive}
                                        onChange={(checked) => {
                                            leaveTheSessionOpenHandleChange(!checked)
                                        }}
                                        checkedChildren={<Check style={!isLogOutWhenUserWasInactive ? {
                                            position: 'relative',
                                            left: 19,
                                            top: props.isMobile ? 5 : 2
                                        } : {position: 'relative', left: 19, top: 100}}/>}
                                        unCheckedChildren={<Cross style={!isLogOutWhenUserWasInactive ? {
                                            position: 'relative',
                                            left: 19.5,
                                            top: 100
                                        } : {
                                            position: 'relative',
                                            right: props.isMobile ? 19 : 20,
                                            top: props.isMobile ? 12 : -1
                                        }}/>}

                                    />
                                </div>
                                <div className={s.autentication_item_description}>
                                    If you are logged in but not active, the session will be prevented from being closed.
                                    If this feature is disabled, the session will close after 30 minutes of inactivity.
                                </div>
                            </div>

                            <div className={s.autentication_item}>
                                <div className={s.row}>
                                    <span className={s.autentication_title}>Send email on login</span>
                                    <Switch
                                        rootClassName={s.my_root_switch}
                                        className={s.my_switch}
                                        checked={isSendEmailOnLogin}
                                        onChange={(checked) => {
                                            sendEmailOnLoginHandleChange(checked)
                                        }}
                                        checkedChildren={<Check style={isSendEmailOnLogin ? {
                                            position: 'relative',
                                            left: props.isMobile ? 17 : 19,
                                            top: props.isMobile ? 5 : 2
                                        } : {position: 'relative', left: 19, top: 100}}/>}
                                        unCheckedChildren={<Cross style={isSendEmailOnLogin ? {
                                            position: 'relative',
                                            left: 19,
                                            top: 100
                                        } : {
                                            position: 'relative',
                                            right: props.isMobile ? 19 : 20,
                                            top: props.isMobile ? 12 : -1
                                        }}/>}
                                    />
                                </div>
                                <div className={s.autentication_item_description}>
                                    Each time you log into your account, you will receive an email with information about
                                    the IP
                                    address of the authorized user and a
                                    link to block your account in case of unauthorized activity in it.
                                </div>
                            </div>

                            <div className={s.autentication_item}>
                                <div className={s.row}>
                                    <span className={s.autentication_title}>Log out when my IP address changes</span>
                                    <Switch
                                        rootClassName={s.my_root_switch}
                                        className={s.my_switch}
                                        checked={isLogOutWhenIpWasChanged}
                                        onChange={(checked) => {
                                            isLogOutWhenIpWasChangedHandleChange(checked)
                                        }}
                                        checkedChildren={<Check style={isLogOutWhenIpWasChanged ? {
                                            position: 'relative',
                                            left: 18,
                                            top: props.isMobile ? 5 : 2
                                        } : {position: 'relative', left: 19, top: 100}}/>}
                                        unCheckedChildren={<Cross style={isLogOutWhenIpWasChanged ? {
                                            position: 'relative',
                                            left: 19.5,
                                            top: 100
                                        } : {
                                            position: 'relative',
                                            right: props.isMobile ? 19 : 20,
                                            top: props.isMobile ? 12 : -1
                                        }}/>}
                                    />
                                </div>
                                <div className={s.autentication_item_description}>
                                    Check the box if you want the session to be closed when the IP address changes (for
                                    example,
                                    when your mobile device switches from Wi-Fi to LTE).
                                </div>
                            </div>

                            <div className={s.autentication_item}>
                                <div className={s.row}>
                                    <span className={s.autentication_title}>Login history</span>
                                    <Switch
                                        rootClassName={s.my_root_switch}
                                        className={s.my_switch}
                                        checked={isSaveLoginHistory}
                                        onChange={(checked) => {
                                            setSaveLoginHistoryHandleChange(checked)
                                        }}
                                        checkedChildren={<Check style={isSaveLoginHistory ? {
                                            position: 'relative',
                                            left: 18,
                                            top: props.isMobile ? 5 : 2
                                        } : {position: 'relative', left: 19, top: 100}}/>}
                                        unCheckedChildren={<Cross style={isSaveLoginHistory ? {
                                            position: 'relative',
                                            left: 19.5,
                                            top: 100
                                        } : {
                                            position: 'relative',
                                            right: props.isMobile ? 19 : 20,
                                            top: props.isMobile ? 12 : -1
                                        }}/>}
                                    />
                                </div>
                                <div className={s.autentication_item_description}>
                                    Every account login is logged and &nbsp;
                                    <span
                                       onClick={() => setIsHistoryShow(true)}
                                       className={s.login_history_link}
                                    >
                                        available here
                                    </span>
                                </div>
                            </div>

                            {/*<div className={s.buttons_block_end}>*/}
                            {/*    <div className={s.button_wrapper}>*/}
                            {/*        <MyBtn title={"Save"} isPersonalAccountBtn large/>*/}
                            {/*    </div>*/}
                            {/*</div>*/}

                        </div>
                    </div>
            }

        </motion.div>
    );
};

export default Session;
