import React from 'react';
import s from './MyTextarea.module.css'
import classnames from "classnames";

type PropsType = {
    placeholder?: string,
    id?: string
    name?: string
    value?: string
    onChange?: any
    isError?: any
    touched?: any
    style?: React.CSSProperties;
}

const MyTextarea = (props: PropsType) => {
    const combinedClass = classnames(
        s.textarea,
        props.isError && props.touched ? s.error : '',
    );

    // Merge the passed style with any existing style
    const mergedStyle = { ...props.style };

    return (
        <textarea
            {...props}
            style={mergedStyle}
            className={combinedClass}
        />
    );
};

export default MyTextarea;
