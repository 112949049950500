import React from 'react';
import s from "./TwoFa.module.css";
import MyInput from "../../ui/MyInput/MyInput";
// import attention from "../../../assets/img/attention.svg";

const TwoFa = (props: any) => {

    return (
        <div>
            <div className={s.error_wrapper}>
                {
                    props.error === "Invalid 2fa code"
                        ? <div className={s.error_block}>
                            {/*<img src={attention} alt=""/>*/}
                            <div>
                                Invalid 2fa code
                            </div>
                        </div>
                        : <></>
                }
            </div>
            <div className={s.sign_in_wrapper}>
                <div className={s.sign_in_content}>
                    <div className={s.title}>
                        Two-factor authentication
                    </div>
                    <div className={s.input_block}>
                        <div className={s.input_label}>
                            2FA Token from Google Authenticator
                        </div>
                        <MyInput
                            id="code"
                            name="code"
                            value={props.dataForSend.twoFaCode}
                            onChange={(e) => props.setDataForSend(
                                {
                                    ...props.dataForSend, twoFaCode: e.currentTarget.value
                                }
                            )}
                            placeholder={"6 digits"}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TwoFa;
