import React, {useState} from 'react';
import s from "./EmailAutenticationLogin.module.css";
import { motion } from 'framer-motion';
import MyInput from "../../../ui/MyInput/MyInput";
// import attention from "../../../../assets/img/attention.svg";
import {zeroFill} from "../../../../helpers/ZeroFill";
import MyBtn from '../../../ui/MyBtn/MyBtn';
import { useNavigate } from 'react-router-dom';
import CustomModal from '../../../ui/CustomModal/CustomModal';

export type EmailVereficationDataType = {
    email: string | null,
    code: string
}

const EmailAutenticationLogin = (props: any) => {

    const navigate = useNavigate()
    const [time, setTime] = useState(120)
    const [timeIsOver, setTimeIsOver] = useState(false)
    const [code, setCode] = useState('')
    const [isModalOpen, setIsModalOpen] = useState<boolean | null>(false)

    const savedData = JSON.parse(localStorage.getItem('registrationData') || '{}');
    const verificationData = JSON.parse(localStorage.getItem('verificationData') || '{}');

    const handleChange = (event: any) => {
        setCode(event.target.value);
    };

    const tick = () => {
        if (time === 0) {
            setTimeIsOver(true)
            props.email === '' ? props.returnToLogin(0) : props.setCurrent(0)
            return
        }
        setTime(prevState => prevState - 1)
    }
    const minutes = Math.floor(time / 60);
    const seconds = time - minutes * 60;

    const modifyEmail = (email: string): string => {
        const [localPart, domainPart] = email.split('@');
        const modifiedLocal = localPart.charAt(0) + '*'.repeat(localPart.length - 1);
        const domainParts = domainPart.split('.');
        const modifiedDomain = '*'.repeat(domainParts[0].length) + '.' + domainParts.slice(1).join('.');
        return `${modifiedLocal}@${modifiedDomain}`;
    };

    React.useEffect(() => {
        const timerID = setInterval(() => tick(), 1000);
        return () => clearInterval(timerID);
    });

    React.useEffect(() => {
        if (props.email) {
            props.createUser();
        }
    }, [props.email]);


    React.useEffect (() => {
        savedData.emailCode = code;
        localStorage.setItem('registrationData', JSON.stringify(savedData));
    }, [code])

    React.useEffect(() => {
        if (props.email) {
            setIsModalOpen(verificationData.isEmailVerified && verificationData.isRegistered)
        }
    }, [verificationData]);

    const closeAndRedirect = () => {
        setIsModalOpen(false)
        navigate("/login")
    }

    return (
        <motion.div
            initial={{opacity: 0, scale: 1}}
            animate={{opacity: 1, scale: 1}}
            transition={{duration: 0.5}}
        >
            <CustomModal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} type={'confirm'}>
                <div className={s.flex}>
                    <div className={s.modal_title}>
                        Success!
                    </div>
                    <div className={s.modal_subtitle}>
                        Registration completed successfully. In a few seconds you will be redirected to the sign up page.
                    </div>
                    <div className={s.button_block}>
                        <MyBtn title={"Ok"} onClick={() => closeAndRedirect()} isPersonalAccountBtn large />
                    </div>
                </div>
            </CustomModal>

            <div className={s.error_wrapper}>
                {
                    props.error !== ""
                        ? <div className={s.error_block}>
                            {/*<img src={attention} alt=""/>*/}
                            <div>
                                {props.error}
                            </div>
                        </div>
                        : <></>
                }
            </div>
            <div className={s.sign_in_wrapper}>
                <form>
                    <div className={s.sign_in_content}>
                        <div className={s.title}>
                            Email authentication
                        </div>
                        <div className={s.subtitle}>
                            Email with confirmation code was sent to email address
                            {
                                props.email === ''
                                ?
                                <></>
                                :
                                <span> {props.email && modifyEmail(props.email)}</span> //
                            }
                        </div>
                        <div className={s.input_block}>
                            <div className={s.input_label}>
                                confirmation Code
                            </div>
                            {
                                props.registration
                                ?
                                    <MyInput
                                        id="code"
                                        name="code"
                                        value={code}
                                        onChange={handleChange}
                                        placeholder={"6 digits"}
                                    />
                                :
                                    <MyInput
                                        id="code"
                                        name="code"
                                        value={props.dataForSend.emailCode}
                                        onChange={(e) => props.setDataForSend(
                                            {
                                                ...props.dataForSend, emailCode: e.currentTarget.value
                                            }
                                        )}
                                        placeholder={"6 digits"}
                                    />
                            }
                        </div>
                        <div className={s.timer_wrapper}>
                            {timeIsOver
                                ? <div></div>
                                : <div> {zeroFill(minutes, 2)} : {zeroFill(seconds, 2)}</div>
                            }
                        </div>
                    </div>
                    {/*<div className={s.dont_have_account}>*/}
                    {/*    <div>*/}
                    {/*        Back to*/}
                    {/*    </div>*/}
                    {/*    <Link className={s.link} to={"#"}>Sign up</Link>*/}
                    {/*</div>*/}
                </form>
                <div className={props.registration ? s.button : s.button_none}>
                    <MyBtn title={'Authentification'}
                           isPersonalAccountBtn
                           large
                           onClick={props.verifyEmail}
                    />
                </div>
            </div>
        </motion.div>
    );
};

export default EmailAutenticationLogin;
