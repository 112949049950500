import React from 'react';
import {motion} from "framer-motion";
import s from "./TermsAndConditions.module.css"
import MyBtn from "../../../components/ui/MyBtn/MyBtn";
import {useNavigate} from "react-router-dom";
import {TERMS_AND_CONDITIONS_BUSINESS, TERMS_AND_CONDITIONS_PERSONAL} from "../../../Constants/RoutesConstants";
const TermsAndConditions = () => {

    const navigate = useNavigate()

    return (
        <motion.div
            initial={{opacity: 0, scale: 1}}
            animate={{opacity: 1, scale: 1}}
            transition={{duration: 0.5}}
        >
            <div className={s.wrapper}>
                <div className={s.container}>
                    <div className={s.title}>
                    Terms & Conditions
                    </div>

                    <div className={s.cards_block}>
                        <div className={s.private_card}>
                            <div className={s.card_title}>
                                General Terms & Conditions for Private Clients
                            </div>
                            <MyBtn title={"See more"} onClick={() => navigate(TERMS_AND_CONDITIONS_PERSONAL)}/>
                        </div>
                        <div className={s.business_card}>
                            <div className={s.card_title}>
                                General Terms & Conditions for Business Clients
                            </div>
                            <MyBtn title={"See more"} onClick={() => navigate(TERMS_AND_CONDITIONS_BUSINESS)}/>
                        </div>
                    </div>
                </div>
            </div>
        </motion.div>
    );
};

export default TermsAndConditions;
