// Function that returns the validation schema based on the payment region
import {
    validationSchemaDomesticEUR,
    validationSchemaDomesticGBP,
    validationSchemaInternationalEUR, validationSchemaInternationalGBP
} from "../FormHelpers/ValidationSchemas";
import * as yup from "yup";

export const getValidationSchemaForTemplates = (paymentRegion: string, currency: string) => {

    if (currency === "EUR") {
        switch (paymentRegion) {
            case "domestic":
                return yup.object({...validationSchemaDomesticEUR});
            case "international":
                return yup.object({...validationSchemaInternationalEUR});
        }
        return
    }

    if (currency === "GBP") {
        switch (paymentRegion) {
            case "domestic":
                return yup.object({...validationSchemaDomesticGBP});
            case "international":
                return yup.object({...validationSchemaInternationalGBP});
        }
        return
    }

}
