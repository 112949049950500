import React from 'react';
import {motion} from "framer-motion";
import s from './Compliants.module.css'

const Complaints = () => {
    return (
        <motion.div
            initial={{opacity: 0, scale: 1}}
            animate={{opacity: 1, scale: 1}}
            transition={{duration: 0.5}}
        >
            <div className={s.wrapper}>
                <div className={s.container}>
                    <div className={s.title}>
                        Complaint handling policy
                    </div>

                    <div className={s.body}>

                        <div className={s.body_title}>
                            About this policy
                        </div>

                        <div className={s.subtitle}>
                            I. Introduction
                        </div>

                        <div className={s.text}>
                            <p>
                                1 . UAB Lux International Payment System (hereinafter “the Company”) follows these
                                general rules for registering, examining, and responding to Client complaints. The
                                purpose of this policy is to organize the process of Client complaints management in a
                                fair, efficient, and proper manner. This policy does not apply when the Client submits a
                                complaint regarding an activity of the Company which is not regulated by special laws or
                                supervised by the Bank of Lithuania; and when the Company is not responsible for the
                                execution of the activity indicated in the Client’s complaint.2. Definitions used in
                                this policy:2.1. Company – UAB Lux International Payment System (company code 305158007,
                                Vilnius, the Republic of Lithuania).2.2. Client – a natural person who has registered in
                                the Company’s System and created an Electronic Money Account.2.3. Complaint – a written
                                request submitted by a Client to the Company, where the Client indicates that the rights
                                or legitimate interests, related to the services provided by the Company or concluded
                                agreements, have been violated.2.4. Complaints examination – the activity of employees
                                of the Company, including acceptance and registration of claims (complaints), issue
                                determination, preparation of a response, and its provision to a Client.2.5. Complaints
                                register – a journal where all complaints of Clients are registered.2.6. Response – a
                                written reply to a Client’s questions and/or demands.
                            </p>
                            <p>
                                2 . Definitions used in this policy:
                            </p>
                            <p>
                                2.1. Company – UAB Lux International Payment System (company code 305158007, Vilnius,
                                the Republic of Lithuania). <br/>
                                2.2. Client – a natural person who has registered in the Company’s System and created an
                                Electronic Money Account. <br/>
                                2.3. Complaint – a written request submitted by a Client to the Company, where the
                                Client indicates that the rights or legitimate interests, related to the services
                                provided by the Company or concluded agreements, have been violated. <br/>
                                2.4. Complaints examination – the activity of employees of the Company, including
                                acceptance and registration of claims (complaints), issue determination, preparation of
                                a response, and its provision to a Client. <br/>
                                2.5. Complaints register – a journal where all complaints of Clients are
                                registered. <br/>
                                2.6. Response – a written reply to a Client’s questions and/or demands.
                            </p>
                        </div>

                        <div className={s.subtitle}>
                            II. Submitting a complaint
                        </div>

                        <div className={s.text}>
                            <p>
                                1 . The Client can submit a complaint, specifying relevant information, which would
                                indicate why the Client reasonably believes that the Company violated the legal rights
                                and interests of the Client while providing its services. <b>The Client may submit a
                                complaint in the following ways:</b>
                            </p>
                            <p>
                                1.1. at the Company’s Office at Mindaugo st. 23 Vilnius, the Republic of Lithuania by
                                filling in the complaint form and providing it to an employee.<br/>
                                1.2. by sending a letter by mail to the Company’s Office at Mindaugo st. 23 Vilnius, the
                                Republic of Lithuania.<br/>
                                1.3. by sending an email to <a className={s.link_small}
                                                               href="mailto:info@luxpay.lt">info@luxpay.lt.</a><br/>
                                1.4. in the electronic dispute settlement system – <a className={s.link_small}
                                                                                      href="https://webgate.ec.europa.eu/odr">https://webgate.ec.europa.eu/odr</a><br/>
                            </p>
                            <p>
                                2. In the complaint, the Client must specify:
                            </p>
                            <p>
                                2.1. the name, surname, or company name. <br/>
                                2.2. the address of the Client. <br/>
                                2.3. the date of filing the complaint. <br/>
                                2.4. the essence of the complaint, i.e. the person’s rights or legitimate interests that
                                have been violated. <br/>
                                2.5. the Client’s requirements to the Institution. <br/>
                                2.6. other available documents relating to the complaint, if needed. <br/>
                                2.7. the Client’s contacts: phone number or email address. <br/>
                            </p>
                            <p>
                                3. If at least one of the required requisites is missing, the Company has the right to
                                request the Client to complete the complaint form.
                            </p>
                            <p>
                                4. Upon the receipt of a complaint from the Client, the Company confirms receipt of the
                                complaint and indicates the time limit within which the reply will be provided.
                            </p>
                            <p>
                                5. The Company will make the maximum effort to provide the response to the Client within
                                the shortest possible time, but no later than 15 (fifteen) Business days, unless the
                                Company cannot provide the answer within 15 (fifteen) days due to reasons beyond its
                                control. In this case, the Company cannot provide a definite response, the Company will
                                indicate the reason for the delay and the deadline for submitting the answer. Either
                                way, the deadline for providing the final answer shall not be longer than 35
                                (thirty-five) business days.
                            </p>
                            <p>
                                6. Client complaints with all the related materials and a response provided to the
                                Client shall be stored not less than 3 years as of provision of the last response to the
                                Client.
                            </p>
                            <p>
                                7. The complaints submitted by the Client are solved free of charge.
                            </p>
                        </div>

                        <div className={s.subtitle}>
                            III. Final provisions
                        </div>

                        <div className={s.text}>
                            <p>
                                1. If a Client is not satisfied with the final response provided by the Company, the
                                Client has the right to use other legitimate remedies and to submit a complaint to the
                                Bank of Lithuania, in the order stipulated by the Law on the Bank of Lithuania, the Law
                                on Consumer Protection, and the Rules of the Procedure for the Out of Court Settlement
                                in the Bank of Lithuania of Disputes between Consumers and Financial Market
                                Participants, approved by Resolution No. 03-23 of the Board of the Bank of Lithuania of
                                26 January 2012, as follows:
                            </p>
                            <p>
                                1.1. The Client’s complaint to the Bank of Lithuania may be submitted in writing or
                                electronically: <br/>
                                1.2. at the address: Totorių g. 4, LT-01121, Vilnius, Republic of Lithuania, or Žalgirio
                                st. 90, LT-09303, Vilnius, Republic of Lithuania. <br/>
                                1.3. sending a complaint by e-mail: <a href="https://www.lb.lt/"
                                                                       className={s.link_small}>info@lb.lt or
                                pt@lb.lt; </a><br/>
                                1.4. sending by fax <a className={s.link_small} href="#">(8 5) 268 0038.</a><br/>
                                1.5. filling in an electronic link in the designated section of the Bank of Lithuania
                                website. <br/>
                                1.6. by other means specified by the Bank of Lithuania.
                            </p>
                            <p>
                                2. In the complaint, the Client must specify:
                            </p>
                            <p>
                                2.1. the name, surname, or company name. <br/>
                                2.2. the address of the Client. <br/>
                                2.3. the date of filing the complaint. <br/>
                                2.4. the essence of the complaint, i.e. the person’s rights or legitimate interests that
                                have been violated. <br/>
                                2.5. the Client’s requirements to the Institution. <br/>
                                2.6. other available documents relating to the complaint, if needed. <br/>
                                2.7. the Client’s contacts: phone number or email address.
                            </p>
                            <p>
                                2. If the dispute is not settled amicably or through other means of out-of-court
                                settlement of disputes, the dispute shall be resolved in court according to as provided
                                by the procedure established by the laws of the Republic of Lithuania.
                            </p>
                            <p>
                                3. Confidentiality is treated seriously in complaints handling. We always bear in mind
                                legal requirements, for example, data protection legislation, as well as internal
                                policies on confidentiality when handling the customers’ information.
                            </p>
                        </div>

                    </div>

                </div>
            </div>
        </motion.div>
    );
};

export default Complaints;
