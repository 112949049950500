import {combineReducers, configureStore} from '@reduxjs/toolkit'
import {useDispatch} from "react-redux";
import userReducer from './reducers/UserSlice'
import transactionsReducer from './reducers/TransactionsSlice/TransactionsSlice'
import storage from 'redux-persist/lib/storage'
import hardSet from 'redux-persist/lib/stateReconciler/hardSet'
import {persistReducer } from 'redux-persist'

const persistConfig = {
    key: 'root_redux',
    storage,
    stateReconciler: hardSet,
}

const rootReducer = combineReducers({
    userReducer,
    transactionsReducer,
})

const persistedReducer = persistReducer<ReturnType<typeof rootReducer>>(persistConfig, rootReducer)

export const setupStore = () => {
    return configureStore({
        reducer: persistedReducer
    })
}

// Infer the `RootState` and `AppDispatch` types from the store itself
// export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
// export type AppDispatch = typeof store.dispatch

export type RootState = ReturnType<typeof rootReducer>
export type AppStore = ReturnType<typeof setupStore>
export type AppDispatch = AppStore['dispatch']
export const useAppDispatch: () => AppDispatch = useDispatch
