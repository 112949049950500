import React, {Dispatch, SetStateAction, useState, useEffect} from 'react';
import {useAppDispatch} from "../../../hooks/redux";
import {Formik, FormikHelpers} from "formik";
import {motion, steps} from 'framer-motion';
import {useFormik} from 'formik';
import * as yup from 'yup';
import s from './password_creation.module.css';
import {NavLink} from 'react-router-dom';
import Steps from '../../../components/ui/Steps/Steps';
import MyBtn from '../../../components/ui/MyBtn/MyBtn';
import MyInput from '../../../components/ui/MyInput/MyInput';
import MySelect from '../../../components/ui/MySelect/MySelect'
import CustomCheckbox from '../../../components/ui/customCheckbox/CustomCheckbox';
import TransparentButton from '../../../components/ui/TransparentButton/TransparentButton';
import {ReactComponent as Back} from '../../../assets/img/personalAccount/back.svg'
import Checkbox from 'antd/lib/checkbox';
import CustomCheckbox1 from '../../../components/ui/CustomCheckbox1/CustomCheckbox1';
import Icon from '@ant-design/icons';

interface RegistrationData {
    password: string;
}

type PropsType = {
    setCurrent: Dispatch<SetStateAction<number>>
    onSubmit: () => void
}

const validationSchema = yup.object({
    password: yup.string()
        .min(8, "min 8")
        .matches(/[1-9]+/, "Password must contain at least one number")
        .matches(/[a-z]+/, "Password must contain at least one lowercase")
        .matches(/[A-Z]+/, "Password must contain at least one uppercase")
        .required('Required field'),
    confirmPassword: yup.string().required('Required field')
        .test({
            message: 'Passwords you entered do not match',
            test: function () {
                return this.parent.confirmPassword === this.parent.password;
            },
        }),
});


const PasswordCreationStep: React.FC<PropsType> = ({...props}) => {

    const [checkboxState, setCheckboxState] = useState({
        checkbox1: false,
        checkbox2: false,
    });

    const [accountType, setAccountType] = useState<string | null>(null)

    useEffect(() => {
        const savedData = localStorage.getItem('registrationData');
        if (savedData) {
            const parsedData = JSON.parse(savedData);
            setAccountType(parsedData.accountType);
        }
    }, []);

    return (
        <motion.div
            initial={{opacity: 0, scale: 1}}
            animate={{opacity: 1, scale: 1}}
            transition={{duration: 0.5}}
        >
            <div className={s.info}>
                <div className={s.title}>Registration Form</div>
                <Formik
                    initialValues={{
                        password: '',
                        confirmPassword: ''
                    }}
                    validationSchema={validationSchema}
                    onSubmit={(values) => {
                        const password = values.password;
                        const savedData = JSON.parse(localStorage.getItem('registrationData') || '{}');

                        savedData.password = password;

                        localStorage.setItem('registrationData', JSON.stringify(savedData));
                        props.onSubmit();
                    }}
                    >
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleSubmit
                    }) => (
                        <>
                            <form onSubmit={handleSubmit} className={s.form}>
                                <div className={s.input_block}>
                                    <div className={s.input_label}>
                                        password
                                    </div>
                                    <MyInput
                                        name="password"
                                        value={values.password}
                                        onChange={handleChange}
                                        isError={errors.password}
                                        touched={touched.password}
                                        width={'100%'}
                                        password
                                    />
                                    {touched.password && errors.password ? (
                                        <div className={s.error}>{errors.password}</div>
                                    ) : null}
                                </div>
                                <div className={s.input_block}>
                                    <div className={s.input_label}>
                                        Repeat Password
                                    </div>
                                    <MyInput
                                        name="confirmPassword"
                                        value={values.confirmPassword}
                                        onChange={handleChange}
                                        isError={errors.confirmPassword}
                                        touched={touched.confirmPassword}
                                        width={'100%'}
                                        password
                                    />
                                    {touched.confirmPassword && errors.confirmPassword ? (
                                        <div className={s.error}>{errors.confirmPassword}</div>
                                    ) : null}
                                </div>
                                <div className={s.checkboxes}>
                                    <div className={s.checkbox}>
                                        <CustomCheckbox1
                                            name={"checkbox1"}
                                            checked={checkboxState.checkbox1}
                                            onChange={(e: any) => setCheckboxState({
                                                ...checkboxState,
                                                checkbox1: e.target.checked
                                            })}
                                        />
                                        <div className={s.checkbox_text}>
                                            I agree to my personal data being processed in accordance with the
                                            <a href='/privacy_policy' className={s.link}>Privacy Policy</a>
                                        </div>
                                    </div>
                                    <div className={s.checkbox}>
                                        <CustomCheckbox1
                                            name={"checkbox2"}
                                            checked={checkboxState.checkbox2}
                                            onChange={(e: any) => setCheckboxState({
                                                ...checkboxState,
                                                checkbox2: e.target.checked
                                            })}
                                        />
                                        <div className={s.checkbox_text}>
                                            I agree with the
                                            <a href={accountType === 'business' ? '/terms_and_conditions/business' : '/terms_and_conditions/personal'}
                                               className={s.link}>
                                                Terms of use of LuxPay
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className={s.button_group}>
                                    <TransparentButton title={'Back'}
                                                       onClick={() => props.setCurrent(1)}
                                                       large
                                                       isPersonalAccountBtn
                                                       icon={<Icon component={() => <Back/>} rev={undefined}/>}
                                    />
                                    <MyBtn title={'Sign up'}
                                           type={'submit'}
                                           large
                                           isPersonalAccountBtn
                                           localDisable={!checkboxState.checkbox1 || !checkboxState.checkbox2}
                                    />
                                </div>
                            </form>
                        </>)}
                </Formik>
            </div>

        </motion.div>
    );
};

export default PasswordCreationStep;
