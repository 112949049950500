export const toHumanDate = (date: number) => {
    let dateFormat = new Date(date * 1000);
    let day = dateFormat.getDate() > 9  ? dateFormat.getDate() : `0${dateFormat.getDate()}`
    let month = dateFormat.getMonth() + 1 > 9 ? dateFormat.getMonth() + 1 : `0${dateFormat.getMonth() + 1}`
    let humanDate = dateFormat.getFullYear() + "-" + day  + "-" + month
    let time = dateFormat.getUTCHours() +':' + dateFormat.getUTCMinutes() + ':' + (dateFormat.getUTCSeconds() < 10 ? `0${dateFormat.getUTCSeconds()}` : dateFormat.getUTCSeconds())

    return {
        date:  humanDate,
        time : time
    }
}




