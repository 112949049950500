import React, {Dispatch, SetStateAction, useState} from 'react';
import CustomModal from "../CustomModal/CustomModal";
import styles from "../../ContactUsForm/ContactUsForm.module.css";
import MyBtn from "../MyBtn/MyBtn";
import {Formik} from "formik";
import {sendContactFormData} from "../../../store/reducers/ActionCreators";
import {setDisabled} from "../../../store/reducers/UserSlice";
import s from "../../../pages/PersonalAccount/PersonalAccount.module.css";
import CustomDropdown from "../CustomDropdown/CustomDropdown";
import MyInput from "../MyInput/MyInput";
import MyTextarea from "../MyTextarea/MyTextarea";
import TransparentButton from "../TransparentButton/TransparentButton";
import Icon from "@ant-design/icons";
import * as yup from "yup";
import {subjectThemes} from "../../../Constants/StaticData";
import {useAppDispatch} from "../../../hooks/redux";
import {ReactComponent as Back} from "../../../assets/img/personalAccount/back.svg";
import icon_info from '../../../assets/img/personalAccount/info-circle.svg'

type PropsType = {
    setIsModalOpen: Dispatch<SetStateAction<boolean | null>>
    isModalOpen: boolean | null
}

const validationSchema = yup.object({
    name: yup.string().required('Required field').max(100, 'max 100 symbols'),
    email: yup.string().email("invalid format").required('Required field'),
    subject: yup.string().required('Required field'),
    message: yup.string().required('Required field').max(1000, 'max 1000 symbols'),
})

const CustomModalContactContainer = (props: PropsType) => {

    const [isSuccessModalOpen, setIsSuccessModalOpen] = useState<boolean | null>(false)
    const [isErrorModalOpen, setIsErrorModalOpen] = useState<boolean | null>(false)
    const dispatch = useAppDispatch()

    return (
        <>

            <CustomModal setIsModalOpen={setIsSuccessModalOpen} isModalOpen={isSuccessModalOpen}>
                <div className={styles.popup_title}>
                    Success!
                </div>
                <div className={styles.popup_subtitle}>
                    Your request has been sent.
                </div>
                <div className={styles.popup_button_wrapper}>
                    <div className={styles.button_block}>
                        <MyBtn isPersonalAccountBtn title={"Ok"} onClick={() => setIsSuccessModalOpen(false)}/>
                    </div>
                </div>
            </CustomModal>

            <CustomModal setIsModalOpen={setIsErrorModalOpen} isModalOpen={isErrorModalOpen} type={"error"}>
                <div className={styles.popup_title}>
                    Oops!
                </div>
                <div className={styles.popup_subtitle}>
                    Something went wrong. Please try again later.
                </div>
                <div className={styles.popup_button_wrapper}>
                    <div className={styles.button_block}>
                        <MyBtn isPersonalAccountBtn buttonType={"error"} title={"Ok"} onClick={() => setIsErrorModalOpen(false)}/>
                    </div>
                </div>
            </CustomModal>

            <CustomModal setIsModalOpen={props.setIsModalOpen} isModalOpen={props.isModalOpen} type={"info"}>
                <Formik initialValues={{
                    subject: '',
                    message: ''
                }}
                        validationSchema={validationSchema}
                        onSubmit={(values) => {
                            dispatch(sendContactFormData(values))
                                .then((res) => {
                                    props.setIsModalOpen(false)
                                    setIsSuccessModalOpen(true)
                                })
                                .catch((e) => {
                                    props.setIsModalOpen(false)
                                    setIsErrorModalOpen(true)
                                })
                                .finally(() => {
                                    dispatch(setDisabled(false))
                                })
                        }}>
                    {({
                          values,
                          errors,
                          touched,
                          handleChange,
                          handleSubmit
                      }) => (
                        <form onSubmit={handleSubmit} className={s.contact_form}>
                            <div>
                                <img src={icon_info} alt=""/>
                            </div>
                            <div className={s.modal_title}>
                                Write a message
                            </div>
                            <div className={s.subject_block}>
                                <span>* subject of the message</span>
                                <CustomDropdown items={subjectThemes} id={"subject"} name={"subject"}/>
                                {
                                    errors.subject && <div className={s.error}>{errors.subject}</div>
                                }
                            </div>
                            <div className={s.message_block}>
                                <span>* Message text</span>
                                <MyTextarea
                                    id={"message"}
                                    name={"message"}
                                    value={values.message}
                                    onChange={handleChange}
                                />
                                {
                                    touched.message && errors.message &&
                                    <div className={s.error}>{errors.message}</div>
                                }
                            </div>
                            <div className={s.buttons_wrapper}>
                                <div className={s.button_block}>
                                    <TransparentButton
                                        title={"Cancel"}
                                        isPersonalAccountBtn
                                        buttonType={'info'}
                                        medium
                                        onClick={() => props.setIsModalOpen(false)}
                                    />
                                </div>
                                <div className={s.button_block}>
                                    <MyBtn title={"Send"} isPersonalAccountBtn medium buttonType={'info'}/>
                                </div>
                            </div>
                        </form>
                    )}
                </Formik>
            </CustomModal>

        </>
    );
};

export default CustomModalContactContainer;
