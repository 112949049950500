import * as yup from "yup";

export const validationSchemaForTemplate = yup.object({
    accNumberFrom: yup.string().required('Required field'),
    accNumberTo: yup.string().required('Required field').max(40, 'max 40 symbols'),
    transferMethod: yup.string().required('Required field'),
    phoneNumber: yup.string().test({
        message: 'Required field',
        test: function (value) {
            const {transferMethod} = this.parent;
            if (transferMethod === 'phone number') return value !== undefined && value !== null && value !== '';
            return true
        },
    }),
    email: yup.string().test({
        message: 'Required field',
        test: function (value) {
            const {transferMethod} = this.parent;
            if (transferMethod === 'email') return value !== undefined && value !== null && value !== '';
            return true
        },
    }),

})

export const validationSchemaForTransfer = yup.object({
    accNumberFrom: yup.string().required('Required field'),
    accNumberTo: yup.string().required('Required field').max(40, 'max 40 symbols'),
    transferMethod: yup.string().required('Required field'),
    amount: yup.string()
        .matches(/^[-+]?[0-9]*[.]?[0-9]+(?:[eE][-+]?[0-9]+)?$/, "Invalid format")
        .required('Required field')
        .max(10, 'max 10 digits')
        .test(
            'no-leading-zero',
            'invalid format',
            (value, context) => {

                const tempArr = context.originalValue.split('')

                const onlyZero = tempArr.every((item: string) => {
                    if (item === '0') {
                        return true
                    }
                })

                if (context.originalValue && onlyZero) {
                    return false
                } else if ((context.originalValue.match(/,/g) || []).length > 1) {
                    return false
                }
                return true
            }
        ),
})
