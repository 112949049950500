import {ITransacton} from "../types/interfaces";
import {toBase64} from "./toBase64";

export const PrepareDataForBankTransaction = async (values: ITransacton, code?: string, withdrawalStatus?: string) => {

    const invoiceAsString = await toBase64(values.invoice)

    console.log(values)

    // recipientDetails
    const recipientTempObject = {
        ibanCode: values.recipientsIban ? values.recipientsIban : null,
        swiftCode: values.recipientsSwiftBic ? values.recipientsSwiftBic : null,
        beneficiaryName: values.fullName,
        beneficiaryAddress: values.recipientsAddress ? values.recipientsAddress : null,
        beneficiarySortCode: values.sortCode ? values.sortCode : null ,
        beneficiaryAccountNumber: values.recipientsAccountNumber ? values.recipientsAccountNumber : null,
    }

    const testObj = {
        type: values.type,
        accountId: values.accountId,
        fromAccount: values.payerAccount,
        reference: values.reference,
        amount: Number(values.amount),
        currency: values.transferCurrency,
        paymentType: values.paymentRegions?.toLowerCase(),
        invoice: invoiceAsString,
        invoiceFilename: values.invoice.name,
        templateName: values.templateName,
    }

    const preparedForSendObject = {
        ...testObj,
        recipientDetails: recipientTempObject,
        withdrawalCode: withdrawalStatus === "notVerified" ? code : null
    }

    return preparedForSendObject
}

