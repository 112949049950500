import React from 'react';
import s from './Steps.module.css'

type PropsType = {
  quantity: number,
  activeNum: number;
}

const Steps: React.FC<PropsType> = ({ quantity, activeNum }) => {
  return (
    <div className={s.body}>
        {
            Array.from({ length: quantity }, (_, index) => index + 1).map((num) => (
              <div key={num} className={num <= activeNum + 1 ? s.active : s.inactive}></div>
            ))
        }
    </div>
  );
};

export default Steps;