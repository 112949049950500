import React, { Dispatch, SetStateAction, useState } from 'react';
import s from './SelectType.module.css'
import { NavLink } from 'react-router-dom';
import MyBtn from '../../../components/ui/MyBtn/MyBtn';

type PropsType = {
    onSubmit: () => void
}

const SelectAccountType: React.FC<PropsType> = ({ ...props }) => {

    const setAccountType = (type: string) => {
        const savedData = JSON.parse(localStorage.getItem('registrationData') || '{}');
        savedData.type = type;
        localStorage.setItem('registrationData', JSON.stringify(savedData));
        props.onSubmit();
    }

  return (
        <div className={s.account_select}>
            <div className={s.account_content}>
                <div className={s.account_selection}>
                    <div className={s.account_selection_title}>Please select account type</div>
                    <div className={s.account_selection_content}>
                        <div className={s.account_selection_item}>
                            <div className={s.item_personal_image}></div>
                            <div className={s.item_title}>Personal</div>
                            <div className={s.item_description}>
                                Manage your personal finances your way – the convenient way, 
                                the flexible way, the LuxPay way.
                            </div>
                            <div className={s.item_button}>
                                <MyBtn title='Create Personal Account' 
                                       large 
                                       isPersonalAccountBtn
                                       onClick={() => setAccountType("personal")}
                                       type={"submit"}  />
                            </div>
                        </div>
                        <div className={s.account_selection_item}>
                            <div className={s.item_business_image}></div>
                            <div className={s.item_title}>Business</div>
                            <div className={s.item_description}>
                                Advance financial operations of your business with our 
                                competitive prices and expert service.
                            </div>
                            <div className={s.item_button}>
                                <MyBtn title='Create Business Account' 
                                       large 
                                       isPersonalAccountBtn
                                       onClick={() => setAccountType("business")}
                                       type={"submit"} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className={s.account_back}>
                    <span className={s.account_back_text}>Already have an account?</span>
                    <span className={s.account_back_link}><NavLink className={s.link} to={"/login"}>Sign In</NavLink></span>
                </div>
            </div>
        </div>
  );
};

export default SelectAccountType;