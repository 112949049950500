import React from 'react';
import s from './AgentProgram.module.css'
import {motion} from 'framer-motion';
import MyBtn from '../../../components/ui/MyBtn/MyBtn';
import { CONTACT_US_FOR_AGENTS } from '../../../Constants/RoutesConstants';
import { Link } from 'react-router-dom';

const AgentProgram = () => {

    return (
        <motion.div
            initial={{opacity: 0, scale: 1}}
            animate={{opacity: 1, scale: 1}}
            transition={{duration: 0.5}}
            className={s.motion_div}
        >
            <div className={s.wrapper}>
                <div className={s.container}>
                    <div className={s.main_text_block}>
                        <div className={s.main_title}>
                            Agent Program
                        </div>
                        <div className={s.main_text}>
                            In addition to our main services and products, we offer an &nbsp;
                            <span className={s.my_link}>Agent Program</span>
                            &nbsp;to the interested parties who can attract more customers to LuxPay
                        </div>
                    </div>

                    <div className={s.provides_block}>
                        <div className={s.provides_cards_block}>

                            <div className={s.left_card}>
                                <span className={s.card_text}>
                                We are signing an Agent agreement and sharing the revenue generated from the attracted customer with the Agent
                                </span>
                            </div>

                            <div className={s.right_card}>
                                <span className={s.card_text}>
                                We are sharing <span className={s.my_link}> 50% of the onboarding fee</span> and the <span className={s.my_link}>5-10% of all revenues</span> generated from the customer activity (depending on the monthly turnover)
                                </span>
                            </div>
                        </div>
                        <div className={s.card_footer}>
                                <div className={s.main_text}>For the further information please contact LuxPay team</div>
                                <Link to={CONTACT_US_FOR_AGENTS}>
                                    <MyBtn title={'Contact Us'} large style={{marginTop: "30px"}} />
                                </Link>
                            </div>

                    </div>
                </div>

            </div>
        </motion.div>
    );
};

export default AgentProgram;
