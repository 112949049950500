import React from 'react';
import s from './ScamAlertLine.module.css'
import classnames from "classnames";
import {FRAUD_AND_SCAM_ALERT} from "../../Constants/RoutesConstants";

const ScamAlertLine = () => {
    return (
        <div>
            <a className={s.wrap_marq} href={FRAUD_AND_SCAM_ALERT}>
                <div className={s.items_wrap}>
                    <div className={classnames(s.items, s.marquee)}>
                        <div className={s.item}></div>
                        <div className={s.item}></div>
                        <div className={s.item}></div>
                        <div className={s.item}></div>
                        <div className={s.item}></div>
                        <div className={s.item}></div>
                        <div className={s.item}></div>
                        <div className={s.item}></div>
                        <div className={s.item}></div>
                        <div className={s.item}></div>
                    </div>
                    <div aria-hidden="true" className={classnames(s.items, s.marquee)}>
                        <div className={s.item}></div>
                        <div className={s.item}></div>
                        <div className={s.item}></div>
                        <div className={s.item}></div>
                        <div className={s.item}></div>
                        <div className={s.item}></div>
                        <div className={s.item}></div>
                        <div className={s.item}></div>
                        <div className={s.item}></div>
                        <div className={s.item}></div>
                    </div>
                </div>
            </a>
        </div>
    );
};

export default ScamAlertLine;
