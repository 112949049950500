import React from 'react';
import s from './About.module.css'
import {motion} from 'framer-motion';

const About = () => {

    return (
        <motion.div
            initial={{opacity: 0, scale: 1}}
            animate={{opacity: 1, scale: 1}}
            transition={{duration: 0.5}}
            className={s.motion_div}
        >
            <div className={s.wrapper}>
                <div className={s.container}>
                    <div className={s.main_text_block}>
                        <div className={s.main_title}>
                            About Us
                        </div>
                        <div className={s.main_text}>
                            Lux International Payment System is an Electronic Money Institution that was founded in
                            2019. The company has the EMI license No. 81 from the &nbsp;
                            <a
                                className={s.my_link}
                                href="https://www.lb.lt/en/sfi-financial-market-participants/uab-lux-international-payment-system">
                                Bank of Lithuania
                            </a>
                            &nbsp;since February 2021. The vision of the company is to build a solid ground for its
                            customers, both
                            private and corporate, enabling them to manage their financial affairs more easily online.
                        </div>
                    </div>

                    <div className={s.provides_block}>
                        <div className={s.provides_title}>
                            LuxPay Provides:
                        </div>

                        <div className={s.provides_cards_block}>

                            <div className={s.left_card}>
                                <span className={s.card_title}>Private and B2B Transfers</span>
                                <span className={s.card_text}>
                                    Whether you want to pay suppliers or vendors worldwide, your employees overseas or
                                    to make any other payment, we got it covered for you!
                                </span>
                                <span className={s.card_footer}>
                                    With your LuxPay multi-currency account, you can pay and get paid from across the world.
                                </span>
                            </div>

                            <div className={s.right_card}>
                                <span className={s.card_title}>Simplified Global Payments</span>
                                <span className={s.card_text}>
                                    We are a business, too, and we understand the challenges you face. So, when you open
                                    an account with LuxPay, our in-house smart routing system executes secure payments.
                                </span>
                                <span className={s.card_footer}>
                                    In short, we facilitate end-to-end seamless payments in any-to-any corridor at lower
                                    fees because we understand the business just like you!
                                </span>
                            </div>

                        </div>
                    </div>
                </div>

                <div className={s.map_block}>
                    <div className={s.map_background}></div>
                    <div className={s.map_block_text}>
                        <div className={s.map_title}>
                            Why Us?
                        </div>
                        <div className={s.text_block}>
                            <span className={s.map_subtitle}>
                                Trust
                            </span>
                            <span className={s.map_text}>
                                We are like a family where trust and respect stand above everything else. We respect our
                                clients and uphold their trust through our transparency, integrity, accountability and
                                security of our systems and performance.
                            </span>
                        </div>
                        <div className={s.text_block}>
                            <span className={s.map_subtitle}>
                                Customer Happiness
                            </span>
                            <span className={s.map_text}>
                                The happiness and success of our customers are our happiness and success. Our customers
                                trust us because we make the banking of their business easier.
                            </span>
                        </div>
                        <div className={s.text_block}>
                            <span className={s.map_subtitle}>
                                Professionalism
                            </span>
                            <span className={s.map_text}>
                                We are proud to have a team of experienced professionals who are dedicated to delivering
                                exceptional service to our clients. With their expertise, we can provide reliable advice
                                and guidance to help our clients make informed financial decisions.
                            </span>
                        </div>
                    </div>
                </div>

            </div>
        </motion.div>
    );
};

export default About;
