import React, {useEffect, useState} from 'react';
import s from "./SmsAutentication.module.css";
import {motion} from 'framer-motion';
import MyInput from "../../ui/MyInput/MyInput";
// import attention from "../../../assets/img/attention.svg";

const SmsAuntification = (props: any) => {

    const [smsCode, setSmsCode] = useState('')

    useEffect(() => {
        const tempValue = {
            ...props.dataForSend,
            smsCode: smsCode
        }
        props.setDataForSend(tempValue)
    },[smsCode])

    return (
        <motion.div
            initial={{opacity: 0, scale: 1}}
            animate={{opacity: 1, scale: 1}}
            transition={{duration: 0.5}}
        >
            <div className={s.error_wrapper}>
                {
                    props.error === "Invalid sms code"
                        ? <div className={s.error_block}>
                            {/*<img src={attention} alt=""/>*/}
                            <div>
                                Invalid sms code
                            </div>
                        </div>
                        : <></>
                }
            </div>
            <div className={s.sign_in_wrapper}>
                    <div className={s.sign_in_content}>
                        <div className={s.title}>
                            SMS authentication
                        </div>
                        <div className={s.subtitle}>
                            SMS with confirmation code sent to your number
                        </div>
                        <div className={s.input_block}>
                            <div className={s.input_label}>
                                confirmation Code
                            </div>
                            <MyInput
                                id="code"
                                name="code"
                                value={smsCode}
                                onChange={(e) => setSmsCode(e.target.value)}
                                placeholder={"6 digits"}
                            />
                        </div>
                        {/*<div className={s.button_block}>*/}
                        {/*    <MyBtn title={"Authentication"} onClick={() => sendLoginData()}/>*/}
                        {/*</div>*/}
                    </div>
            </div>
        </motion.div>
    );
};

export default SmsAuntification;
