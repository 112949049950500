import React, {Dispatch, SetStateAction} from 'react';
import s from './CustomModal.module.css'
import {motion} from 'framer-motion';
import classNames from 'classnames';

type ModalType = {
  setIsModalOpen?: Dispatch<SetStateAction<boolean | null>>
  isModalOpen: boolean | null,
  children: any,
  type?: 'confirm' | 'error' | 'contact' | 'info',
  isNotify?: boolean
}

const CustomModal = ({setIsModalOpen, isModalOpen, children, type, isNotify}: ModalType) => {
  return (
    <motion.div
      initial={{opacity: 0, scale: 1}}
      animate={{opacity: 1, scale: 1}}
      transition={{duration: 0.5}}
    >
      {isModalOpen
        ? <div className={s.wrapper}>
            <div className={classNames(isNotify? s.notify_content_wrapper : s.content_wrapper, {
              [s.infoModal]: type === 'info',
              [s.confirmModal]: type === 'confirm',
              [s.error]: type === 'error' && !isNotify,
              [s.contact]: type === 'contact'
            })}>
              <div className={classNames(type === 'contact' ? s.short_content : (isNotify ? s.notify_content : s.content)) }>
                {children}
              </div>
            </div>
        </div>
        : <></>
      }
    </motion.div>
  );
};

export default CustomModal;
