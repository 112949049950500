import React from 'react';
import s from "./Careers.module.css";
import {motion} from "framer-motion";

const Careers = () => {
    return (
        <motion.div
            initial={{opacity: 0, scale: 1}}
            animate={{opacity: 1, scale: 1}}
            transition={{duration: 0.5}}
            className={s.motion_div}
        >
            <div className={s.wrapper}>
                <div className={s.box}>
                    <span className={s.title}>Careers</span>
                    <span className={s.subtitle}>
                        <p>
                            No open positions right now.
                        </p>
                        <p>
                            There are no positions available currently.
                        </p>
                    </span>
                    <span>
                        Thank you for your interest about employment opportunities at LuxPay. Currently we do not have any
                        open positions, however if you are interested in possible future opportunities please send your
                        CV and Cover Letter to <a className={s.my_link} href="mailto:hr@luxpay.lt">hr@luxpay.</a>  lt We will be in touch if a role becomes available that matches
                        your skills and experiences.
                    </span>
                </div>
            </div>
        </motion.div>
    );
};

export default Careers;
