import React from 'react';
import {ITransaction} from "../../../../../../store/reducers/TransactionsSlice/TransactionsSlice";
import s from './PopoverContent.module.css'

type PropsType = {
    item: ITransaction
}

const PopoverContent = (props: PropsType) => {

    return (
        <div className={s.wrapper}>
            { props.item.recipientDetails?.beneficiaryName && props.item.recipientDetails?.beneficiaryName !== '' &&
                <div className={s.row}>
                    <div className={s.row_name}>Beneficiary Name</div>
                    <div className={s.row_value}>{props.item.recipientDetails.beneficiaryName}</div>
                </div>
            }

            { props.item.recipientDetails?.beneficiaryAddress && props.item.recipientDetails?.beneficiaryAddress !== '' &&
                <div className={s.row}>
                    <div className={s.row_name}>Beneficiary Address</div>
                    <div className={s.row_value}>{props.item.recipientDetails.beneficiaryAddress}</div>
                </div>
            }

            { props.item.recipientDetails?.swiftCode && props.item.recipientDetails?.swiftCode !== '' &&
                <div className={s.row}>
                    <div className={s.row_name}>Swift Code</div>
                    <div className={s.row_value}>{props.item.recipientDetails.swiftCode}</div>
                </div>
            }

            { props.item.recipientDetails?.ibanCode && props.item.recipientDetails?.ibanCode !== '' &&
                <div className={s.row}>
                    <div className={s.row_name}>Iban Code</div>
                    <div className={s.row_value}>{props.item.recipientDetails.ibanCode}</div>
                </div>
            }

            { props.item.recipientDetails?.beneficiarySortCode && props.item.recipientDetails?.beneficiarySortCode !== '' &&
                <div className={s.row}>
                    <div className={s.row_name}>Beneficiary Sort Code</div>
                    <div className={s.row_value}>{props.item.recipientDetails.beneficiarySortCode}</div>
                </div>
            }

            { props.item.recipientDetails?.beneficiaryAccountNumber && props.item.recipientDetails?.beneficiaryAccountNumber !== '' &&
                <div className={s.row}>
                    <div className={s.row_name}>Beneficiary Account Number</div>
                    <div className={s.row_value}>{props.item.recipientDetails.beneficiaryAccountNumber}</div>
                </div>
            }

        </div>
    );
};

export default PopoverContent;
