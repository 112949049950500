import React from 'react';
import s from './FraudAndScamAlert.module.css'
import styles from '../../../styles/styles.module.css'
import MyBtn from "../../../components/ui/MyBtn/MyBtn";
import {useNavigate} from "react-router-dom";
import {CONTACT_US} from "../../../Constants/RoutesConstants";

const FraudAndScamAlert = () => {

    const navigate = useNavigate();

    const redirect = () => {
        navigate(CONTACT_US)
    }

    return (
        <div className={s.wrapper}>
            <div className={styles.container}>
                <div className={s.page_description}>
                    <div className={s.title}>
                        Fraud And Scam Alert
                    </div>
                    <div className={s.subtitle}>
                        At LuxPay , your security is our top priority. We are committed to helping you keep your
                        personal and financial information safe.
                    </div>
                </div>
                <div className={s.list_wrapper}>
                    <div className={s.table_header}>
                        Please stay alert to the potential for fraud and scams by keeping the following guidelines in
                        mind:
                    </div>
                    <div className={s.table_item}>
                        <div className={s.item_number}>1</div>
                        <div className={s.item_title}>Verify Communications:</div>
                        <div className={s.item_description}>
                            Before acting on any requests for personal information, confirm that the communication is
                            from
                            a legitimate source. Contact us directly using the information on our official website if
                            you
                            are unsure.
                        </div>
                    </div>

                    <div className={s.table_item}>
                        <div className={s.item_number}>2</div>
                        <div className={s.item_title}>Protect Your Information:</div>
                        <div className={s.item_description}>
                            Never share your account details, passwords, or personal identification numbers (PINs) with
                            anyone. LuxPay will never ask for your password or PIN in an email, text message, or phone
                            call.
                        </div>
                    </div>

                    <div className={s.table_item}>
                        <div className={s.item_number}>3</div>
                        <div className={s.item_title}>Recognize Scams:</div>
                        <div className={s.item_description}>
                            Be cautious of emails, calls, or texts that demand urgent action, offer unexpected refunds,
                            or request remote access to your computer. These are common tactics used by scammers.
                        </div>
                    </div>

                    <div className={s.table_item}>
                        <div className={s.item_number}>4</div>
                        <div className={s.item_title}>Stay Updated:</div>
                        <div className={s.item_description}>
                            Keep your software and security features up-to-date to protect against malware and phishing
                            attacks.
                        </div>
                    </div>

                    <div className={s.table_item}>
                        <div className={s.item_number}>5</div>
                        <div className={s.item_title}>Monitor Your Accounts:</div>
                        <div className={s.item_description}>
                            Regularly review your account statements and transactions for any unauthorized activity.
                            Alert us immediately if you notice anything suspicious.
                        </div>
                    </div>

                    <div className={s.table_item}>
                        <div className={s.item_number}>6</div>
                        <div className={s.item_title}>Use Secure Connections:</div>
                        <div className={s.item_description}>
                            Avoid accessing your bank accounts from public Wi-Fi networks. Always ensure that the
                            website you are visiting has a secure connection <span className={s.colorfull}>https://</span>
                        </div>
                    </div>
                </div>
                <div className={s.contact_us_block}>
                    <div className={s.contact_us_block_title}>
                        Have you been a victim of fraud?
                    </div>
                    <div className={s.contact_us_block_subtitle}>
                        If you believe you have been a victim of a scam or have received suspicious communication,
                        please contact us immediately!
                    </div>
                    <div className={s.button_wrapper}>
                        <MyBtn
                            title={'Contact Us'}
                            style={{width:'297px'}}
                            onClick={() => redirect()}
                        />
                    </div>
                </div>
                <div className={s.stay_safe}>
                    <div>
                        Together, we can prevent fraud and protect your financial well-being.
                    </div>
                    <div className={s.colorfull}>
                        Stay Safe, Stay Secure.
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FraudAndScamAlert;
