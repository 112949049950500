import React from 'react';
import {motion} from "framer-motion";
import s from './CookiePolicy.module.css'

const CookiePolicy = () => {
    return (
        <motion.div
            initial={{opacity: 0, scale: 1}}
            animate={{opacity: 1, scale: 1}}
            transition={{duration: 0.5}}
        >
            <div className={s.wrapper}>
                <div className={s.container}>
                    <div className={s.title}>
                        Cookie Policy
                    </div>
                    <div className={s.desc}>
                        This cookie policy explains what cookies are and how we use them on our website. You should read
                        this policy so you can understand what type of cookies we use, the information we collect using
                        the cookies and how that information is used. By using our website you are agreeing that we can
                        use cookies in accordance with this policy.
                    </div>

                    <div className={s.body}>

                        <div className={s.subtitle}>
                            What are cookies?
                        </div>

                        <div className={s.text}>
                            Cookies are files which contain a small amount of information. Cookies are stored on the
                            browser or hard drive of your computer or device.
                        </div>

                        <div className={s.subtitle}>
                            How do we use cookies?
                        </div>

                        <div className={s.text}>
                            We use cookies to distinguish you from other users of our website and to provide a browsing
                            experience that is unique to you. Cookies are used by us so that our website can remember
                            what you have done whilst browsing, for instance, your log-in details, how far you have
                            progressed with an order and what is in your shopping cart.
                        </div>

                        <div className={s.subtitle}>
                            What type of cookies do we use?
                        </div>

                        <div className={s.text}>
                            <p>
                                Cookies can be in the form of session cookies or persistent cookies. Session cookies are
                                deleted from your computer or device when you close your web-browser. Persistent cookies
                                will remain stored on your computer or device until deleted or until they reach their
                                expiry date. <b>We use the following cookies:</b>
                            </p>
                            <ul>
                                <li>
                                    Analytical / performance cookies. These cookies allow us to recognise and count the
                                    number of visitors to our website and to see how visitors move around when they are
                                    using it. This helps us to improve the way our website works, for example, by
                                    ensuring that users find what they are looking for easily.
                                </li>
                                <li>
                                    Functionality cookies. These cookies are used to recognise you when you return to
                                    our website. This enables us to personalise our content for you, such as greeting
                                    you by name and remembering your preferences. It also allows for live chat support
                                    during your browsing experience.
                                </li>
                                <li>
                                    Targeting cookies. These cookies record your visit to our website, the pages you
                                    have visited and the links you have followed. We will use this information to make
                                    our website and the advertising displayed on it more relevant to your interests. We
                                    may also share this information with third parties for this purpose. These cookies
                                    allow you to share and send information to other websites.
                                </li>
                            </ul>
                        </div>

                        <div className={s.subtitle}>
                            What kind of information do we collect by using cookies?
                        </div>

                        <div className={s.text}>
                            When you visit our website, we may automatically collect the following types of information
                            from you: Your internet protocol (IP) address, your login information, time zone setting,
                            operating system and platform, information about your visits including the URL you came
                            from, your country, the search terms you used in our website, products you viewed or
                            searched, page response times, download errors, length of visits to certain pages, page
                            interaction information, (such as scrolling, clicks, and mouse-overs) and the methods used
                            to browse away from the page.
                        </div>

                        <div className={s.subtitle}>
                            How do you block cookies?
                        </div>

                        <div className={s.text}>
                            <p>
                                Most browsers allow you to refuse cookies. You may block our cookies by activating the
                                setting on your browser that allows you to refuse the setting of all or some cookies.
                                You can find out more about cookies and how to delete and control them on
                                www.aboutcookies.org or click help in your browser menu.
                            </p>
                            <p>
                                If you block our use of cookies, you may be unable to access certain areas of our
                                website and certain functions and pages will not work in the usual way. For example, you
                                will not be able to use the shopping list feature or shop online.
                            </p>
                        </div>

                        <div className={s.subtitle}>
                            How Lux International Payment System can change the Cookie Policy?
                        </div>

                        <div className={s.text}>
                            We may update this policy from time to time. Changes in technology, legislation and
                            authorities’ guidance may require us to inform you of the activities we undertake where it
                            affects your privacy rights. You should check this page occasionally to ensure you are
                            familiar with any changes.
                        </div>

                    </div>

                </div>
            </div>
        </motion.div>
    );
};

export default CookiePolicy;
