import React, {Dispatch, SetStateAction, useEffect, useState} from 'react';
import {Formik} from "formik";
import s from "./BankTransactionTemplate.module.css";
import styles from "../../../styles/styles.module.css";
import {Popover} from "antd";
import info from "../../../assets/img/personalAccount/information.svg";
import classnames from "classnames";
import {motion} from "framer-motion";
import {useNavigate} from "react-router-dom";
import TransparentButton from "../../ui/TransparentButton/TransparentButton";
import Icon from "@ant-design/icons";
import {ReactComponent as Back} from "../../../assets/img/personalAccount/back.svg";
import MyBtn from "../../ui/MyBtn/MyBtn";
import CustomDropdown from "../../ui/CustomDropdown/CustomDropdown";
import {useAppDispatch, useAppSelector} from "../../../hooks/redux";
import CustomDropdownForAccounts from "../../ui/CustomDropdownForAccounts/CustomDropdownForAccounts";
import MyInput from "../../ui/MyInput/MyInput";
import {createTemplate, getTemplateByIdThunk, SendTransferThunk} from "../../../store/reducers/ActionCreators";
import {PrepareDataForPaymentTemplate} from "../../../helpers/PrepareDataForPaymentTemplate";
import {ITemplate} from "../../../types/interfaces";
import {typesList} from "../../../Constants/StaticData";
import {
    getValidationSchemaForTemplates
} from "../../BankTransferFormPersonal/FormHelpersForTemplates/FormHelpersForTemplates";
import {
    getValidationSchema
} from "../../BankTransferFormPersonal/FormHelpers/FormHelpers";
import {
    PrepareDataForBankTransaction
} from "../../../helpers/PrepareDataForAzaTranzactions";
import {setDisabled} from "../../../store/reducers/UserSlice";
import {useSelector} from "react-redux";
import {selectAccountsByFilter} from "../../../store/reducers/TransactionsSlice/selector";
import {Persist} from "formik-persist";
import CustomModal from "../../ui/CustomModal/CustomModal";
import iconSuccess from "../../../assets/img/personalAccount/tick-circle.svg"

type PropsType = {
    setCurrent?: Dispatch<SetStateAction<number>>
    setTransferData?: Dispatch<SetStateAction<any>>
    id?: string | number;
    templateName: string,
    setPurpose: Dispatch<SetStateAction<string>>
}

const CurrencyHelper = (props: any) => {

    useEffect(() => {
        props.accountsList.map((account: any) => {
            if (account.number === props.payerAccount) {
                props.setCurrency(account.currency)
            }
        })
    }, [props.accountsList, props.payerAccount])

    useEffect(() => {
        props.accountsList.map((account: any) => {
            if (account.number === props.payerAccount) {
                props.setCurrency(account.currency)
            }
        })
    }, [])

    return null
}

const BankTransactionTemplate = (props: PropsType) => {
    const navigate = useNavigate()

    const [paymentBankTemplate, setPaymentBankTemplate] = useState<ITemplate>()
    const [paymentRegion, setPaymentRegion] = useState("")
    const [isShowSuccessModal, setIsShowSuccessModal] = useState(false)
    const [isShowPaymentSentSuccessModal, setIsShowPaymentSentSuccessModal] = useState(false)

    const accountsList = useSelector(selectAccountsByFilter)
    const token = useAppSelector(state => state.userReducer.userToken)
    const accountId = useAppSelector(state => state.userReducer.chosenAccountTypeId)
    const dispatch = useAppDispatch()
    const [isMobile, setIsMobile] = useState(false)
    const [currency, setCurrency] = useState('')

    useEffect(() => {
        if (props.id !== 'create') {
            dispatch(getTemplateByIdThunk(token, props.id, accountId))
                .then((res) => {
                    if (res.data.type === 'Intra Transaction') {
                        props.setPurpose('Transfer to LuxPay User')
                    } else {
                        setPaymentBankTemplate(res.data)
                    }
                })
        }
        return () => {
            localStorage.removeItem('transfer-form')
        }
    }, [])

    useEffect(() => {
        if (window.innerWidth <= 768) {
            setIsMobile(true)
        } else {
            setIsMobile(false)
        }
    }, [window.innerWidth])

    const myInitValues = {
        fullName: paymentBankTemplate?.recipientDetails?.beneficiaryName,
        recipientsIban: paymentBankTemplate?.recipientDetails?.iban || paymentBankTemplate?.recipientDetails?.ibanCode,
        recipientsSwiftBic: paymentBankTemplate?.recipientDetails?.swiftCode,
        reference: paymentBankTemplate?.recipientDetails?.reference,
        recipientsAddress: paymentBankTemplate?.recipientDetails?.beneficiaryAddress,
        recipientsAccountNumber: paymentBankTemplate?.recipientDetails?.beneficiaryAccountNumber,
        sortCode: paymentBankTemplate?.recipientDetails?.sortCode,
        paymentRegions: paymentBankTemplate?.paymentType,
        payerAccount: paymentBankTemplate?.account,
        invoice: '',
        type: 'international',
        amount: ''
    }

    const closeAndRedirect = () => {
        setIsShowSuccessModal(false)
        setIsShowPaymentSentSuccessModal(false)
        navigate(-1)
    }

    console.log(paymentBankTemplate)

    return (
        <div className={s.form_wrapper}>

            <CustomModal isModalOpen={isShowSuccessModal} type="confirm">
                <div className={classnames(styles.modal_content, s.modal_content_center)}>
                    <img src={iconSuccess} alt="" className={styles.popup_icon}/>
                    <div className={styles.popup_title}>
                        Success!
                    </div>
                    <div className={styles.popup_subtitle}>
                        Your template has been added successfully.
                    </div>
                    <div className={styles.popup_button_block}>
                        <MyBtn title={"Ok"}
                               medium
                               isPersonalAccountBtn
                               onClick={() => closeAndRedirect()}
                        />
                    </div>
                </div>
            </CustomModal>

            <CustomModal isModalOpen={isShowPaymentSentSuccessModal} isNotify>
                <div className={styles.modal_content}>
                    <div className={styles.popup_subtitle}>
                        <span className={styles.success}>Success!</span> Your request has been sent
                        successfully.
                    </div>
                    <div className={styles.popup_button_block}>
                        <MyBtn
                            isPersonalAccountBtn
                            title={"Ok"}
                            onClick={() => closeAndRedirect()}
                        />
                    </div>
                </div>
            </CustomModal>

            <motion.div
                initial={{opacity: 0, scale: 1}}
                animate={{opacity: 1, scale: 1}}
                transition={{duration: 0.5}}
            >
                <Formik
                    enableReinitialize={true}
                    initialValues={myInitValues}
                    validationSchema={props.id === "create"
                        ? getValidationSchemaForTemplates(paymentRegion, currency)
                        : getValidationSchema(paymentBankTemplate?.paymentType ?? '', currency)}
                    onSubmit={async (values, formikHelpers) => {
                        // dispatch(setDisabled(true))
                        const tempData = {
                            ...values,
                            templateName: props.templateName,
                            transferCurrency: currency,
                            accountId: accountId
                        }

                        if (props.id === 'create') {
                            const preparedData = PrepareDataForPaymentTemplate(tempData)

                            dispatch(createTemplate(token, preparedData))
                                .then((res) => {
                                    if (res.data === 'Created') {
                                        setIsShowSuccessModal(true)
                                        formikHelpers.resetForm()
                                    }
                                })
                                .finally(() => {
                                    dispatch(setDisabled(false))
                                })
                        } else {

                            const preparedData = PrepareDataForBankTransaction(tempData)

                            dispatch(SendTransferThunk(token, await preparedData, accountId))
                                .then((res) => {
                                    if (res.data === 'Created') {
                                        formikHelpers.resetForm()
                                        setIsShowPaymentSentSuccessModal(true)
                                    } else {
                                        console.error(res.data)
                                    }
                                })
                                .finally(() => {
                                    dispatch(setDisabled(false))
                                })
                        }
                    }}
                >
                    {({
                          values,
                          errors,
                          touched,
                          handleChange,
                          handleSubmit
                      }) => (
                        <form onSubmit={handleSubmit}>
                            <div className={s.row}>

                                <div className={s.input_block}>
                                    <div className={s.input_label}>
                                        * Payer account
                                        <Popover content={"Please select the account you wish to make a transfer from."}
                                                 color={'#EEEFEF'}
                                                 overlayInnerStyle={{
                                                     fontFamily: 'Inter',
                                                     width: '280px',
                                                     padding: '13px 16px'
                                                 }}
                                        >
                                            <img src={info} alt=""/>
                                        </Popover>
                                    </div>
                                    <CustomDropdownForAccounts
                                        items={accountsList}
                                        placeholder={"Please select the account"}
                                        id="payerAccount"
                                        name="payerAccount"
                                        isError={errors.payerAccount}
                                        touched={touched.payerAccount}
                                        setCurrency={setCurrency}
                                    />
                                    {errors.payerAccount && touched.payerAccount &&
                                        <div className={s.error_message}>{errors.payerAccount}</div>}
                                </div>

                                <div className={s.input_block}>
                                    <div className={s.input_label}>
                                        * Payment type
                                    </div>
                                    <CustomDropdown
                                        customId="paymentRegions"
                                        items={typesList}
                                        placeholder={"Please select the payment type"}
                                        id="paymentRegions"
                                        name="paymentRegions"
                                        isError={errors.paymentRegions}
                                        touched={touched.paymentRegions}
                                        setItem={setPaymentRegion}
                                    />
                                    {errors.paymentRegions && touched.paymentRegions &&
                                        <div className={s.error_message}>{errors.paymentRegions}</div>}
                                </div>

                            </div>

                            {/*Domestic (for EUR)*/}
                            {
                                values.paymentRegions === "domestic" && currency === "EUR" &&
                                <div className={s.block}>
                                    <div className={s.title}>
                                        Outgoing Payment Details
                                    </div>

                                    <div className={s.row}>
                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                * Beneficiary’s Name
                                            </div>
                                            <MyInput id={"fullName"}
                                                     name={"fullName"}
                                                     touched={touched.fullName}
                                                     value={values.fullName}
                                                     onChange={handleChange}
                                                     isError={errors.fullName}
                                            />
                                            {errors.fullName &&
                                                <div className={s.error_message}>{errors.fullName}</div>}
                                        </div>

                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                * IBAN
                                                <Popover
                                                    content={"Enter the recipient’s bank account number or IBAN account."}
                                                    color={'#EEEFEF'}
                                                    overlayInnerStyle={{
                                                        fontFamily: 'Manrope',
                                                        fontSize: '14px',
                                                        width: '280px',
                                                        padding: '13px 16px'
                                                    }}
                                                >
                                                    <img src={info} alt=""/>
                                                </Popover>
                                            </div>
                                            <MyInput id={"recipientsIban"}
                                                     name={"recipientsIban"}
                                                     touched={touched.recipientsIban}
                                                     value={values.recipientsIban}
                                                     onChange={handleChange}
                                                     isError={errors.recipientsIban}
                                            />
                                            {errors.recipientsIban &&
                                                <div className={s.error_message}>{errors.recipientsIban}</div>}
                                        </div>
                                    </div>

                                    <div className={s.row}>
                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                * BIC Code
                                                <Popover
                                                    content={"A SWIFT code is an international bank code that identifies " +
                                                        "particular banks worldwide. It's also known as a Bank Identifier" +
                                                        " Code (BIC). A SWIFT code consists of 8 or 11 characters."}
                                                    color={'#EEEFEF'}
                                                    overlayInnerStyle={{
                                                        fontFamily: 'Manrope',
                                                        fontSize: '14px',
                                                        width: '280px',
                                                        padding: '13px 16px'
                                                    }}
                                                >
                                                    <img src={info} alt=""/>
                                                </Popover>
                                            </div>
                                            <MyInput id={"recipientsSwiftBic"}
                                                     name={"recipientsSwiftBic"}
                                                     touched={touched.recipientsSwiftBic}
                                                     value={values.recipientsSwiftBic}
                                                     onChange={handleChange}
                                                     isError={errors.recipientsSwiftBic}
                                            />
                                            {errors.recipientsSwiftBic &&
                                                <div className={s.error_message}>{errors.recipientsSwiftBic}</div>}
                                        </div>

                                        <div className={s.mobile_row}>

                                            <div className={s.currency_dropdown}>
                                                <div className={s.input_label}>
                                                    Currency
                                                </div>
                                                <MyInput
                                                    id="transferCurrency"
                                                    name="transferCurrency"
                                                    value={currency}
                                                    disabled={true}
                                                />
                                            </div>

                                            <div className={classnames(s.input_block, s.short)}>

                                            </div>

                                        </div>
                                    </div>

                                    <div className={s.row}>
                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                * Reference
                                            </div>
                                            <MyInput id={"reference"}
                                                     name={"reference"}
                                                     touched={touched.reference}
                                                     value={values.reference}
                                                     onChange={handleChange}
                                                     isError={errors.reference}
                                            />
                                            {errors.reference &&
                                                <div className={s.error_message}>{errors.reference}</div>}
                                        </div>

                                        <div className={s.input_block}>

                                        </div>
                                    </div>
                                </div>
                            }

                            {/*International (for EUR)*/}
                            {
                                values.paymentRegions === "international" && currency === "EUR" &&
                                <div className={s.block}>
                                    <div className={s.title}>
                                        Outgoing Payment Details
                                    </div>

                                    <div className={s.row}>
                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                * Beneficiary’s Name
                                            </div>
                                            <MyInput id={"fullName"}
                                                     name={"fullName"}
                                                     touched={touched.fullName}
                                                     value={values.fullName}
                                                     onChange={handleChange}
                                                     isError={errors.fullName}
                                            />
                                            {errors.fullName &&
                                                <div className={s.error_message}>{errors.fullName}</div>}
                                        </div>

                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                * IBAN
                                                <Popover
                                                    content={"Enter the recipient’s bank account number or IBAN account."}
                                                    color={'#EEEFEF'}
                                                    overlayInnerStyle={{
                                                        fontFamily: 'Manrope',
                                                        fontSize: '14px',
                                                        width: '280px',
                                                        padding: '13px 16px'
                                                    }}
                                                >
                                                    <img src={info} alt=""/>
                                                </Popover>
                                            </div>
                                            <MyInput id={"recipientsIban"}
                                                     name={"recipientsIban"}
                                                     touched={touched.recipientsIban}
                                                     value={values.recipientsIban}
                                                     onChange={handleChange}
                                                     isError={errors.recipientsIban}
                                            />
                                            {errors.recipientsIban &&
                                                <div className={s.error_message}>{errors.recipientsIban}</div>}
                                        </div>
                                    </div>

                                    <div className={s.row}>
                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                * SWIFT / BIC Code
                                                <Popover
                                                    content={"A SWIFT code is an international bank code that identifies " +
                                                        "particular banks worldwide. It's also known as a Bank Identifier" +
                                                        " Code (BIC). A SWIFT code consists of 8 or 11 characters."}
                                                    color={'#EEEFEF'}
                                                    overlayInnerStyle={{
                                                        fontFamily: 'Manrope',
                                                        fontSize: '14px',
                                                        width: '280px',
                                                        padding: '13px 16px'
                                                    }}
                                                >
                                                    <img src={info} alt=""/>
                                                </Popover>
                                            </div>
                                            <MyInput id={"recipientsSwiftBic"}
                                                     name={"recipientsSwiftBic"}
                                                     touched={touched.recipientsSwiftBic}
                                                     value={values.recipientsSwiftBic}
                                                     onChange={handleChange}
                                                     isError={errors.recipientsSwiftBic}
                                            />
                                            {errors.recipientsSwiftBic &&
                                                <div className={s.error_message}>{errors.recipientsSwiftBic}</div>}
                                        </div>

                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                * Beneficiary’s address
                                            </div>
                                            <MyInput id={"recipientsAddress"}
                                                     name={"recipientsAddress"}
                                                     touched={touched.recipientsAddress}
                                                     value={values.recipientsAddress}
                                                     onChange={handleChange}
                                                     isError={errors.recipientsAddress}
                                            />
                                            {errors.recipientsAddress &&
                                                <div className={s.error_message}>{errors.recipientsAddress}</div>}
                                        </div>
                                    </div>

                                    <div className={s.row}>
                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                * Reference
                                            </div>
                                            <MyInput id={"reference"}
                                                     name={"reference"}
                                                     touched={touched.reference}
                                                     value={values.reference}
                                                     onChange={handleChange}
                                                     isError={errors.reference}
                                            />
                                            {errors.reference &&
                                                <div className={s.error_message}>{errors.reference}</div>}
                                        </div>

                                        <div className={s.mobile_row}>

                                            <div className={s.currency_dropdown}>
                                                <div className={s.input_label}>
                                                    Currency
                                                </div>
                                                <MyInput
                                                    id="transferCurrency"
                                                    name="transferCurrency"
                                                    value={currency}
                                                    disabled={true}
                                                />
                                            </div>

                                            <div className={classnames(s.input_block, s.short)}>

                                            </div>

                                        </div>
                                    </div>
                                </div>
                            }

                            {/*Domestic (for GBP)*/}
                            {
                                values.paymentRegions === "domestic" && currency === "GBP" &&
                                <div className={s.block}>
                                    <div className={s.title}>
                                        Outgoing Payment Details
                                    </div>

                                    <div className={s.row}>
                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                * Beneficiary’s Name
                                            </div>
                                            <MyInput id={"fullName"}
                                                     name={"fullName"}
                                                     touched={touched.fullName}
                                                     value={values.fullName}
                                                     onChange={handleChange}
                                                     isError={errors.fullName}
                                            />
                                            {errors.fullName &&
                                                <div className={s.error_message}>{errors.fullName}</div>}
                                        </div>

                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                * Beneficiary’s Account Number
                                            </div>
                                            <MyInput id={"recipientsAccountNumber"}
                                                     name={"recipientsAccountNumber"}
                                                     touched={touched.recipientsAccountNumber}
                                                     value={values.recipientsAccountNumber}
                                                     onChange={handleChange}
                                                     isError={errors.recipientsAccountNumber}
                                            />
                                            {errors.recipientsAccountNumber &&
                                                <div className={s.error_message}>{errors.recipientsAccountNumber}</div>}
                                        </div>
                                    </div>

                                    <div className={s.row}>
                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                * Beneficiary’s Sort Code
                                            </div>
                                            <MyInput id={"sortCode"}
                                                     name={"sortCode"}
                                                     touched={touched.sortCode}
                                                     value={values.sortCode}
                                                     onChange={handleChange}
                                                     isError={errors.sortCode}
                                            />
                                            {errors.sortCode &&
                                                <div className={s.error_message}>{errors.sortCode}</div>}
                                        </div>

                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                * Reference
                                            </div>
                                            <MyInput id={"reference"}
                                                     name={"reference"}
                                                     touched={touched.reference}
                                                     value={values.reference}
                                                     onChange={handleChange}
                                                     isError={errors.reference}
                                            />
                                            {errors.reference &&
                                                <div className={s.error_message}>{errors.reference}</div>}
                                        </div>
                                    </div>

                                    <div className={s.row}>
                                        <div className={s.mobile_row}>

                                            <div className={s.currency_dropdown}>
                                                <div className={s.input_label}>
                                                    Currency
                                                </div>
                                                <MyInput
                                                    id="transferCurrency"
                                                    name="transferCurrency"
                                                    value={currency}
                                                    disabled={true}
                                                />
                                            </div>

                                            <div className={classnames(s.input_block, s.short)}>

                                            </div>

                                        </div>

                                        <div className={s.input_block}>
                                        </div>
                                    </div>
                                </div>
                            }

                            {/*International (for GBP)*/}
                            {
                                values.paymentRegions === "international" && currency === "GBP" &&
                                <div className={s.block}>
                                    <div className={s.title}>
                                        Outgoing Payment Details
                                    </div>

                                    <div className={s.row}>
                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                * Beneficiary’s Name
                                            </div>
                                            <MyInput id={"fullName"}
                                                     name={"fullName"}
                                                     touched={touched.fullName}
                                                     value={values.fullName}
                                                     onChange={handleChange}
                                                     isError={errors.fullName}
                                            />
                                            {errors.fullName &&
                                                <div className={s.error_message}>{errors.fullName}</div>}
                                        </div>

                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                * IBAN
                                                <Popover
                                                    content={"Enter the recipient’s bank account number or IBAN account."}
                                                    color={'#EEEFEF'}
                                                    overlayInnerStyle={{
                                                        fontFamily: 'Manrope',
                                                        fontSize: '14px',
                                                        width: '280px',
                                                        padding: '13px 16px'
                                                    }}
                                                >
                                                    <img src={info} alt=""/>
                                                </Popover>
                                            </div>
                                            <MyInput id={"recipientsIban"}
                                                     name={"recipientsIban"}
                                                     touched={touched.recipientsIban}
                                                     value={values.recipientsIban}
                                                     onChange={handleChange}
                                                     isError={errors.recipientsIban}
                                            />
                                            {errors.recipientsIban &&
                                                <div className={s.error_message}>{errors.recipientsIban}</div>}
                                        </div>
                                    </div>

                                    <div className={s.row}>
                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                * SWIFT / BIC Code
                                                <Popover
                                                    content={"A SWIFT code is an international bank code that identifies " +
                                                        "particular banks worldwide. It's also known as a Bank Identifier" +
                                                        " Code (BIC). A SWIFT code consists of 8 or 11 characters."}
                                                    color={'#EEEFEF'}
                                                    overlayInnerStyle={{
                                                        fontFamily: 'Manrope',
                                                        fontSize: '14px',
                                                        width: '280px',
                                                        padding: '13px 16px'
                                                    }}
                                                >
                                                    <img src={info} alt=""/>
                                                </Popover>
                                            </div>
                                            <MyInput id={"recipientsSwiftBic"}
                                                     name={"recipientsSwiftBic"}
                                                     touched={touched.recipientsSwiftBic}
                                                     value={values.recipientsSwiftBic}
                                                     onChange={handleChange}
                                                     isError={errors.recipientsSwiftBic}
                                            />
                                            {errors.recipientsSwiftBic &&
                                                <div className={s.error_message}>{errors.recipientsSwiftBic}</div>}
                                        </div>

                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                * Beneficiary’s address
                                            </div>
                                            <MyInput id={"recipientsAddress"}
                                                     name={"recipientsAddress"}
                                                     touched={touched.recipientsAddress}
                                                     value={values.recipientsAddress}
                                                     onChange={handleChange}
                                                     isError={errors.recipientsAddress}
                                            />
                                            {errors.recipientsAddress &&
                                                <div className={s.error_message}>{errors.recipientsAddress}</div>}
                                        </div>
                                    </div>

                                    <div className={s.row}>

                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                * Reference
                                            </div>
                                            <MyInput id={"reference"}
                                                     name={"reference"}
                                                     touched={touched.reference}
                                                     value={values.reference}
                                                     onChange={handleChange}
                                                     isError={errors.reference}
                                            />
                                            {errors.reference &&
                                                <div className={s.error_message}>{errors.reference}</div>}
                                        </div>

                                        <div className={s.mobile_row}>

                                            <div className={s.currency_dropdown}>
                                                <div className={s.input_label}>
                                                    Currency
                                                </div>
                                                <MyInput
                                                    id="transferCurrency"
                                                    name="transferCurrency"
                                                    value={currency}
                                                    disabled={true}
                                                />
                                            </div>

                                            <div className={classnames(s.input_block, s.short)}>

                                            </div>

                                        </div>
                                    </div>
                                </div>
                            }

                            {
                                props.id !== 'create' &&
                                <div className={s.row}>

                                    <div className={s.mobile_row}>
                                        <div className={classnames(s.input_block, s.short)}>
                                            <div className={s.input_label}>
                                                * Amount
                                                <Popover content={"Select a currency and enter the transfer amount.\n" +
                                                    "In the account balance in the selected currency is insufficient, the system " +
                                                    "will automatically offer you to top up using funds in a different currency " +
                                                    "you have on the account."}
                                                         color={'#EEEFEF'}
                                                         overlayInnerStyle={{
                                                             fontFamily: 'Inter',
                                                             width: '280px',
                                                             padding: '13px 16px'
                                                         }}
                                                >
                                                    <img src={info} alt=""/>
                                                </Popover>
                                            </div>
                                            <MyInput id={"amount"}
                                                     name={"amount"}
                                                     value={values.amount}
                                                     onChange={handleChange}
                                                     isError={errors.amount}
                                            />
                                            {errors.amount && <div className={s.error_label}>{errors.amount}</div>}
                                        </div>

                                        <div className={s.languages_dropdown}>
                                            <MyInput
                                                id="transferCurrency"
                                                name="transferCurrency"
                                                value={currency}
                                            />
                                        </div>
                                    </div>

                                </div>

                            }

                            <div className={s.continue_block}>
                                <div className={s.required}>
                                    <div>
                                        * required fields.
                                    </div>
                                </div>
                                {!props.id && <div className={s.button_wrapper}>
                                    <MyBtn
                                        title={"Continue"}
                                        type={"submit"}
                                        isPersonalAccountBtn
                                    />
                                </div>}
                            </div>

                            <div className={s.buttonsWrapper}>
                                <div className={s.button_block}>
                                    <TransparentButton title={'Back'}
                                                       isPersonalAccountBtn
                                                       large
                                                       icon={<Icon component={() => <Back/>} rev={undefined}/>}
                                                       onClick={() => {
                                                           navigate(-1);
                                                       }}/>
                                </div>

                                <MyBtn title={props.id === 'create' ? 'Save' : 'Continue'}
                                       large
                                       style={{width: isMobile ? '100%' : 180}}
                                       type="submit"
                                       isPersonalAccountBtn
                                       onClick={() => console.log(errors)}
                                />
                            </div>

                            <Persist name="transfer-form"/>

                            <CurrencyHelper
                                accountsList={accountsList}
                                payerAccount={values.payerAccount}
                                setCurrency={setCurrency}
                            />

                        </form>
                    )}
                </Formik>
            </motion.div>
        </div>
    );
};

export default BankTransactionTemplate;
