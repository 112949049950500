import React from 'react'
import s from "../AccountStatement/AccountStatement.module.css";
import {Tab, TabList, TabPanel, Tabs} from "react-tabs";
import {motion} from "framer-motion";
import DetailsForBankTransfer from "./DetailsForBankTransfer/DetailsForBankTransfer";
import DetailsForDebitCard from "./DetailsForDebitCard/DetailsForDebitCard";

export const AccountTopUp = () => {
    return (
        <motion.div
            initial={{opacity: 0, scale: 1}}
            animate={{opacity: 1, scale: 1}}
            transition={{duration: 0.5}}
        >
            <div className={s.container}>
                <Tabs>
                    <TabList className={s.tabstitle_block}>
                        <Tab selectedClassName={s.active_tab} className={s.tabtitle}>Details for the Bank Transfer</Tab>
                        <div className={s.separator}></div>
                        <Tab selectedClassName={s.active_tab} className={s.tabtitle}>Details for the Debit Card Transfer</Tab>
                    </TabList>
                    <TabPanel>
                        <DetailsForBankTransfer/>
                    </TabPanel>
                    <TabPanel>
                        <DetailsForDebitCard/>
                    </TabPanel>
                </Tabs>
            </div>
        </motion.div>
    )
}
