import {useEffect} from "react";
import {useField, useFormikContext} from "formik";
import {
    defaultValidation,
    validationSchemaDomesticEUR,
    validationSchemaDomesticGBP,
    validationSchemaInternationalEUR, validationSchemaInternationalGBP
} from "./ValidationSchemas";
import * as yup from "yup";

export const CurrencyHelper = (props: any) => {
    useEffect(() => {
        props.accountsList.map((account: any) => {
            if (account.number === props.payerAccount) {
                props.setCurrency(account.currency)
            }
        })
    }, [props.accountsList, props.payerAccount])

    return null
}

export const PaymentTypeHelper = (props: any) => {

    const { values } = useFormikContext();
    const [field, meta, helpers] = useField<string>(props.name);
    const { setValue } = helpers;

    useEffect(() => {
        setValue("")
        // @ts-ignore
    },[values.recipientsBankCountry])

    return null
}

// Function that returns the validation schema based on the payment region
export const getValidationSchema = (paymentRegion: string, currency? : string) => {

    const amountValidation = yup.string()
        .matches(/^[-+]?[0-9]*[.]?[0-9]+(?:[eE][-+]?[0-9]+)?$/, "Must be only digits")
        .matches(/^[-+]?[0-9]*[.]?[0-9]+(?:[eE][-+]?[0-9]+)?$/, "Must be only digits")
        .required('Required field')
        .max(10, 'max 10 digits')
        .test(
            'no-leading-zero',
            'invalid format',
            (value, context) => {

                const tempArr = context.originalValue.split('')

                const onlyZero = tempArr.every((item: string) => {
                    if (item === '0') {
                        return true
                    }
                })

                if (context.originalValue && onlyZero) {
                    return false
                } else if ((context.originalValue.match(/,/g) || []).length > 1) {
                    return false
                }
                return true
            }
        )

    const comparedValidationSchemaInternationalEUR = yup.object({
        fullName: validationSchemaInternationalEUR.fullName,
        recipientsIban: validationSchemaInternationalEUR.recipientsIban,
        recipientsSwiftBic: validationSchemaInternationalEUR.recipientsSwiftBic,
        recipientsAddress: validationSchemaInternationalEUR.recipientsAddress,
        reference: validationSchemaInternationalEUR.reference,
        payerAccount: validationSchemaInternationalEUR.payerAccount,
        paymentRegions: validationSchemaInternationalEUR.paymentRegions,
        templateName: validationSchemaInternationalEUR.templateName,
        amount: amountValidation
    });

    const comparedValidationSchemaDomesticEUR = yup.object({
        payerAccount: validationSchemaDomesticEUR.payerAccount,
        paymentRegions: validationSchemaDomesticEUR.paymentRegions,
        fullName: validationSchemaDomesticEUR.fullName,
        recipientsIban: validationSchemaDomesticEUR.recipientsIban,
        recipientsSwiftBic: validationSchemaDomesticEUR.recipientsSwiftBic,
        reference: validationSchemaDomesticEUR.reference,
        templateName: validationSchemaDomesticEUR.templateName,
        amount: amountValidation
    });

    const comparedValidationSchemaDomesticGBP = yup.object({
        payerAccount: validationSchemaDomesticGBP.payerAccount,
        fullName: validationSchemaDomesticGBP.fullName,
        reference: validationSchemaDomesticGBP.reference,
        recipientsAccountNumber: validationSchemaDomesticGBP.recipientsAccountNumber,
        sortCode: validationSchemaDomesticGBP.sortCode,
        paymentRegions: validationSchemaDomesticGBP.paymentRegions,
        templateName: validationSchemaDomesticGBP.templateName,
        amount: amountValidation
    });

    const comparedValidationSchemaInternationalGBP = yup.object({
        payerAccount: validationSchemaInternationalGBP.payerAccount,
        recipientsIban: validationSchemaInternationalGBP.recipientsIban,
        recipientsSwiftBic: validationSchemaInternationalGBP.recipientsSwiftBic,
        fullName: validationSchemaInternationalGBP.fullName,
        reference: validationSchemaInternationalGBP.reference,
        recipientsAddress: validationSchemaInternationalGBP.recipientsAddress,
        paymentRegions: validationSchemaInternationalGBP.paymentRegions,
        templateName: validationSchemaInternationalGBP.templateName,
        amount: amountValidation
    });

    if (currency === "EUR") {
        switch (paymentRegion) {
            case "domestic":
                return comparedValidationSchemaDomesticEUR;
            case "international":
                return comparedValidationSchemaInternationalEUR;
        }
        return
    }

    if (currency === "GBP") {
        switch (paymentRegion) {
            case "domestic":
                return comparedValidationSchemaDomesticGBP;
            case "international":
                return comparedValidationSchemaInternationalGBP;
        }
        return
    }

    return defaultValidation
}
