import React from 'react';
import s from './TermsAndConditionsPrivate.module.css'
import {motion} from "framer-motion";

const TermsAndConditionsPrivate = () => {
    return (
        <motion.div
            initial={{opacity: 0, scale: 1}}
            animate={{opacity: 1, scale: 1}}
            transition={{duration: 0.5}}
        >
            <div className={s.wrapper}>
                <div className={s.container}>
                    <div className={s.title}>
                        Terms & Conditions
                    </div>

                    <div className={s.content}>
                        <div className={s.content_title}>
                            General Terms & Conditions for Private Clients
                        </div>

                        <div className={s.subtitle}>
                            1. Definitions
                        </div>

                        <div className={s.text_block}>
                            <p>
                                1.1. <b>Account</b> – the result of registration in the System, where information about
                                Client’s
                                Payment Accounts is provided and in which Services are provided.
                            </p>
                            <p>
                                1.2. <b>Agreement</b> – an agreement concluded between the Client and the Company
                                regarding
                                provision of Services, including but not limited to any annexes, supplements to this
                                Agreement and information on the Company’s website provided as reference in this
                                Agreement.
                            </p>
                            <p>
                                1.3. <b>Business day</b> –the day on which the Company provides its Services, usually
                                Monday to
                                Friday, except public holiday in Lithuania.
                            </p>
                            <p>
                                1.4. <b>Business Payment Account</b> – payment account opened by the Company to the
                                Client for
                                the purpose to use Company’s Services for Client’s business needs.
                            </p>
                            <p>
                                1.5. <b>Client</b> – a natural person who has registered in the System and created an
                                Account.
                            </p>
                            <p>
                                1.6. <b>Client identification</b> – identification and verification of the identity of
                                the Client and/or his representative(s) following the procedure set in the System.
                            </p>
                            <p>
                                1.7. <b>Close relative</b> – Client’s parents and children, grandparents and
                                grandchildren,
                                brothers and sisters.
                            </p>
                            <p>
                                1.8. <b>Company</b> – UAB "Lux International Payment System", legal person code
                                305158007,
                                registered address Saltiniu str. 24, Vilnius, Lithuania, email address <a
                                href="mailto:info@luxpay.lt">info@luxpay.lt</a>,
                                telephone number +370 695 02799, electronic money institution license No. 81 issued on
                                2021-02-18 by the Bank of Lithuania; data about UAB "Lux International Payment System"
                                is collected and stored at the Register of Legal Entities of the Republic of Lithuania.
                                List of entities, which were issued with electronic money institution licence, and copy
                                of Company’s licence can be obtained here:
                                <a href="https://www.lb.lt/en/sfi-financial-market-participants">
                                    https://www.lb.lt/en/sfi-financial-market-participants
                                </a>.
                                The supervisory body of the
                                Company is the Bank of Lithuania, identification code 188607684, address: Gedimino av.
                                6, LT-01103 Vilnius, Lithuania.
                            </p>
                            <p>
                                1.9. <b>Commission fee</b> – a fee charged by the Company for the provision of Services.
                            </p>
                            <p>
                                1.10. <b>Durable medium</b> means any medium which enables the Client to store
                                information
                                addressed personally to him in a way accessible for future reference for a period of
                                time adequate to the purposes of the information and which allows the unchanged
                                reproduction of the information stored.
                            </p>
                            <p>
                                1.11. <b>Electronic money (also referred to as funds in this Agreement)</b> – monetary
                                value,
                                electronically stored on Payment Account for the execution of Payment operations via
                                System.
                            </p>
                            <p>
                                1.12. <b>Fraud</b> – an act or omission due to which person fraudulently acquired
                                another
                                person's property or rights to the property as well as evaded or extinguished obligation
                                related to the property for his or other person’s benefit.
                            </p>
                            <p>
                                1.13. <b>Payer</b> – a person who submits the Payment order.
                            </p>
                            <p>
                                1.14. <b>Payment</b> – an act, initiated by the Payer or on his behalf or by the
                                Recipient, of
                                placing, transferring or withdrawing Electronic money, irrespective of any underlying
                                obligations between the Payer and the Recipient.
                            </p>
                            <p>
                                1.15. <b>Payment Account</b> – payment account opened by the Company to the Client for
                                the
                                purpose to use Company’s Services for Client’s personal needs. Payment Account is opened
                                only after Client identification.
                            </p>
                            <p>
                                1.16. <b>Payment order</b> – an instruction by a Payer or the Recipient to its payment
                                service
                                provider requesting the execution of a Payment.
                            </p>
                            <p>
                                1.17. <b>Payment services</b> – services enabling cash to be placed on a payment account
                                as
                                well as all the operations required for operating a payment account; services enabling
                                cash withdrawals from a payment account as well as all the operations required for
                                operating a payment account; execution of payment transactions, including transfers of
                                funds on a payment account with the payment service provider of the payment service user
                                or with another payment service provider: execution of direct debits, including one-off
                                direct debits, execution of payment transactions through a payment card or a similar
                                device and/or execution of credit transfers, including standing orders; issuing of
                                Payment Instruments and processing of incoming payments.
                            </p>
                            <p>
                                1.18. <b>Payment transfer</b> – a Payment service when at the initiative of the Payer
                                money is
                                transferred/Electronic money is redeemed to the Payment Account.
                            </p>
                            <p>
                                1.19. <b>Party</b> – the Company or the Client.
                            </p>
                            <p>
                                1.20. <b>Personal Data</b> – any information related to the Client whose identity can be
                                identified, directly or indirectly, in particular by reference to an identifier such as
                                a name, an identification number, location data, an online identifier or to one or more
                                factors specific to the physical, physiological, genetic, mental, economic, cultural or
                                social identity of that Client.
                            </p>
                            <p>
                                1.21. <b>Personalized Security Measures</b> – individual one-time code sent via SMS by
                                the
                                Company to the Client in order to access Payment Account and/or to authorise Payment
                                orders. The Company may at its sole discretion decide on the type of Personalized
                                Security Measures to be issued and may change it at any time necessary.
                            </p>
                            <p>
                                1.22. <b>Pricing</b> – prices for the Company’s Services to the Client.
                            </p>
                            <p>
                                1.23. <b>Recipient</b> – a natural or legal person who receives (or intends to receive)
                                the
                                money and is indicated in the Payment order as a Recipient of the Payment.
                            </p>
                            <p>
                                1.24. <b>Services</b> – Electronic money issuance or redemption and Payment services
                                provided by the Company.
                            </p>
                            <p>
                                1.25. <b>System</b> – a software solution used by the Company for provision of Services.
                            </p>
                            <p>
                                1.26. <b>Statement</b> – a document, which includes information about Payments executed
                                during
                                a specific period of time.
                            </p>
                            <p>
                                1.27. <b>State authorities</b> – state and municipal institutions and bodies, companies
                                and
                                public institutions, financed from the state or municipal budgets and funds of state
                                money and authorized to provide public administration services or other public functions
                                according to the national laws.
                            </p>
                            <p>
                                1.28. <b>Unique identifier</b> – a combination of letters, numbers or symbols specified
                                to the
                                Client by the Company and to be provided by the Client to identify unambiguously the
                                Client participating in the Payment and/or his Payment account used in the Payment.
                            </p>
                        </div>

                        <div className={s.subtitle}>
                            2. General provisions
                        </div>

                        <div className={s.text_block}>
                            <p>
                                2.1. This document serves as General terms and conditions of the Payment service
                                agreement. The Agreement is concluded between the Company and the Client.
                            </p>
                            <p>
                                2.2. This Agreement governs the provision of Services to the Client. In addition to this
                                Agreement, the relationship between the Company and the Client related to the provision
                                of Services is also governed by legal acts applicable to the Client, annexes,
                                supplements to this Agreement concluded with the Client, other agreements, rules and
                                principles of justice, fairness and reasonableness.
                            </p>
                            <p>
                                2.3. The Company familiarizes the Client with the provision of this Agreement by
                                providing it in writing or by sending it via email before the conclusion of the
                                Agreement or before the offer to conclude such Agreement. The Client has a right to
                                receive, on request, the information on the terms and conditions of this Agreement and
                                the conditions of provision of Payment services on paper or on another Durable medium at
                                any time during the contractual relationship.
                            </p>
                            <p>
                                2.4. Any other annexes, supplements to this Agreement can also be obtained online on the
                                Company’s website or, in certain cases, sent to the Client via email e.g. at the
                                Client’s request.
                            </p>
                            <p>
                                2.5. The Client is entitled to use Payment Account only for his personal needs. If the
                                Client intends using Company’s Services for the business purposes, the Client must open
                                the Business Payment Account. In case of the Client’s failure to do so, it will be
                                considered as the material breach of the Agreement and the Agreement will be subject to
                                unilateral termination by the Company with an immediate effect.
                            </p>
                            <p>
                                2.6. The Parties have concluded this Agreement in the English language.
                            </p>
                        </div>

                        <div className={s.subtitle}>
                            3. Registration and Creation of an Account
                        </div>

                        <div className={s.text_block}>
                            <p>
                                3.1. In order to use Company’s Services, the Client must first open his Account by
                                registering on the Company’s website. The Company has the right to refuse to register
                                the potential Client without indicating reasons, although the Company’s refusal will
                                always be based on significant reason.
                            </p>
                            <p>
                                3.2. The Client may only open Account if the Client is at least 18 (eighteen) years old
                                and such action is legal according to the Client’s country of residence. The Company at
                                its sole discretion is entitled to set different age limits for different Services.
                            </p>
                            <p>
                                3.3. As part of the signup process, the Client will have to:
                            </p>
                            <ul>
                                <li>
                                    3.3.1. express the consent to comply with this Agreement, i.e. accept the provision
                                    of this Agreement and any other documents that may be necessary depending on the
                                    scope of Services, which the Client intends to use;
                                </li>
                                <li>
                                    3.3.2. confirm that the correct data is provided and if there is a need for changing
                                    or adding data, only the correct data will be submitted. The Client is entitled to
                                    bear any losses that may occur due to the submission of invalid or incorrect data.
                                </li>
                                <li>
                                    3.3.3. represent and warrant to the Company that opening of Account does not violate
                                    any laws and regulations applicable to the Client.
                                </li>
                            </ul>
                            <p>
                                By accepting provisions of this Agreement, the Client undertakes to indemnify the
                                Company against any losses that may be incurred by the Company in connection with the
                                breach of obligations by the Client.
                            </p>
                            <p>
                                3.4. After registration in the System, an Account for the Client is created and the
                                Payment Account is opened automatically. The Client is allowed to possess only 1 (one)
                                Account. The Client is entitled to open not more than 1 (one) Payment Accounts. Attempt
                                to breach the maximum number of allowed Payment Accounts will be considered as an
                                attempt of fraud and may result in termination of the business relationship between the
                                Company and the Client, and respectively closure of Client’s Payment Account opened with
                                the Company.
                            </p>
                            <p>
                                3.5. Payment Account allows the Client to deposit money in the Payment Account, execute
                                local and international money transfers, contribution payments, also to receive money to
                                the Payment Account as well as to use other functionalities available in the Payment
                                Account.
                            </p>
                            <p>
                                3.6. The Client is entitled to link other users to Payment Account and determine the
                                extent of their rights to act. The Client is solely responsible to ensure that each
                                linked user is assigned with the correct extent of rights, therefore the Client solely
                                covers any and all damages in relation to misuse of Payment Account because of incorrect
                                assignment of the rights. If the Company suffers any damages in relation thereto, such
                                damages are borne by the Client.
                            </p>
                            <p>
                                3.7. In order to use the Services, the Client has to perform Client identification
                                procedure under the circumstances and the procedures set out in the Agreement in order
                                for the Company to comply with the requirements of the Law on Prevention of Money
                                Laundering and Terrorist Financing of the Republic of Lithuania or other applicable
                                laws.
                            </p>
                            <p>
                                3.8. The Company is entitled to request and the Client is obliged to submit information,
                                data and documents, as well as to fill in questionnaires submitted by the Company and/or
                                perform other actions that may be required to establish the Client’s identity to comply
                                with the applicable laws. The Company has the right to demand from the Client to provide
                                original documents and (or) their copies and (or) copies of documents certified by a
                                notary, any other person authorised by the state, confirmed by mark “Apostille” (or
                                legalised in other applicable way) and (or) translated into Lithuanian, Russian or
                                English. All documents and information are prepared and provided at the expense of the
                                Client.
                            </p>
                            <p>
                                3.9. The Company retains the right to re-establish the Client’s identity at any time
                                during the term of the Agreement and in connection to that, to request to submit to the
                                Company additional information, data and/or documents, fill in documents and/or perform
                                actions. Failure to comply with this requirement or the negative result of such
                                re-establishment of the identity shall be subject to termination of this Agreement with
                                an immediate effect.
                            </p>
                            <p>
                                3.10. The Company is also entitled to request the Client to submit information, data
                                and/or documents regarding the Client’s Payments performed on Payment Account in order
                                to justify such Payments. Failure to comply with this requirement may result in refusal
                                to execute the Payment, suspension of Services to the Client or may be subject to
                                termination of this Agreement with an immediate effect by the Company.
                            </p>
                        </div>

                        <div className={s.subtitle}>
                            4. Prices and Settlement procedure
                        </div>

                        <div className={s.text_block}>
                            <p>
                                4.1. General prices for Services, applied Commission fees are provided on the Company’s
                                website. Prices for individual Services are provided in the Client‘s Account.
                            </p>
                            <p>
                                4.2. The Client may hold funds in the Payment Account in a different currency, i.e. EUR,
                                USD and GBP. In such case, the Client undertakes responsibility for the possible
                                depreciation of the money due to changes in exchange rates. Currency exchange is based
                                on the exchange rate of the Company, which is valid at the moment of conversion and is
                                constantly updated and published on the Company’s website. Currency exchange rates are
                                applied immediately and without separate notice.
                            </p>
                            <p>
                                4.3. Where a currency conversion service is offered prior to the initiation of the
                                Payment and where that currency conversion service is offered at an ATM, at the point of
                                sale or by the Recipient, the party offering the currency conversion service will
                                disclose to the Payer the Commission fee as well as the exchange rate to be used for
                                converting the Payment. The Payer shall agree to the currency conversion service on that
                                basis.
                            </p>
                            <p>
                                4.4. Opening and maintenance of the Payment account is being charged in accordance with
                                the fee structure provided to the Client in a separate document together with this
                                agreement. If the Client did not log into the Account and perform transactions on the
                                Payment Account after the account activation for more than 6 (months) months, the
                                Company will notify the Client about the dormant account, the applied Commission fee for
                                the Payment Account(s) maintenance and ability to close the Payment Account. If the
                                Client never accessed his Payment account within 3 (three) months after the account
                                activation, the Company will notify the Client about the dormant account, the applied
                                Commission fee for the Payment Account(s) maintenance and ability to close the Payment
                                Account.
                            </p>
                            <p>
                                4.5. The Company’s Commission fees are deducted:
                            </p>
                            <ul>
                                <li>
                                    4.5.1. At the time of the Payment;
                                </li>
                                <li>
                                    4.5.2. if Commission fees were not deducted when executing a Payment, the Company
                                    has the right to deduct them later.
                                </li>
                            </ul>
                            <p>
                                4.6. The Commission fee for the Payment is indicated to the Client before the Payment,
                                unless otherwise agreed by the Parties.
                            </p>
                            <p>
                                4.7. Services provided by the Company may be subject to additional Commission fee from
                                the bank where the Client’s bank account is opened depending on the agreement concluded
                                between the bank and the Client.
                            </p>
                            <p>
                                4.8. The Commission fee is paid in the currency indicated in this Agreement, its annexes
                                or supplements or on the Company’s website.
                            </p>
                            <p>
                                4.9. The Client confirms that he carefully studied the Pricing.
                            </p>
                            <p>
                                4.10. At the Client’s request, the Company provides conditions regularly, at least once
                                a month, free of charge, to get acquainted with the following information:
                            </p>
                            <ul>
                                <li>
                                    4.10.1. information enabling the Payer to identify each Payment and information
                                    relating to the Recipient;
                                </li>
                                <li>
                                    4.10.2. the amount of the Payment in the currency in which the Payer’s Payment
                                    Account is debited or in the currency used for the Payment order;
                                </li>
                                <li>
                                    4.10.3. the amount of Commission fee for the Payment and a breakdown of the amount
                                    of the Commission fee, or the interest payable by the Payer;
                                </li>
                                <li>
                                    4.10.4. the exchange rate used in the Payment by the Payer’s payment service
                                    provider, and the amount of the Payment after that currency conversion where the
                                    execution of the Payment involved currency conversion;
                                </li>
                                <li>
                                    4.10.5. the debit value date or the date of receipt of the Payment order.
                                </li>
                            </ul>
                            <p>
                                4.11. At the Client’s request, the Company provides conditions regularly, at least once
                                a month, free of charge, to get acquainted with the following information:
                            </p>
                            <ul>
                                <li>
                                    4.11.1. the information enabling the Client as the Recipient to identify the Payment
                                    and the Payer, and any information transferred with the Payment;
                                </li>
                                <li>
                                    4.11.2. the amount of the Payment in the currency in which the Client’s Payment
                                    account is credited;
                                </li>
                                <li>
                                    4.11.3. the amount of Commission fee for the Payment and a breakdown of the amount
                                    of the Commission fee, or the interest payable by the Client;
                                </li>
                                <li>
                                    4.11.4. the exchange rate used in the Payment by the Company, and the amount of the
                                    Payment before that currency conversion where execution of the Payment involved
                                    currency conversion;
                                </li>
                                <li>
                                    4.11.5. the credit value date.
                                </li>
                            </ul>
                            <p>
                                4.12. Fees for the Company’s Services are deducted from the Payment Account. In case the
                                amount of money on the Payment Account is less than the amount of the Payment transfer
                                and the price of the Services, the Payment transfer is not executed.
                            </p>
                        </div>

                        <div className={s.subtitle}>
                            5. Terms of issuance and redemption of Electronic money
                        </div>

                        <div className={s.text_block}>
                            <p>
                                5.1. Money held on the Payment Account is considered Electronic money which the Company
                                issues after the Client transfers or deposits money to Client’s Payment Account. After
                                the Client deposits or transfers money to Client’s Payment Account and the Company
                                receives the money, the Company credits it to the Client's account, at the same time
                                issuing Electronic money at the nominal value. The Electronic money is credited to and
                                held on the Client's Payment Account.
                            </p>
                            <p>
                                5.2. The nominal value of Electronic money coincides with the value of money deposited
                                or transferred to the Payment Account after deduction of a standard Commission fee
                                applicable to a particular payment mean.
                            </p>
                            <p>
                                5.3. At the request of the Client, Electronic money held on Client’s Payment Account
                                shall without undue delay be redeemed at its nominal value at any time. The Client
                                submits the request for redemption of Electronic money by generating a Payment order to
                                transfer Electronic money from Client’s Payment Account to any other account specified
                                by the Client or withdraw Electronic money from Client’s Payment Account. The Company
                                has the right to apply limitations for the redemption of Electronic money specified in
                                this Agreement and applicable laws.
                            </p>
                            <p>
                                5.4. No specific conditions for Electronic money redemption, that would differ from the
                                standard conditions for transfers and other Payments performed on the Payment account,
                                shall be applied. The amount of redeemed or transferred Electronic money is chosen by
                                the Client.
                            </p>
                            <p>
                                5.5. No additional fee for Electronic money redemption is applied. In the event of
                                redemption of Electronic money, the Client pays the usual Commission fee for a money
                                transfer or withdrawal which depends on the method of Electronic money transfer or
                                withdrawal chosen by the Client. The standard Company’s Commission fees for money
                                transfer or withdrawal are applied.
                            </p>
                            <p>
                                5.6. In case the Client terminates the Agreement and submits the request to close
                                Payment Account and delete his Account in the System or the Company as stated in this
                                Agreement terminates the provision of Services to the Client and deletes the Client’s
                                Account from the System, money held on the Payment Account is transferred to the
                                Client's or Client’s close relative’s account with another financial institution if such
                                actions do not contradict with applicable laws.
                            </p>
                            <p>
                                5.7. The Company is entitled to deduct from the repaid money the amounts belonging to
                                the Company, i.e. fees for services provided by the Company and expenses which have not
                                been paid by the Client, including but not limited to fines and damages incurred by the
                                Company due to a Client’s breach of the Agreement. In the event of a dispute between the
                                Client and the Company, the Company has the right to detain money under dispute until
                                the dispute is resolved.
                            </p>
                            <p>
                                5.8. In the case of the Company’s failure to repay the money to the Client due to
                                reasons beyond his control, the Client shall be notified thereof immediately. The Client
                                shall without undue delay indicate another account or provide additional information
                                necessary to repay the money (execute a payment).
                            </p>
                            <p>
                                5.9. Where the redemption of Electronic money is requested by the Client upon the
                                expiration date of this Agreement or after not more than one year after the mentioned
                                expiration date, the total monetary value of the Electronic money held by such Client
                                shall be redeemed.
                            </p>
                        </div>

                        <div className={s.subtitle}>
                            6. Usage of Account
                        </div>

                        <div className={s.text_block}>
                            <p>
                                6.1. The Client may manage the Payment Account by logging into his Account through the
                                Company’s website with login name, password and code received via SMS message.
                            </p>
                            <p>
                                6.2. The Client undertakes to take all reasonable measures to keep the username and
                                password of Payment Account safe at all time and never disclose it to third parties. In
                                case the Client receives a request to disclose any login information anywhere else than
                                on the Company’s website, the Client must not disclose the requested information and
                                must immediately without undue delay inform the Company. Such information notices shall
                                be submitted by email <a href="mailto:support@luxpay.lt">support@luxpay.lt</a> from an
                                email, which was indicated by the Client at the time of registration in the System.
                            </p>
                            <p>
                                6.3. It is recommended to change the password to Payment Account regularly to reduce the
                                risk of a security breach.
                            </p>
                            <p>
                                6.4. In case the Client has any suspicion that login details, password or other
                                Personalized Security Measures were stolen, misappropriated, used without authorization
                                or otherwise compromised or the Client lost such information, it is recommended for the
                                Client to change the password immediately and without undue delay inform the Company in
                                relation thereto. Any undue delay in notifying the Company may not only affect the
                                security of Payment Account but may result in the Client being liable for losses
                                incurred as a result. If the Client suspects that Payment Account has been accessed by a
                                third party, the Client should also contact responsible State authorities and report the
                                incident.
                            </p>
                            <p>
                                6.5. The Client is obliged to take all reasonable measures to warrant that his mobile
                                phone cannot be accessed by third parties, because codes to authorise Payments will be
                                received via SMS messages. The Client is also obliged to take all reasonable measures to
                                warrant that his email used in the registration process is secure and cannot be accessed
                                by third parties, because it may be used to reset passwords or to communicate with the
                                Client about the security of Payment Account. In case email address used in the
                                registration process is compromised during the business relationship between the Company
                                and the Client, the Client must inform the Company without undue delay after becoming
                                aware of such information and contact the provider of email services.
                            </p>
                        </div>

                        <div className={s.subtitle}>
                            7. Topping of Payment Account
                        </div>

                        <div className={s.text_block}>
                            <p>
                                7.1. The Client may at any time top up his Payment Account by indicating the amount of
                                the deposit and by selecting the available method for topping up on the Company’s
                                website.
                            </p>
                            <p>
                                7.2. The Company has a right at its sole discretion to change methods for topping up at
                                any time, if at least 1 (one) method is available at all times.
                            </p>
                            <p>
                                7.3. The Company shall not incur any liability regarding the Client’s funds used to top
                                up Payment Account until the Company receives such funds into its account. Electronic
                                money is issued only after the received amount is credited into Payment Account.
                            </p>
                            <p>
                                7.4. The Client is solely responsible for the completeness and accuracy of the payment
                                details entered to top up the Payment Account. The Company is not liable for any losses
                                suffered by the Client and/or any third party as a result of inaccuracy of the payment
                                details.
                            </p>
                            <p>
                                7.5. The Company is entitled to impose any additional authorization measures for topping
                                up the Payment Account.
                            </p>
                            <p>
                                7.6. The Client understands and accepts that the Client’s payment services provider may
                                apply additional fees for the execution of transfer to top up of Payment Account.
                            </p>
                        </div>

                        <div className={s.subtitle}>
                            8. Withdrawal from Payment Account
                        </div>

                        <div className={s.text_block}>
                            <p>
                                8.1. The Client may at any time withdraw part or all of the Electronic money held in
                                Payment Account by indicating the amount of the withdrawal and by selecting the
                                available method for withdrawal on the Company’s website.
                            </p>
                            <p>
                                8.2. The Company has a right at its sole discretion to change methods for withdrawal at
                                any time, if at least 1 (one) method is available at all times.
                            </p>
                            <p>
                                8.3. The Client shall count the withdrawn cash and make any claims regarding the amount
                                of cash or quality of banknotes immediately after the withdrawal.
                            </p>
                            <p>
                                8.4. The Client is solely responsible for the completeness and accuracy of the payment
                                details entered to withdraw from the Payment Account. The Company is not liable for
                                withdrawal redemption of Electronic money to the wrong bank account due to wrong payment
                                details provided by the Client. In such case, the Client may request the Company to
                                assist in reclaiming such funds, however, the Company gives no guarantee that the
                                reclaiming process will be successful, nor assume any liability thereof.
                            </p>
                            <p>
                                8.5. The Company bears no responsibility regarding the withdrawal payment once the
                                Client’s services provider receives the funds, as the Company is only the payer.
                            </p>
                        </div>

                        <div className={s.subtitle}>
                            9. Payment transfers
                        </div>

                        <div className={s.text_block}>
                            <p>9.1. The Client may execute the following Payment from the Payment Account:</p>
                            <ul>
                                <li>
                                    9.1.1. SEPA Payments;
                                </li>
                                <li>
                                    9.1.2. SWIFT Payments.
                                </li>
                            </ul>
                            <p>
                                9.2. The Client must follow the instruction in the Company’s System to perform any of
                                the above-mentioned Payments.
                            </p>
                            <p>
                                9.3. The Client and, in some cases, the linked users are entitled to set daily Payments
                                limits of Payment Account following the instruction provided by the Company.
                            </p>
                            <p>
                                9.4. The Client may select if the Payment transfer is standard or urgent. If the Client
                                does not select the Payment transfer manner, it is considered that the Client has
                                initiated a standard Payment transfer.
                            </p>
                        </div>

                        <div className={s.subtitle}>
                            10. Payment order
                        </div>

                        <div className={s.text_block}>
                            <p>
                                10.1. The Client is obliged to provide a Payment order for the execution of the Payment
                                in accordance with the instructions, which are valid at the moment of transfer,
                                indicated in the System.
                            </p>
                            <p>
                                10.2. By placing the Payment order, the Client must clearly express his will. Placed
                                Payment orders must be clear, unambiguous and comply with the requirement for submission
                                of such Payment order and/or content of the Payment order set by legal acts or the
                                Company. Submission of the Payment order is an agreement of the Client to execute the
                                Payment and cannot be cancelled, except the following limitations:
                            </p>
                            <ul>
                                <li>
                                    10.2.1. if the Payment is initiated by the Client, such Payment order can be
                                    cancelled till such Payment order is received;
                                </li>
                                <li>
                                    10.2.2. if the Payment is initiated by another other Company’s client (i.e. payment
                                    initiated by the payee), such Payment order can be cancelled till the Client
                                    authorized such Payment order.
                                </li>
                            </ul>
                            <p>
                                10.3. The Payment order is considered received:
                            </p>
                            <ul>
                                <li>
                                    10.3.1. at the day of its receipt, or, if the moment of receipt of the Payment order
                                    is not a Business day of the Company, the Payment order is considered received on
                                    the nearest Business day of the Company;
                                </li>
                            </ul>
                            <p>
                                10.4. The Client will be able to see the status of his Payment orders in his Payment
                                Account anytime by logging into Account.
                            </p>
                            <p>
                                10.5. The Company is entitled to record and store any Payment orders submitted by any of
                                the means, and to record and store information about all Payments performed by the
                                Client or according to Payment orders of the Client. Such Statements may be submitted by
                                the Company to the Client and/or third persons, who have the right to receive such data
                                under applicable laws, as evidence confirming the submission of Payment orders and (or)
                                executed Payment operations.
                            </p>
                            <p>
                                10.6. The Client is responsible for indicating correct Payer’s or Recipient’s Unique
                                Identifier and information that is required to execute the Client’s Payment order, if
                                any. If the Client has indicated the Unique Identifier and the respective Payment order
                                was executed against such Unique Identifier, the Company will be considered as to have
                                been duly performed its obligations and shall have no responsibility to the extent of
                                such Payment order. Fee for such Payment will be applied and will not be refunded to the
                                Client even if the Company retracts the Payment and the funds will be credited back to
                                the Payment Account.
                            </p>
                            <p>
                                10.7. If the Payment is initiated by the Recipient, such Payment shall only be executed
                                once the Client authorizes such Payment and the Recipient provided the Payer with the
                                respective information of such Payment in advance. If the Client authorized the Payment
                                initiated by the Recipient and upon the authorization of the Payment (a) the Client was
                                not aware of the amount of the Payment and (b) amount of the Payment exceeded the
                                Client’s reasonable expectations, the Client may request for a refund of such Payment.
                                The Client must submit the request for the refund within 8 (eight) weeks following the
                                day of Payment.
                            </p>
                            <p>
                                10.8. The Company shall not bear any responsibility for errors, repetitions,
                                inconsistencies and/or contradictions or other shortages in the Client’s Payment order
                                and depending on the nature of such shortcomings or errors, the Company may execute such
                                Payment order according to the data provided in the Payment order or reject execution of
                                such Payment order.
                            </p>
                            <p>
                                10.9. If the Client indicated wrong Payment order information and such Payment order has
                                not been executed yet, the Client may contact the Company by submitting the request
                                to &nbsp;
                                <a href="mailto:support@luxpay.lt">support@luxpay.lt</a> to amend information of such
                                Payment order, although the Company has
                                no obligation to fulfil the Client’s request or cancel such Payment order. If the
                                Payment order was executed according to the Payment order information indicated by the
                                Client, the Company has to be considered as to have been duly performed its obligations
                                and shall bear no responsibility to the extent of such Payment order. The Client may
                                request to correct the Payment order’s information (Unique Identifier) and the Company
                                may satisfy such request. In the latter case, a fee for the correction of the payment
                                order may be applied.
                            </p>
                            <p>
                                10.10 The Company may request the Client to enter additional data to proceed with the
                                Client’s Payment order if the Recipient’s payment services provider requires so. The
                                Client is responsible for entering the correct additional information.
                            </p>
                            <p>
                                10.11. The Company is entitled, however, not obliged, to verify if the Unique Identifier
                                required to execute the Payment order corresponds to name of the respective Payment
                                Account holder. If the Company establishes that name of Payment Account holder and the
                                Unique Identifier clearly mismatches, the Company is entitled to reject such Payment
                                order. Notwithstanding if the Company executes the Payment order against the provided
                                Unique Identifier, the Company shall be deemed to have been duly and in full fulfilled
                                its obligations in relation to such Payment order.
                            </p>
                            <p>
                                10.12. If the Client notices that money has been credited to his Payment Account by
                                mistake or in other ways that have no legal basis, the Client is obliged to notify the
                                Company about it and has no right to dispose of such money. In such cases, the Company
                                has the right and the Client gives an irrevocable consent to deduct the money from his
                                Payment Account without the Client's order. If the amount of money on the Payment
                                Account is insufficient to debit the money credited by mistake, the Client
                                unconditionally commits repaying to the Company the money credited by mistake within 5
                                (five) Business days from the receipt of such request from the Company.
                            </p>
                            <p>
                                10.13. Considering the provisions of Section 10.6, the Company will provide support to
                                the Client to retract the Payment, although there is no guarantee that the Company will
                                be able to retract the Payment. For the attempt to retract the Payment, irrespective if
                                such an attempt is successful or not, the fee may be applied. If it is unable for the
                                Company to retract the Payment and credit it back to the Payment Account, the Company
                                will furnish the Client with the information required for the Client to independently
                                purse return of the Payment.
                            </p>
                            <p>
                                10.14. The Company executes the Payment orders and credits the Payment to the
                                Recipient’s payment service provider’s account within the following terms:
                            </p>
                            <ul>
                                <li>
                                    10.14.1. Payment orders within the Company (to other Company’s clients) shall be
                                    executed instantly, not later than within a period of 1 (one) Business day after the
                                    time of receipt of a Payment order, except the cases when Payment is suspended due
                                    to cases indicated in the laws or this Agreement;
                                </li>
                                <li>
                                    10.14.2. Payment orders in EUR within the Republic of Lithuania and placed not later
                                    than noon (12 pm) shall be executed on the same Business Day if such Payment order
                                    is placed on the Business Day, except the cases when Payment is suspended due to
                                    cases indicated in the laws or this Agreement;
                                </li>
                                <li>
                                    10.14.3. SEPA Payment orders shall be executed instantly, not later than on the next
                                    Business day after the time of receipt of a Payment order, except the cases when
                                    Payment is suspended due to cases indicated in the laws or this Agreement;
                                </li>
                                <li>
                                    10.14.4. SWIFT Payment orders shall be executed not later than within a period of 3
                                    (three) Business days after the time of receipt of a Payment order, except the cases
                                    when Payment is suspended due to cases indicated in the laws or this Agreement.
                                </li>
                            </ul>
                            <p>
                                10.15. The Company is liable for the proper and due execution of the Payment order in
                                accordance with applicable laws and this Agreement, unless the Company knows that the
                                Payment has been received by the Recipient’s payment services provider and can prove
                                that. In case the Payment order was not executed or was executed not in timely and due
                                manner and in accordance with this Agreement, the Company is obliged:
                            </p>
                            <ul>
                                <li>
                                    10.15.1. to refund the Payment Account with the amount of such Payment order; and
                                </li>
                                <li>
                                    10.15.2. to restore balance in the Client’s Payment Account to such extent as if the
                                    Payment has not been executed; and
                                </li>
                                <li>
                                    10.15.3. to ensure that the Client shall suffer no damages in relation to failure
                                    pay or receive (whatever may be the case) the interest on the certain term.
                                </li>
                            </ul>
                            <p>
                                10.16. In the above event, the Company will use its best efforts to track such Payment
                                and no fee will be applied for such retraction.
                            </p>
                            <p>
                                10.17. If the Payment is initiated by the Recipient, the Company ensures the transfer of
                                the Payment request to the Payer in timely and due manner. The Company shall be
                                responsible to secure that the Recipient suffers no damages in relation to the late
                                transfer of Payment request.
                            </p>
                            <p>
                                10.18. The Client’s Payment order may not be executed or the execution of it may be
                                delayed if the Company:
                            </p>
                            <ul>
                                <li>
                                    10.18.1. has reasonable doubts regarding the content of the Payment order;
                                </li>
                                <li>
                                    10.18.2. has reasonable doubts if the Payment order was submitted by the Client or
                                    his authorized representative;
                                </li>
                                <li>
                                    10.18.3. has reasonable doubts regarding the legality of the Payment order;
                                </li>
                                <li>
                                    10.18.4. has doubts if the data/documents/information provided in relation to the
                                    Payment order are authentic and true;
                                </li>
                                <li>
                                    10.18.5. other doubts that cannot be reasonably justified by the Company.
                                </li>
                            </ul>
                            <p>
                                10.19. In the event indicated in Section 10.18 of this Agreement, the Company is
                                entitled to request from the Client to additionally confirm the submitted Payment order
                                and/or submit documents confirming the rights of persons to manage the funds held on the
                                Payment Account or other additional information, documents or data to clear doubts in
                                relation to the Payment order. By executing this Agreement, the Client warrants that in
                                such event, the Company does not undertake the responsibility if the Client suffers any
                                damages in relation to the delayed Payment order.
                            </p>
                            <p>
                                10.20. In case the Company’s refusal to execute the Payment order submitted by the
                                Client, the Company shall immediately without undue delay inform the Client or create
                                necessary conditions for the Client to get acquainted with such notification, except the
                                cases when such notification is impossible technically or forbidden by the applicable
                                laws.
                            </p>
                            <p>
                                10.21. If the money transferred by the Payment order is returned due to reasons beyond
                                the control of the Company, e.g. the account of the Recipient is closed, the returned
                                amount is credited to the Payment Account of the Client. Fees paid by the Payer for the
                                Payment order execution are not returned, and other fees related to the returning of
                                money and applied to the Company can be deducted from the Payment Account of the Client.
                            </p>
                            <p>
                                10.22. If the Company is liable for non-execution or inappropriate execution of the
                                Payment order, the Company will not apply any fees applicable to such Payments.
                            </p>
                            <p>
                                10.23. The Client is obliged to regularly monitor the Payment Account and review any and
                                all transactions on the Payment Account, including, but not limited to the Client’s
                                Payment orders and Payments and inform the Company of any discrepancies on Payment
                                Account no later than within 13 (thirteen) months following the day of the execution of
                                the Payment by sending an email to <a
                                href="mailto:support@luxpay.lt">support@luxpay.lt</a> from an email, which was indicated
                                by the Client at the time of registration in the System.
                            </p>
                        </div>

                        <div className={s.subtitle}>
                            11. Authorization of the Payment order
                        </div>

                        <div className={s.text_block}>
                            <p>
                                11.1. The Client must authorize the Payments before their execution by authenticating
                                the Payment order following the instructions provided by the Company.
                            </p>
                            <p>
                                11.2. The Client uses his Personalized Security Measures to authorize the Payment. As
                                regards the Personalized Security Measures, the Client must:
                            </p>
                            <ul>
                                <li>
                                    11.2.1. to use Personalized Security Measures following the instructions provided by
                                    the Company. In case of failure to do so, the Client shall be responsible for any
                                    and all damages in relation thereto;
                                </li>
                                <li>
                                    11.2.2. to keep Personalized Security Measures inaccessible to third parties at all
                                    times;
                                </li>
                                <li>
                                    11.2.3. immediately inform the Company about unauthorised use, theft, loss or other
                                    misappropriation of Personalized Security Measures.
                                </li>
                            </ul>
                            <p>
                                11.3. The Company retains the right to block the Payment Account and/or Personalized
                                Security Measures temporarily or permanently due to reasons of security. The Client will
                                be informed on such blocking via email, except cases when the Company is obliged not to
                                do so. As soon as the ground for blocking expires, the blocking is removed.
                            </p>
                            <p>
                                11.4. The Company retains the right to demand the Client to perform additional actions
                                to authenticate the Client’s Payment order. Any and all authorizations performed on
                                Payment Account shall be deemed to be made by the Client and have the same legal effect
                                as the ordinary signature fixed on the hard copy of the document. Such authorization
                                shall be considered as the appropriate means of proof evidencing that the Payment has
                                been authorized and in such case, the Client shall not be entitled to contest such
                                Payment.
                            </p>
                            <p>
                                11.5. In case the funds were written off Payment Account and the Client did not
                                authorize such Payment, The Company is obliged as soon as practically possible, however,
                                not later than until the end of the Business day following the day when the Company
                                became aware of such an unauthorised Payment to have been executed:
                            </p>
                            <ul>
                                <li>
                                    11.5.1. to refund the Client with the amount of such unauthorised Payment;
                                </li>
                                <li>
                                    11.5.2. to restore balance in Payment Account to such extent as if the Payment has
                                    not been executed; and
                                </li>
                                <li>
                                    11.5.3. to ensure that the Client shall suffer no damages in relation to failure pay
                                    or receive the interest on the certain term.
                                </li>
                            </ul>
                            <p>
                                The above does not apply if the Company has objective reasons to suspect fraud. In the
                                latter case, the Company shall notify the Bank of Lithuania in writing on the reasons,
                                which give ground for the Company to reasonably suspect Fraud.
                            </p>
                            <p>
                                11.6. The Client must notify the Company on all and any unauthorized Payments on the
                                Client’s Payment Account in compliance with Section 10.23.
                            </p>
                            <p>
                                11.7. The Client shall not be entitled to contest the Payment transaction executed by
                                the Company if the Payment order has been duly authorized in the manner set out in this
                                Section.
                            </p>
                        </div>

                        <div className={s.subtitle}>
                            12. Client’s Obligations
                        </div>

                        <div className={s.text_block}>
                            <p>
                                12.1. The Client is obliged:
                            </p>
                            <ul>
                                <li>
                                    12.1.1. To act fairly and to comply with the provision of this Agreement, its
                                    annexes and supplements as well as legislation and other applicable legal acts;
                                </li>
                                <li>
                                    12.1.2. not to violate the rights of the Company and third parties to trademarks,
                                    copyrights, commercial secrets and other intellectual property rights;
                                </li>
                                <li>
                                    12.1.3. not to have more than 1 (one) Account; registering an Account by fictitious
                                    or someone else's name without having the power of attorney;
                                </li>
                                <li>
                                    12.1.4. not to open more than 1 (one) Payment Account unless the Company has
                                    authorised the Client otherwise;
                                </li>
                                <li>
                                    12.1.5. not to use Payment Account for illegal, unlawful and fraudulent activities;
                                </li>
                                <li>
                                    12.1.6. not to execute or receive transfers of illegally acquired funds, if the
                                    Client is aware of or should be aware of it;
                                </li>
                                <li>
                                    12.1.7. not to provide false, misleading or incorrect information, data or documents
                                    to the Company;
                                </li>
                                <li>
                                    12.1.8. to provide information or undertake other actions that are reasonably
                                    requested by the Company;
                                </li>
                                <li>
                                    12.1.9. not to access Payment Account from anonymous IP addresses;
                                </li>
                                <li>
                                    12.1.10. not to provide false, misleading or incorrect information about the Company
                                    to third parties;
                                </li>
                                <li>
                                    12.1.11. not to use Payment Account for activities that may harm the Company’s or
                                    third parties’ brand and image;
                                </li>
                                <li>
                                    12.1.12. not to use the Company’s Services from countries that are not acceptable to
                                    the Company. The list is provided on the Company’s website;
                                </li>
                                <li>
                                    12.1.13. not to use Payment Account for morally or ethically dubious purposes, or
                                    purposes which in any way violate applicable laws, rules and regulations;
                                </li>
                                <li>
                                    12.1.14. not to use Payment Account for the benefit of the third party;
                                </li>
                                <li>
                                    12.1.15. not to spread computer viruses and undertake other actions that could cause
                                    System malfunctions, information damage or destruction and other damage to the
                                    System, equipment or information of the Company;
                                </li>
                                <li>
                                    12.1.16. not hack, improperly access or interfere with the Company and other
                                    software and/or applications required to provide Services to the Client;
                                </li>
                                <li>
                                    12.1.17. to submit any and all information, data and/or documents requested by the
                                    Company for the purpose of provision of Services under this Agreement;
                                </li>
                                <li>
                                    12.1.18. not to disclose to any third party the Personalized Security Measures
                                    issued for the purpose of use of Payment Account. The Client is obliged to
                                    familiarize each linked user of Payment Account of such requirement. If the Client
                                    fails to comply with this provision or the Client could, however, the Client did not
                                    prevent transfer of such Personalized Security Measures or committed such transfer
                                    deliberately or due to gross negligence, the Client shall assume full liability for
                                    the losses incurred and the Client must indemnify the losses of other persons, if
                                    any, if they have suffered damages due to the Client’s or the Client’s
                                    representative omission;
                                </li>
                                <li>
                                    12.1.19. not to accept payments in unregulated and (or) unsupervised virtual
                                    currency, not to buy, convert or manage it in any other ways (the prohibition
                                    includes execution or receipt of transfers from virtual currency exchangers).
                                </li>
                            </ul>
                            <p>
                                12.2. The Client is obliged to reimburse all direct damages, fines and other monetary
                                sanctions applied to the Company due to non-observance or violation of this Agreement,
                                including but not limited to, Section 12.1 of this Agreement due to the fault of the
                                Client.
                            </p>
                            <p>
                                12.3. The Client is responsible and undertakes to reimburse any losses incurred by the
                                Company, other Company’s clients and third parties due to the Client’s use of Company’s
                                Services and violation of this Agreement, its annexes and supplements.
                            </p>
                        </div>

                        <div className={s.subtitle}>
                            13. Notifications, Communication and Consultation
                        </div>

                        <div className={s.text_block}>
                            <p>
                                13.1. The Client agrees that Company’s notifications will be provided to the Client in
                                Lithuanian, Russian and English languages (except if otherwise agreed by the Parties) by
                                placing them on the website of the Company and by sending an email, which was indicated
                                by the Client at the time of registration in the System. In some cases, when the
                                applicable law requires, information may be provided by sending it to the address,
                                indicated by the Client at the time of registration in the System, or by sending an SMS
                                message. The Client may communicate and consult with the Company regarding the
                                provisions of this Agreement, its annexes and supplements at any time by sending an
                                email to <a href="mailto:support@luxpay.lt">support@luxpay.lt</a> from an email,
                                which was indicated by the Client at the time
                                of registration in the System. All communications and consultations will be conducted in
                                Lithuanian, Russian or English.
                            </p>
                            <p>
                                13.2. It is considered that the Client received the notification within 24 (twenty four)
                                hours from the moment it was posted on the website of the Company or sent to the Client
                                by e-mail or SMS message. If the notification is sent by post, it shall be deemed that
                                the Client received it within 14 (fourteen) Business days after it was sent.
                            </p>
                            <p>
                                13.3. The Client undertakes to regularly check his email, which was indicated by the
                                Client at the time of registration in the System, box and Company’s website to get
                                acquainted with Company’s notifications about amendments in a timely manner.
                            </p>
                            <p>
                                13.4. If a Party of this Agreement consists of a plurality of persons, the Company has
                                the right to address notifications to any of the persons involved and the person who has
                                received the information has an obligation transmit the information to other persons
                                indicated in this Agreement.
                            </p>
                            <p>
                                13.5. The Client undertakes to provide his contact information, and in case of
                                amendments, immediately update the contact data, which the Company could use to urgently
                                contact the Client or representatives of the Client. In case the Client does not update
                                the contact data on his Account, all consequences due to the failure of the Company to
                                submit notifications to the Client shall fall on the Client.
                            </p>
                            <p>
                                13.6. The Client undertakes to inform the Company in writing about loss or theft of his
                                personal identity document immediately, in order to protect funds of the Client from
                                possible illegal actions of third persons.
                            </p>
                            <p>
                                13.7. In case of suspected or executed Fraud, the Company will inform the Client via
                                email, which was indicated by the Client at the time of registration in the System. For
                                the purpose of proper Client’s identification, the Client may be asked to provide
                                Company with the data known by the Company. The Company shall be entitled to suspend the
                                use of Payment Account and execution of the Client’s Payment orders or Payments until
                                the Company identifies that no Fraud was executed.
                            </p>
                            <p>
                                13.8. In case the Company’s notification relates to essential unilaterally amendments to
                                the provisions of this Agreement, the Client shall be informed 60 (sixty) days in
                                advance by written notice, unless stated otherwise in this Agreement. It shall be deemed
                                that the Client has received the notification and the amendments to this Agreement come
                                into force within 60 (sixty) days after the notification has been published on the
                                website of the Company, sent to the Client by email or via any other mean that had been
                                indicated by the Client during registration (post or SMS message with a link to a
                                respective webpage).
                            </p>
                            <p>
                                13.9. The notification period indicated in Section 13.7 of this Agreement does not apply
                                and notifications may be provided in accordance with the procedure indicated in Section
                                13.1
                            </p>
                            <ul>
                                <li>
                                    13.9.1. a new service or a part of service appears, which may be used or not used by
                                    the Client at his own choice;
                                </li>
                                <li>
                                    13.9.2. the provisions of this Agreement are changed due to changes in mandatory
                                    requirements of the legislation;
                                </li>
                                <li>
                                    13.9.3. the prime cost of provided services increases which leads to increase in
                                    prices of the Company’s services;
                                </li>
                                <li>
                                    13.9.4. the prices of services are reduced.
                                </li>
                            </ul>
                            <p>
                                13.10. Non-essential amendments of the Agreement which are style and grammar
                                corrections, paraphrasing and moving a sentence, a clause or a section of this Agreement
                                for the sake of better understanding, provision of examples for articles and other
                                changes which do not reduce or limit rights of the Client and do not increase liability
                                of the Client or aggravate his situation are not required to be approved by the Client.
                            </p>
                            <p>
                                13.11. In case the Client does not agree to amendments or supplements to the Agreement,
                                the Client has the right to refuse Company’s services and terminate the Agreement,
                                notifying the Company thereof 30 (thirty) days in advance by issuing an email to &nbsp;
                                <a href="mailto:support@luxpay.lt">support@luxpay.lt</a>. In case the Client informs the
                                Company in writing that the Client
                                does not wish to be subject of new provisions of the Agreement, the Agreement is
                                considered to be terminated on the date on which the new edition of the Agreement
                                entered into force. If the Client fails to notify the Company about his disagreement
                                with the amendments by the suggested day of their entry into force, it is considered
                                that the Client accepts the amendments to the Agreement, and the amendments shall enter
                                into force on the specified day of their entry into force.
                            </p>
                            <p>
                                13.12. The Parties shall immediately inform each other about any circumstances
                                significant for the execution of the Agreement. The Client shall submit documents
                                confirming such circumstances.
                            </p>
                            <p>
                                13.13. The Client shall have no right to amend this Agreement unilaterally.
                            </p>
                        </div>

                        <div className={s.subtitle}>
                            14. Suspension of Service provision and Termination of the Agreement
                        </div>

                        <div className={s.text_block}>
                            <p>
                                14.1. The Company at its sole discretion, taking into account the specifics of the
                                situation and giving preference to the interest of the Clients and execution of
                                applicable laws, has the right to suspend Payment Account, included but not limited to,
                                the following cases:
                            </p>
                            <ul>
                                <li>
                                    14.1.1. the Client fails to complete necessary identification procedures or fails to
                                    submit to the Company the requested information, data and/or documents within
                                    indicated time;
                                </li>
                                <li>
                                    14.1.2. the Company is obliged to do so in accordance with the applicable laws;
                                </li>
                                <li>
                                    14.1.3. the Client has a negative balance on Payment Account;
                                </li>
                                <li>
                                    14.1.4. the Client fails to settle with the Company in a timely and due manner in
                                    accordance with this Agreement;
                                </li>
                                <li>
                                    14.1.5. the Company has reasonable doubts regarding Payment order, i.e. its purpose,
                                    authorisation, legality, reasonability, etc;
                                </li>
                                <li>
                                    14.1.6. the Company has reasonable doubts regarding the origin of the funds on
                                    Payment Account;
                                </li>
                                <li>
                                    14.1.7. the Client’s use of Payment Account breaches the provisions of this
                                    Agreement and/or requirements of the applicable laws;
                                </li>
                                <li>
                                    14.1.8. the Company becomes aware of any other circumstances which may prevent the
                                    Client from proper fulfilment of the Client’s obligations under this Agreement;
                                </li>
                                <li>
                                    14.1.9. the Company has reasonable ground to believe that Payment Account is being
                                    used by other persons rather than the Client;
                                </li>
                                <li>
                                    14.1.10. the Company has reasonable grounds to suspect that money laundering or
                                    terrorist financing activities has been or is intended to be carried out via Payment
                                    Account;
                                </li>
                                <li>
                                    14.1.11. the Client uses Payment Account in such a way that requires prior approval
                                    of such way of use by the Company;
                                </li>
                                <li>
                                    14.1.12. the Company becomes aware of the theft, loft or misappropriate of the
                                    Client’s Personalized Security Measures granting access to the Payment Account;
                                </li>
                                <li>
                                    14.1.13. the Company has reasonable suspicion that Account or Payment Account of the
                                    Client has been hacked;
                                </li>
                                <li>
                                    14.1.14. if the Company see it necessary to protect the interest of the Client
                                    and/or the Company and/or clients of the Company.
                                </li>
                            </ul>
                            <p>
                                14.2. In any case, the Company has the right to suspend Payment Account for a period of
                                60 (sixty) days with the right to extend it for the same period the unlimited number of
                                times.
                            </p>
                            <p>
                                14.3. Once the grounds of such limitations extinct, the suspension of the use of
                                Services shall be removed.
                            </p>
                            <p>
                                14.4. In some cases, the above circumstances may result in unilateral termination of the
                                Agreement by the Company with an immediate effect.
                            </p>
                            <p>
                                14.5. The Client has a right to terminate this Agreement at any time by notifying the
                                Company 30 (thirty) calendar days in advance via email. At least the following
                                information must be indicated in the notification: the Client’s name, last name,
                                personal code (if assigned), date of birth, citizenship, residence address as well as
                                date of the Agreement. Failure to comply with the requirements for the notification of
                                termination of this Agreement shall result in rejection of such notification. The Client
                                is solely responsible for indicating the required information correctly and accurately.
                                By executing the Agreement, the Client warrants that the Client understands that in the
                                event of termination of this Agreement, the Client will not be refunded with any fees
                                that the Client has paid to the Company under this Agreement for the Services that have
                                been provided for the Client before the receipt of the notification.
                            </p>
                            <p>
                                14.6. The Company has a right to terminate this Agreement by providing a written
                                termination notice via email at least 30 (thirty) calendar days in advance.
                            </p>
                            <p>
                                14.7. The Company is entitled to terminate the Agreement, with an immediate effect, if:
                            </p>
                            <ul>
                                <li>
                                    14.7.1. the Client essentially violates this Agreement or its annexes, supplements,
                                    and/or other parts thereof;
                                </li>
                                <li>
                                    14.7.2. the Client’s breach of the Agreement continues, and such breach is not cured
                                    by the Client within the time limit specified in writing by the Company;
                                </li>
                                <li>
                                    14.7.3. the Client poses unacceptable risk of money laundering and/or terrorist
                                    financing;
                                </li>
                                <li>
                                    14.7.4. in the Company’s opinion, the Client’s activities or actions shall be
                                    damaging or may damage the business image/reputation of the Company;
                                </li>
                                <li>
                                    14.7.5. the Client fails to complete necessary identification procedures, or submit
                                    the information required by the Company;
                                </li>
                                <li>
                                    14.7.6. the Client failed to provide information regarding changes after the
                                    execution of this Agreement;
                                </li>
                                <li>
                                    14.7.7. there is a major change in the Client’s circumstances, to the extent that if
                                    such circumstances existed before the or on the day of this Agreement, the Agreement
                                    would have not been executed;
                                </li>
                                <li>
                                    14.7.8. the Client is included or becomes included during the term of this Agreement
                                    in the sanctions list;
                                </li>
                                <li>
                                    14.7.9. at the day of this Agreement, the Client provided inaccurate or incomplete
                                    information about the Client of which the Company was not and was not able to be
                                    aware of and if the Company was aware of such circumstances to exist before the or
                                    on the day of this Agreement, the Company would have not executed this Agreement;
                                </li>
                                <li>
                                    14.7.10. the Client’s activities include illegal or unlawful activities;
                                </li>
                                <li>
                                    14.7.11. due to further provision of Services and Client’s activities, justified
                                    interests of third parties may be harmed;
                                </li>
                                <li>
                                    14.7.12. the Company becomes aware of the Client’s bankruptcy or death;
                                </li>
                                <li>
                                    14.7.13. the Client becomes subject to debt collection action or shall be entered in
                                    debtors’ register;
                                </li>
                                <li>
                                    14.7.14. the Company is required to do so in accordance with the applicable law;
                                </li>
                                <li>
                                    14.7.15. the Company see it necessary to protect the interest of the Client and/or
                                    the Company and/or clients of the Company.
                                </li>
                            </ul>
                            <p>
                                14.8. The Client shall be liable to reimburse any and all Company’ direct and indirect
                                damages and losses that were suffered regarding the termination of the Agreement on any
                                of the grounds indicated in the Section 14.7 of this Agreement.
                            </p>
                            <p>
                                14.9. In case the Agreement is terminated, money held on the Payment Account shall be
                                transferred to the Client's or Client’s Close relative’s account with another financial
                                institution if such actions do not contradict with applicable laws.
                            </p>
                            <p>
                                14.10. The Company is entitled to deduct from the Payment Account the amounts belonging
                                to the Company, i.e. fees for Services provided by the Company and expenses which have
                                not been paid by the Client, including but not limited to fines and damages incurred by
                                the Company due to a Client’s breach of the Agreement. Should there were not sufficient
                                funds on Payment Account, the Client undertakes to credit the Payment Account with the
                                respective amount of funds without undue delay, however, not later than within 1 (one)
                                Business day following the Company’s request in writing.
                            </p>
                            <p>
                                14.11. In the case of the Company’s failure to repay the money to the Client due to
                                reasons beyond his control, the Client shall be notified thereof immediately. The Client
                                shall without undue delay indicate another account or provide additional information
                                necessary to repay the money (execute a payment).
                            </p>
                            <p>
                                14.12. Notwithstanding the above, the Client may use Payment Account transfer services
                                to another payment services provider by submitting a written request to the Company via
                                email <a href="mailto:support@luxpay.lt">support@luxpay.lt</a> from an email, which was
                                indicated by the Client at the time of
                                registration in the System, in accordance with Art. 67 of Law on Payments of the
                                Republic of Lithuania. The prices for such services, applied Commission fees and more
                                detailed information are provided on the Company’s website.
                            </p>
                            <p>
                                14.13. Termination of this Agreement shall not release the Client from the proper
                                fulfilment of all liabilities that arose before the termination of this Agreement.
                            </p>
                            <p>
                                14.14. The Agreement remains valid in respect of outstanding claims at the time of the
                                Agreement’s expiry, even if the Agreement is terminated.
                            </p>
                        </div>

                        <div className={s.subtitle}>
                            15. Confidentiality and Data Protection
                        </div>

                        <div className={s.text_block}>
                            <p>
                                15.1. The Company and the Client are obliged to treat all information relating to the
                                business relationship between the Company and the Client as confidential, irrespective
                                of the form such information is obtained, except the information in question is already
                                publicly available and this fact cannot be attributed to other Party’s breach of
                                contract.
                            </p>
                            <p>
                                15.2. The duty of confidentiality applies unless otherwise agreed by the Parties in
                                writing or where the Party is required to disclose such information by law, regulation
                                or a decision taken by the public authority.
                            </p>
                            <p>
                                15.3. The Company is entitled to disclose information about the Client to third parties,
                                to the subcontractors and other companies, in case such disclosure is required in order
                                for the Company to fulfil its obligations under this Agreement or applicable laws.
                            </p>
                            <p>
                                15.4. The duty of confidentiality applies during the term of this Agreement and continue
                                to apply once the Agreement is terminated.
                            </p>
                            <p>
                                15.5. Any and all personal data in relation to the performance of this Agreement shall
                                be processed in accordance with the Company’s Privacy Policy.
                            </p>
                        </div>

                        <div className={s.subtitle}>
                            16. Liability
                        </div>

                        <div className={s.text_block}>
                            <p>
                                16.1. The Company under no circumstances shall be liable for any specific, operating
                                losses, incidental or indirect loss, consequential damages, claims by third parties
                                and/or lost data, revenue, customers, profits, goodwill or interest in any other
                                circumstance.
                            </p>
                            <p>
                                16.2. The Client shall indemnify the Company for any losses or claims, including claims
                                for damages, and for any complaints, legal proceedings or expenses, including but not
                                limited to any fine or fee imposed on the Company as a result of the Client’s breach of
                                and/or failure to comply with this Agreement and/or all relevant regulations, rules and
                                legislation applicable to the Client. The foregoing shall apply irrespective of the
                                Agreement being terminated.
                            </p>
                            <p>
                                16.3. No limitation of liability is applied if it is not allowed under the applicable
                                law.
                            </p>
                            <p>
                                16.4. The Company shall not be held liable for the following:
                            </p>
                            <ul>
                                <li>
                                    16.4.1. for any Payment and loss in relation to such Payment if the Client had not
                                    protected Client’s log in to Payment Account information and means required to
                                    authorize execution of the Payment, irrespective of the reason, or from loss, theft
                                    or misappropriate of Payment Account or Personalized Security Features required to
                                    log in into Payment Account or to authorize execution of the Payment or breach into
                                    Payment Account when the Client did not immediately inform the Company of loss,
                                    theft or misappropriate of Payment Account or Personalized Security Measures;
                                </li>
                                <li>
                                    16.4.2. breach of any Company’s obligations caused by third parties beyond the
                                    Company’s control;
                                </li>
                                <li>
                                    16.4.3. consequences resulting due to disturbances of the fulfilment of any
                                    Company’s obligations caused by a third party which is beyond the control of the
                                    Company;
                                </li>
                                <li>
                                    16.4.4. errors, late, missed transaction, suspension of funds and discrepancies that
                                    occurred by third parties beyond the Company’s control;
                                </li>
                                <li>
                                    16.4.5. for any consequences arising due to the termination of the Agreement,
                                    suspension of Services or delay of Payment by the Company if the Company acted in
                                    accordance with this Agreement and applicable law;
                                </li>
                                <li>
                                    16.4.6. goods and services purchased using Payment Account, and also for another
                                    party, which receives payments from the Payment Account, not complying with the
                                    provision of this Agreement;
                                </li>
                                <li>
                                    16.4.7. for breach of the Agreement and/or any and all damages that occurred because
                                    of the Company’s compliance with the applicable law and/or court order and/or order
                                    by other State authorities;
                                </li>
                                <li>
                                    16.4.8. for losses incurred by the Client due to suspension of Service provision,
                                    blockage of the Account and (or) Payment instrument or other actions if those
                                    actions have been performed in accordance with the procedures stated in the
                                    Agreement and under circumstances and on the basis specified in this Agreement and
                                    applicable laws.
                                </li>
                            </ul>
                            <p>
                                16.5. The Client may suffer up to EUR 50 (fifty euro) damages for an unauthorized
                                Payment in the case such damages are suffered in relation to loss, theft or
                                misappropriate of the Personalized Security Measures.
                            </p>
                            <p>
                                16.6. The Client shall suffer no damages for an unauthorized Payment in the following
                                cases:
                            </p>
                            <ul>
                                <li>
                                    16.6.1. the Client could have not noticed loss, theft or misappropriate of the
                                    Personalized Security Measures until execution of an unauthorized Payment, unless
                                    the Client acted unfairly;
                                </li>
                                <li>
                                    16.6.2. the Company shall be liable for an unauthorized Payment.
                                </li>
                            </ul>
                            <p>
                                16.7. Any and all damages shall be suffered by the Client in relation to unauthorized
                                Payments arising because of failure to:
                            </p>
                            <ul>
                                <li>
                                    16.7.1. comply with the rules of use of the Client’s Personalized Security Measures
                                    resulting from the Client’s dishonesty, wilful misconduct or gross negligence or the
                                    Client acting unfairly;
                                </li>
                                <li>
                                    16.7.2. inform the Company immediately on loss, theft or illegal misappropriate and
                                    unauthorised usage of the Personalized Security Measures as soon as practically
                                    possible;
                                </li>
                                <li>
                                    16.7.3. follow the Company’s instructions regarding the protection of the Client’s
                                    Personalized Security Measures.
                                </li>
                            </ul>
                            <p>
                                16.8. After the Client submits a notification with a request to block the Personalized
                                Security Measures, the Company shall bear the losses of the Client incurred due to the
                                lost, stolen or illegally acquired Personalized Security Measures, except for cases of
                                the dishonesty of the Client.
                            </p>
                            <p>
                                16.9. In case the Company does not ensure the possibility to immediately notify about a
                                lost, stolen or illegally misappropriated Personalized Security Measures, also in the
                                cases, where the Company does not require safer authentication, the Company shall bear
                                the losses incurred due to unauthorised use of the Personalized Security Measures,
                                except for cases of dishonesty of the Client.
                            </p>
                            <p>
                                16.10. Notwithstanding the foregoing, the Company is not liable for losses incurred as a
                                result of failure to comply with its obligations in connection with circumstances of
                                force majeure which are proven in accordance with the procedure established by
                                applicable laws, except:
                            </p>
                            <ul>
                                <li>
                                    16.10.1. at the date of this Agreement the Company should have foreseen the
                                    circumstances giving rise to the loss, or if the Company should have been able to
                                    avoid or overcome the cause of the loss or consequences thereof by taking
                                    appropriate commercial precautions;
                                </li>
                                <li>
                                    16.10.2. the legislation under all circumstances makes the Company liable for the
                                    circumstances giving rise to the loss.
                                </li>
                            </ul>
                            <p>
                                16.11. The Client shall notify the Company about the force majeure in writing within 2
                                (two) Business days after the day of occurrence of such circumstances. The Company may
                                notify the Client about force majeure circumstances via email or websites of the System.
                            </p>
                        </div>

                        <div className={s.subtitle}>
                            17. Settlement of Disputes
                        </div>

                        <div className={s.text_block}>
                            <p>
                                17.1. If the Client believes that the Client’s right and/or interest related to this
                                Agreement and/or Company’s Services have been violated, the Client can submit the
                                complaint together with relevant documentation from an email, which was indicated by the
                                Client at the time of registration in the System to the Company via email &nbsp;
                                <a href="mailto:support@luxpay.lt">support@luxpay.lt</a>, or sending notification via
                                the Account. Such complain shall be
                                provided in Lithuanian, Russian or English languages.
                            </p>
                            <p>
                                17.2. The Company shall examine a written complaint of the Client not later than within
                                15 (fifteen) Business days from the day the complaint was received and provide the
                                Client with a detailed, motivated response, grounded by documents. In exceptional cases,
                                when due to reasons beyond the Company’s control Company is unable to provide a response
                                within the mentioned 15 (fifteen) business days, the Company undertakes to send a
                                holding reply, clearly indicating the reasons for a delay in answering to the complaint
                                and specifying the deadline by which the Client will receive the final reply. In any
                                event, the deadline for receiving the final reply shall not exceed 35 (thirty-five)
                                business days. Company shall examine the Client’s complaints free of charge.
                            </p>
                            <p>
                                17.3. Agreement is governed by Lithuanian laws.
                            </p>
                            <p>
                                17.4. In case of failure to settle a dispute amicably or in other extrajudicial methods
                                of dispute resolution, the dispute shall be settled by the courts of the Republic of
                                Lithuania in accordance with the procedure established by applicable laws.
                            </p>
                            <p>
                                17.5. If you are a consumer and you are unsatisfied with our response, you may apply to
                                the Bank of Lithuania regarding the settlement of a dispute via the following means 1)
                                through the online dispute resolution tool „E-Government Gateway“; 2) by filling in the
                                Consumer's application form and sending it to the Supervision Service of the Bank of
                                Lithuania, Žalgirio str. 90, LT-09303 Vilnius, Lithuania or via email <a
                                href="mailto:pt@lb.lt">pt@lb.lt</a>; 3) by
                                submitting a free-form application and sending it to the Supervision Service of the Bank
                                of Lithuania, Žalgirio str. 90, LT-09303 Vilnius, Lithuania or via email <a
                                href="mailto:pt@lb.lt">pt@lb.lt</a> (More information: <a href="https://www.lb.lt//lt/daugiau-apie-gincius-su-
                                finansiniu-paslaugu-teikeju">https://www.lb.lt//lt/daugiau-apie-gincius-su-
                                finansiniu-paslaugu-teikeju</a>).
                            </p>
                            <p>
                                17.6. If you are a consumer and you of the opinion that your or another person’s rights
                                and/or legitimate interests related to the Agreement and/or Services have been violated
                                by the Company, you may apply to the Bank of Lithuania with a complaint via electronic
                                means indicated in the website of the Bank of Lithuania
                                (<a href="https://www.lb.lt/lt/kontaktai#group-464">https://www.lb.lt/lt/kontaktai#group-464</a>)
                                or by sending the complaint to the Bank of
                                Lithuania, Totorių str. 4, LT-01121 Vilnius, Lithuania or Žalgirio str. 90, LT-09303
                                Vilnius, Lithuania or via email <a href="mailto:info@lb.lt">info@lb.lt</a> or <a
                                href="mailto:pt@lb.lt">pt@lb.lt</a>.
                            </p>
                        </div>

                        <div className={s.subtitle}>
                            18. Final Provisions
                        </div>

                        <div className={s.text_block}>
                            <p>
                                18.1. Each Party warrants that possesses all licences and permissions required under the
                                applicable laws that are necessary to execute this Agreement.
                            </p>
                            <p>
                                18.2. The Client does not have the right to assign his rights and obligations arising
                                out of this Agreement to third parties without prior written consent from the Company.
                                The Company reserves the right to assign its rights and obligations arising out of this
                                Agreement to third parties at any time without consent from the Client if such transfer
                                of rights and obligations does not contradict with the legislation.
                            </p>
                            <p>
                                18.3. If any provision of this Agreement shall be found by any court or legal authority
                                to be invalid, unenforceable or illegal, the other provisions shall remain valid and in
                                force and, to the extent possible, the provision shall be modified to ensure it is
                                valid, enforceable and legal whilst maintaining or giving effect to its commercial
                                intention.
                            </p>
                            <p>
                                18.4. Termination of this Agreement or the Services shall not affect accrued rights and
                                obligations of the Company and the Client except unless such rights were accrued
                                unlawfully or in breach of this Agreement.
                            </p>
                            <p>
                                18.5. This Agreement is valid for an indefinite period of time.
                            </p>
                        </div>

                    </div>
                </div>
            </div>
        </motion.div>
    );
};

export default TermsAndConditionsPrivate;
