import React, {useState} from 'react';
import s from './BankTransfer.module.css'
import BankTransferStepOne from "./BankTransferStepOne/BankTransferStepOne";
import BankTransferStepTwo from "./BankTransferStepTwo/BankTransferStepTwo";

type BankTransferPropsType = {
    isButtonDisable: boolean | undefined
    code: string
    withdrawalStatus: string
}

const BankTransfer = (props: BankTransferPropsType) => {

    const [current, setCurrent] = useState(0);
    const [transferData, setTransferData] = useState({})

    const steps = [
        {
            content: <BankTransferStepOne setCurrent={setCurrent}
                                          current={current}
                                          setTransferData={setTransferData}
                                          isButtonDisable={props.isButtonDisable}

            />
        },
        {
            content: <BankTransferStepTwo setCurrent={setCurrent}
                                          current={current}
                                          transferData={transferData}
                                          code={props.code}
                                          withdrawalStatus={props.withdrawalStatus}
            />
        },
    ];

    return (
        <>
            <div className={s.wrapper}>
                {steps[current].content}
            </div>
        </>
    );
};

export default BankTransfer;
