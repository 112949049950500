import React, {Dispatch, SetStateAction, useState} from 'react';
import s from "./ChangePhone.module.css";
import MyBtn from "../ui/MyBtn/MyBtn";
import CustomModal from "../ui/CustomModal/CustomModal";
import MyInput from "../ui/MyInput/MyInput";
import {useFormik} from "formik";
import TransparentButton from "../ui/TransparentButton/TransparentButton";
import Icon from "@ant-design/icons";
import {ReactComponent as Back} from "../../assets/img/personalAccount/back.svg";
import info from "../../assets/img/personalAccount/info-circle.svg";
import {useAppDispatch, useAppSelector} from "../../hooks/redux";
// import {
//     getNewEmailThunk,
//     sendConfirmationCodeThunk,
//     sendNewEmailThunk,
//     setTokenState
// } from "../../store/reducers/ActionCreators";
// import {setDisabled} from "../../store/reducers/UserSlice";
import * as yup from "yup";

type PropsType = {
    setIsChangePhoneProcess: Dispatch<SetStateAction<boolean | null>>
    isChangePhoneProcess: boolean | null
}

const validationSchemaEmail = yup.object({
    email: yup.string().email("invalid format").required('Required field'),
})

const ChangePhone = (props: PropsType) => {

    const [isVerifyModalOpen, setIsVerifyModalOpen] = useState<boolean | null>(false)
    const [isErrorModalOpen, setIsErrorModalOpen] = useState<boolean | null>(false)
    const [isErrorText, setIsErrorText] = useState('Something went wrong. Please try again later.')
    const [newPhone, setNewPhone] = useState('')
    const dispatch = useAppDispatch()
    const token = useAppSelector(state => state.userReducer.userToken)

    const formikEmail = useFormik({
        initialValues: {
            phone: '',
        },
        enableReinitialize: true,
        validationSchema :validationSchemaEmail,
        onSubmit: (values, formikHelpers) => {
            // dispatch(sendNewEmailThunk(values, token))
            //     .then(() => {
            //         setIsVerifyModalOpen(true)
            //         setNewPhone(values.phone)
            //     })
            //     .catch((e: any) => {
            //         if (e.response.data === 'This email exists, please use other email') {
            //             setIsErrorText('This email exists, please use other email')
            //         }
            //         setIsErrorModalOpen(true)
            //     })
            //     .finally(() => {
            //         dispatch(setDisabled(false))
            //     })
        }
    })

    const formikEmailVerify = useFormik({
        initialValues: {
            code: '',
        },
        onSubmit: (values, formikHelpers) => {
            const tempData = {
                ...values,
                phone: newPhone
            }
            // dispatch(sendConfirmationCodeThunk(tempData, token))
            //     .then(() => {
            //         formikHelpers.resetForm()
            //         dispatch(getNewEmailThunk(token))
            //         setIsVerifyModalOpen(false)
            //         props.setIsChangeEmailProcess(false)
            //     })
            //     .catch((e: any) => {
            //         if (e.response.data === "Invalid authorization data") {
            //             dispatch(setTokenState(false))
            //         } else {
            //             setIsErrorText('Invalid email code')
            //             setIsErrorModalOpen(true)
            //         }
            //         formikHelpers.resetForm()
            //     })
            //     .finally(() => {
            //         dispatch(setDisabled(false))
            //     })
        }
    })

    return (
        <>
            <CustomModal isModalOpen={props.isChangePhoneProcess} setIsModalOpen={props.setIsChangePhoneProcess} type={"info"}>
                <form onSubmit={formikEmail.handleSubmit} className={s.from_wrapper}>
                    <div className={s.iconBlock}>
                        <img src={info} alt=""/>
                    </div>
                    <div className={s.modal_title}>
                        Change your phone number
                    </div>
                    <div className={s.input_block}>
                        <div className={s.input_label}>
                            new Phone Number
                        </div>
                        <MyInput
                            value={formikEmail.values.phone}
                            onChange={formikEmail.handleChange}
                            id={"phone"}
                            name={"phone"}
                            isError={!!formikEmail.errors.phone}
                            touched={formikEmail.touched.phone}
                        />
                        {formikEmail.errors.phone && <div className={s.error_field}>{formikEmail.errors.phone}</div>}
                    </div>
                    <div className={s.buttonsWrapper}>
                        <TransparentButton
                            buttonType={'confirm'}
                            medium
                            style={{width: 180}}
                            title={'Cancel'}
                            onClick={() => props.setIsChangePhoneProcess(false)}
                            icon={<Icon component={() => <Back/>} rev={undefined}/>}
                            isPersonalAccountBtn
                        />
                        <MyBtn
                            buttonType={'confirm'}
                            medium
                            style={{width: 180}}
                            title={'Next'}
                            type={"submit"}
                            isPersonalAccountBtn
                        />
                    </div>
                </form>
            </CustomModal>

            <CustomModal isModalOpen={isVerifyModalOpen} setIsModalOpen={setIsVerifyModalOpen} type={"confirm"}>
                <form onSubmit={formikEmailVerify.handleSubmit} className={s.from_wrapper}>
                    <div className={s.modal_title}>
                        Email authentication
                    </div>
                    <div className={s.modal_subtitle}>
                        Email with confirmation code was sent to your email address <b>{newPhone}</b>
                    </div>
                    <div className={s.input_block}>
                        <div className={s.input_label}>
                            confirmation Code
                        </div>
                        <MyInput
                            value={formikEmailVerify.values.code}
                            onChange={formikEmailVerify.handleChange}
                            id={"code"}
                            name={"code"}
                        />
                    </div>
                    <div className={s.buttonsWrapper}>
                        <TransparentButton
                            buttonType={'confirm'}
                            medium
                            style={{width: 180}}
                            title={'Cancel'}
                            onClick={() => setIsVerifyModalOpen(false)}
                            icon={<Icon component={() => <Back/>} rev={undefined}/>}
                        />
                        <MyBtn
                            buttonType={'confirm'}
                            medium
                            style={{width: 180}}
                            title={'Confirm'}
                            type={"submit"}
                        />
                    </div>
                </form>
            </CustomModal>

            <CustomModal isModalOpen={isErrorModalOpen} setIsModalOpen={setIsErrorModalOpen} type={"error"}>
                <div className={s.from_wrapper}>
                    <div className={s.modal_title}>
                        Oops!
                    </div>
                    <div className={s.modal_subtitle}>
                        {isErrorText}
                    </div>
                    <div className={s.buttonsWrapper}>
                        <MyBtn
                            buttonType={'error'}
                            medium
                            style={{width: 180}}
                            title={'Ok'}
                            onClick={() => setIsErrorModalOpen(false)}
                        />
                    </div>
                </div>
            </CustomModal>
        </>
    );
};

export default ChangePhone;
