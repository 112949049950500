import { useState, useEffect, useRef } from 'react';

const useOutsideClick = (initialValue: boolean) => {
    const [isActive, setIsActive] = useState(initialValue);
    const ref = useRef<any>(null);
    const ref2 = useRef<any>(null);
    const handleClick = (e: any) => {
        e.stopPropagation()

        if (ref2.current && ref2.current.contains(e.target)) {
            setIsActive(true);
            return
        }

        if (ref.current.contains(e.target) && isActive) {
            setIsActive(false);
            return
        }

        if (ref.current && !ref.current.contains(e.target) && isActive) {
            setIsActive(false);
            return
        }
    };

    useEffect(() => {
        document.addEventListener("click", handleClick);
        return () => {
            document.removeEventListener("click", handleClick);
        };
    });

    return { ref, ref2, isActive, setIsActive };
};

export default useOutsideClick;
