import React from 'react';
import s from "./AccountCardMobile.module.css"
import info from "../../../assets/img/personalAccount/information.svg";
import {Popover} from "antd";
import classnames from "classnames";

type PropsType = {
    balance: number
    currency: string
    number: string
    status: string
}

const AccountCardMobile = (props: PropsType) => {
    return (
        <>
            {
                props.status === "Active"
                    ? <div className={s.account_card}>
                        <div className={s.row}>
                            <p className={s.label}>Number</p>
                            <div className={s.flex_popover}>
                                <span className={s.value}>{props.number}</span>
                                <Popover content={"Your internal account number."}
                                         color={'#EEEFEF'}
                                         overlayInnerStyle={{
                                             fontFamily: 'Inter',
                                             width: '280px',
                                             padding: '13px 16px'
                                         }}
                                >
                                    <img src={info} alt=""/>
                                </Popover>
                            </div>
                        </div>
                        <div className={classnames(s.row, s.flex)}>
                            <div className={s.block}>
                                <p className={s.label}>Currency</p>
                                <div className={s.flex_popover}>
                                    <span className={s.value}>{props.currency}</span>
                                </div>
                            </div>
                            <div className={s.block}>
                                <p className={s.label}>Reserved</p>
                                <div className={s.flex_popover}>
                                    <span className={s.value}>0 {props.currency}</span>
                                </div>
                            </div>
                        </div>
                        <div className={classnames(s.flex)}>
                            <div className={s.block}>
                                <p className={s.label}>Available Balance</p>
                                <div className={s.flex_popover}>
                                    <span className={s.value}>{props.balance} {props.currency}</span>
                                </div>
                            </div>
                            <div className={s.block}>
                                <p className={s.label_total}>Total Balance</p>
                                <div className={s.flex_popover}>
                                    <span className={s.value}>{props.balance} {props.currency}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    : <div className={s.account_card}>
                        <div className={s.row}>
                            <p className={s.label}>Number</p>
                            <div className={s.flex_popover}>
                                <span className={s.value_pending}>{props.number}</span>
                                <Popover content={"Your internal account number."}
                                         color={'#EEEFEF'}
                                         overlayInnerStyle={{
                                             fontFamily: 'Inter',
                                             width: '280px',
                                             padding: '13px 16px'
                                         }}
                                >
                                    <img src={info} alt=""/>
                                </Popover>
                            </div>
                        </div>
                        <div className={classnames(s.row, s.flex)}>
                            <div className={s.block}>
                                <p className={s.label}>Currency</p>
                                <div className={s.flex_popover}>
                                    <span className={s.value}>{props.currency}</span>
                                </div>
                            </div>
                        </div>
                        <div className={classnames(s.pending)}>
                            pending approval
                        </div>
                    </div>
            }

        </>
    );
};

export default AccountCardMobile;
