import {motion} from 'framer-motion';
import React, {useEffect, useState} from 'react';
import s from './AutenticationStep.module.css'
import MyBtn from "../../../components/ui/MyBtn/MyBtn";
import {useAppDispatch, useAppSelector} from "../../../hooks/redux";
import {
    finalLoginThunk,
    getKycListThunk,
    getCardInfo,
    getUserAccountListThunk,
    setTokenThunk,
    GetAccoutDetailsByAccountTypeThunk,
    setAccountsListTypesThunk,
    setChosenAccountTypeIdThunk, setSessionIdThunk
} from "../../../store/reducers/ActionCreators";
import {useNavigate} from "react-router-dom";
import EmailAutenticationLogin
    from "../../../components/Autentications/EmailAutentication/EmailAutenticationLogin/EmailAutenticationLogin";
import {setDisabled} from "../../../store/reducers/UserSlice";
import SmsAuntification from "../../../components/Autentications/SmsAuntification/SmsAuntification";
import TwoFa from "../../../components/Autentications/TwoFa/TwoFa";
import {calculateCurrentDate} from "../../../helpers/CalculateDate";
import Steps from '../../../components/ui/Steps/Steps';

const AutenticationStep = (props: any) => {

    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const [error, setError] = useState("")
    const [current, setCurrent] = useState(0);
    const userToken = useAppSelector(store => store.userReducer.userToken)

    useEffect(() => {
        if (userToken !== "") {
            // Выполняем действия, когда userToken обновляется
            dispatch(getUserAccountListThunk(userToken))
                .then((res) => {
                    dispatch(setAccountsListTypesThunk(res.data));
                    if (res.data.length === 2) {
                        props.setAccountsList(res.data);
                        props.setCurrent(2);
                    } else {
                        dispatch(setChosenAccountTypeIdThunk(res.data[0].id))
                        dispatch(GetAccoutDetailsByAccountTypeThunk(userToken, res.data[0].id))
                            .then(() => {
                                navigate('/personal_account/accounts');
                            })
                            .catch((e) => {
                                console.log(e);
                            })
                            .finally(() => {
                                dispatch(setDisabled(false))
                            })
                        ;
                    }
                });
        }
    }, [userToken, dispatch, navigate, props]);

    const sendLoginData = () => {

        const preparedDataWithSessionStart = {
            ...props.dataForSend, sessionStart : calculateCurrentDate()
        }

        dispatch(finalLoginThunk(preparedDataWithSessionStart))
            .then((res) => {
                dispatch(setTokenThunk(res.data.userToken));
                dispatch(setSessionIdThunk(res.data.sessionId));
            })
            .catch((e) => {
                setError(e.response.data)
                dispatch(setDisabled(false))

                if (e.response.data !== 'Invalid email code' //код email верный
                    && props.additionalSettings.phoneVerifyEnabled //верификация телефона включена
                    &&  current === 0) //текущий этап - проверка имейла
                {
                    setError("")
                    setCurrent(1)
                    return
                }

                if (e.response.data !== 'Invalid email code' //код email верный
                    && !props.additionalSettings.phoneVerifyEnabled //верификация телефона выключена
                    && props.additionalSettings.twoFaVerifyEnabled //верификация 2fa включена
                    &&  current !== 2 //текущий этап - не проверка 2fa
                )
                {
                    setError("")
                    setCurrent(2)
                    return
                }

                if (props.additionalSettings.phoneVerifyEnabled //верификация телефона включена
                    && e.response.data !== 'Invalid sms code' //код смс верный
                    && props.additionalSettings.twoFaVerifyEnabled  //верификация 2fa включена
                    && current === 1) //текущий этап - проверка смс
                {
                    setError("")
                    setCurrent(2)
                    return
                }

                if (e.response.data === 'Invalid 2fa code') {
                    setError("Invalid 2fa code")
                }
            })
            .finally(() => {
                dispatch(setDisabled(false))
            })
        ;
    };

    const sendLoginData2 = () => {

        dispatch(finalLoginThunk(props.dataForSend))
            .then((res) => {
                dispatch(setTokenThunk(res.data.userToken))
                dispatch(getUserAccountListThunk(res.data.userToken))
                    .then((res) => {
                        dispatch(setAccountsListTypesThunk(res.data))
                        if (res.data.length === 2) {
                            props.setAccountsList(res.data)
                            props.setCurrent(2)
                        } else {
                            dispatch(GetAccoutDetailsByAccountTypeThunk(userToken, res.data[0].id))
                                .then((res) => {
                                    navigate('/personal_account/accounts')
                                })
                                .catch((e) => {
                                    console.log(e)
                                })
                        }
                    })
                // dispatch(getKycListThunk(res.data.userToken))
                //     .then((kycRes) => {
                //         console.log(kycRes)
                //         @ts-ignore
                //         if (res.data.userData.accountType === 'personal') {
                //             if (kycRes.data.length === 0) {
                //                 navigate('/personal_account/personal/settings')
                //                 dispatch(setDisabled(false))
                //                 return
                //             }
                //             if (kycRes.data[0].status === "Rejected" || kycRes.data[0].status === "Pending") {
                //                 navigate('/personal_account/personal/settings')
                //                 dispatch(setDisabled(false))
                //                 return
                //             }
                //             navigate('/personal_account/accounts')
                //             dispatch(setDisabled(false))
                //         } else {
                //             if (kycRes.data.length === 0) {
                //                 navigate('/personal_account/business/settings')
                //                 dispatch(setDisabled(false))
                //                 return
                //             }
                //             if (kycRes.data[0].status === "Rejected" || kycRes.data[0].status === "Pending") {
                //                 navigate('/personal_account/business/settings')
                //                 dispatch(setDisabled(false))
                //                 return
                //             }
                //             navigate('/personal_account/accounts')
                //             dispatch(setDisabled(false))
                //         }
                //     })
                // dispatch(getCardInfo(res.data.userToken))
            })
            .catch((e) => {
                // if (e.response.data !== 'Invalid email code' && e.response.data !== 'Invalid sms code') {
                //     props.setCurrent(3)
                // }

                setError(e.response.data)
                dispatch(setDisabled(false))

                if (e.response.data !== 'Invalid email code' //код email верный
                    && props.additionalSettings.phoneVerifyEnabled //верификация телефона включена
                    &&  current === 0) //текущий этап - проверка имейла
                {
                    setError("")
                    setCurrent(1)
                    return
                }

                if (e.response.data !== 'Invalid email code' //код email верный
                    && !props.additionalSettings.phoneVerifyEnabled //верификация телефона выключена
                    && props.additionalSettings.twoFaVerifyEnabled //верификация 2fa включена
                    &&  current !== 2 //текущий этап - не проверка 2fa
                )
                {
                    setError("")
                    setCurrent(2)
                    return
                }

                if (props.additionalSettings.phoneVerifyEnabled //верификация телефона включена
                    && e.response.data !== 'Invalid sms code' //код смс верный
                    && props.additionalSettings.twoFaVerifyEnabled  //верификация 2fa включена
                    && current === 1) //текущий этап - проверка смс
                {
                    setError("")
                    setCurrent(2)
                    return
                }

                if (e.response.data === 'Invalid 2fa code') {
                    setError("Invalid 2fa code")
                }

            })
            .finally(() => {
                dispatch(setDisabled(false))
            })
    }

    const steps = [
        {
            content: <EmailAutenticationLogin
                error={error}
                dataForSend={props.dataForSend}
                setDataForSend={props.setDataForSend}
                sendLoginData={sendLoginData}
                returnToLogin={props.setCurrent}
            />
        },
        {
            content: <SmsAuntification
                error={error}
                dataForSend={props.dataForSend}
                setDataForSend={props.setDataForSend}
                sendLoginData={sendLoginData}
            />
        },
        {
            content: <TwoFa
                error={error}
                setDataForSend={props.setDataForSend}
                dataForSend={props.dataForSend}
                sendLoginData={sendLoginData}
            />
        },
    ];

    return (
        <motion.div
            initial={{opacity: 0, scale: 1}}
            animate={{opacity: 1, scale: 1}}
            transition={{duration: 0.5}}
        >
            <div className={s.wrapper}>

                <div className={s.form_wrapper}>
                    {steps[current].content}

                    <div className={s.btn_block}>
                        <div className={s.btn_wrapper}>
                            <MyBtn title={"Authentication"} large isPersonalAccountBtn onClick={() => sendLoginData()}/>
                        </div>
                    </div>
                    {/* <div className={s.forget_credentials}>
                        If you forgot your login credentials, please contact&nbsp;
                        <a href="mailto:support@luxpay.lt">support@luxpay.lt</a>
                    </div> */}
                    <div className={s.back}>
                        back to <span className={s.link} onClick={() => props.setCurrent(0)}>Sign In</span>
                    </div>
                    <div className={s.steps}><Steps quantity={3} activeNum={1} /></div>
                </div>



            </div>

        </motion.div>
    );
};

export default AutenticationStep;
