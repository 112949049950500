import React, {ChangeEvent, useEffect, useState} from 'react';
import s from './MyInput.module.css'
import classnames from "classnames";

type PropsType = {
    password?: boolean
    isError?: any
    id?: string
    name?: string
    value?: any
    placeholder?: string
    onChange?: { (e: ChangeEvent<any>): void;}
    touched?:  any,
    width?: string,
    ref?: any,
    disabled?: boolean
}

const MyInput = (props: PropsType) => {

    const [inputType, setInputType] = useState("text")

    useEffect(() => {
        if (props.password) {
            setInputType("password")
        }
    }, [])


    const changeInputType = (e: any) => {

        e.preventDefault()

        if (inputType === "password") {
            setInputType("text")
        }

        if (inputType === "text") {
            setInputType("password")
        }

    }


    return (
        <div className={s.inputWrapper} style={{width: props.width}}>
            <input type={inputType}
                   readOnly
                   onFocus={(e) => e.target.readOnly = false}
                   className={classnames(s.my_input, {[s.error_input]: !!props.isError})}
                   {...props}
            />
            <div className={props.password ? s.view : s.hidden}>
                <a href="#" className={inputType === "text" ? s.passwordView : s.passwordControl}
                   onClick={(e) => changeInputType(e)}>
                </a>
            </div>

        </div>

    );
}

export default MyInput;
