import React from 'react';
import s from './DetailsForDebitCard.module.css'

const DetailsForDebitCard = () => {
    return (
        <div className={s.coming_soon}>
            Coming soon...
        </div>
    );
};

export default DetailsForDebitCard;
