import React, {useState} from 'react';
import s from './Home.module.css'
import {useNavigate, Link, useLocation} from "react-router-dom";
import {motion} from 'framer-motion';
import classnames from "classnames";
import monitor from '../../../assets/img/monitor.png'
import card from '../../../assets/img/card.png'
import personalIcon from "../../../assets/img/personal_icon.svg";
import businessIcon from "../../../assets/img/business_icon.svg";
import shield from "../../../assets/img/shield_tick.png";
import security from "../../../assets/img/security_safe.png";
import MyBtn from "../../../components/ui/MyBtn/MyBtn";
import {useFormik} from "formik";
import * as yup from "yup";
import {CONTACT_US} from '../../../Constants/RoutesConstants';

const validationSchema = yup.object({
    email: yup.string().email("invalid format").required('Required field'),
})

const Home = () => {
    const navigate = useNavigate();
    const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false)
    const [isErrorModalOpen, setIsErrorModalOpen] = useState(false)

    const emailForm = useFormik({
        initialValues: {
            email: sessionStorage.getItem("email") || "",
        },
        validationSchema,
        onSubmit: async (values, formikHelpers) => {
            sessionStorage.setItem("email", values.email);
            let response = await fetch(process.env.REACT_APP_API_URL + 'api/mailer/sendContactFormData', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8'
                },
                body: JSON.stringify({
                    project: "luxpay",
                    subject: "",
                    data: {
                        email: values.email,
                    }
                })
            });
            navigate(CONTACT_US);
            let result = await response.json();

            if (result === 'ok') {
                setIsSuccessModalOpen(true)
                formikHelpers.resetForm()
            } else {
                setIsErrorModalOpen(true)
            }

        }
    })

    return (
        <motion.div
            initial={{opacity: 0, scale: 1}}
            animate={{opacity: 1, scale: 1}}
            transition={{duration: 0.5}}
            className={s.motion_div}
        >
            <div className={s.main_container}>

                <div className={s.main_content_block}>
                    <div className={s.title_block}>
                        <span className={s.title_color}>LuxPay</span> — your banking world <span
                        className={classnames(s.title_color, s.underline)}>without borders</span>
                    </div>

                    <div className={s.subtitle}>
                        Join LuxPay today and open the door to fast and secure worldwide payments via IBAN, SEPA and
                        SWIFT
                    </div>
                    <form onSubmit={emailForm.handleSubmit}>
                        <div className={s.input_block}>
                            <input onChange={emailForm.handleChange}
                                   type="text"
                                   placeholder={"Please enter your email address"}
                                   className={s.my_input}
                                   id={"email"}
                                   name={"email"}
                            />
                            {emailForm.errors.email && <div className={s.error}>{emailForm.errors.email}</div>}
                            <button className={s.circle_btn} type={"submit"}>
                                <img src="../../../assets/img/arrow_btn.svg" alt=""/>
                            </button>
                        </div>
                    </form>
                </div>

                <div className={s.background_image}></div>
            </div>

            <div className={s.manage_block}>
                <div className={s.manage_text_block}>
                    <div className={s.manage_title}>
                        Manage your money effortlessly
                    </div>
                    <div className={s.manage_subtitle}>
                        Manage your money efficiently, use it flexibly and individually — without any restriction
                    </div>
                </div>
                <div className={s.blocks_wrapper}>
                    <div className={s.online_block}>
                        <img src={monitor} alt=""/>
                        <div className={s.online_bank_title}>
                            Online on internet bank
                        </div>
                        <div className={s.card_text_manage}>
                            Take full control of your finances with our easy-to-use money management solution.
                        </div>
                    </div>
                    <div className={s.cards_block}>
                        <img src={card} alt=""/>
                        <div className={s.online_bank_title}>
                            With a payment card
                        </div>
                        <div className={s.card_text_manage}>
                            Enjoy the freedom of managing your money in easy and transparent way.&nbsp;
                            <a href="/login" className={s.request_card_link}>Request a card</a>
                        </div>
                    </div>
                </div>
            </div>

            <div className={s.dedicated_wrapper}>
                <div className={s.dedicated_block}>
                    <div className={s.dedicated_title}>
                        Dedicated IBAN
                    </div>
                    <div className={s.dedicated_text}>
                        Every customer is provided with a unique European bank account number, which is exclusively
                        assigned to them and ensures secure and convenient banking transactions. It offers customers the
                        ability to easily receive and send funds, make international transactions, and manage their
                        financial activities with peace of mind.
                    </div>
                    <div className={s.dedicated_button_block}>
                        <Link to={"/sign_up"}>
                            <button className={s.dedicated_get_started_btn}>Get started</button>
                        </Link>
                    </div>
                </div>
            </div>

            <div className={s.open_account_block}>
                <div className={s.open_account_title}>
                    Open your own account now!
                </div>

                <div className={s.open_account_cards_block}>
                    <div className={s.open_account_card}>
                        <img src={personalIcon} className={s.icon} alt=""/>
                        <div className={s.card_title}>
                            Personal
                        </div>
                        <div className={s.card_text}>
                            Manage your personal finances your way – the convenient way, the flexible way, the LuxPay
                            way.
                        </div>
                        <Link to={"/sign_up/personal"}>
                            <MyBtn title={"Open Personal Account"} large style={{width: "260px"}}/>
                        </Link>
                    </div>
                    <div className={s.open_account_card}>
                        <img src={businessIcon} className={s.icon} alt=""/>
                        <div className={s.card_title}>
                            Business
                        </div>
                        <div className={s.card_text}>
                            Advance financial operations of your business with our competitive prices and expert
                            service.
                        </div>
                        <Link to={"/sign_up/business"}>
                            <MyBtn title={"Open Business Account"} large style={{width: "260px"}}/>
                        </Link>
                    </div>
                </div>
            </div>

            <div className={s.additional_info_block}>

                <div className={s.map_block}>

                    <div>
                        <div className={s.map_block_title}>
                            Don't be bound by the location — take full advantage of SEPA and SWIFT payments
                        </div>
                        <Link to={"/sign_up"}>
                            <button className={s.open_account_button_transparent}>Open Payment Account</button>
                        </Link>
                    </div>

                    <div className={s.map_background}></div>
                </div>

            </div>

            <div className={s.color_bg}>
                <div className={s.wrapper}>
                    <div className={s.benefits_block}>

                        <div className={s.benefits_blocks_wrapper}>
                            <div className={s.benefit_small}>
                                <div className={s.benefit_title}>Convenient</div>
                                <div className={s.benefit_text}>
                                    Receive or send money to any bank account
                                </div>
                            </div>
                            <div className={s.benefit}>
                                <div className={s.benefit_title}>Quick & easy setup</div>
                                <div className={s.benefit_text}>
                                    Access your funds anytime, anywhere through our online banking platform
                                </div>
                            </div>

                            <div className={s.benefit}>
                                <div className={s.benefit_title}>Secure connection</div>
                                <div className={s.benefit_text}>
                                    Two-factor authentication (2FA) for log-in and payments execution
                                </div>
                            </div>
                            <div className={s.benefit_small}>
                                <div className={s.benefit_title}>Comfortable</div>
                                <div className={s.benefit_text}>
                                    Operate your money online as in any other bank
                                </div>
                            </div>
                        </div>

                        <div className={s.benefits_description}>
                            <div className={s.benefits_description_title}>
                                The <span className={s.benefits_description_title_underline}>benefits</span>
                                <span className={s.benefits_description_title_color}> speak for </span>themselves
                            </div>

                            <div className={s.benefits_description_subtitle}>
                                Open an IBAN account — hassle-free, in no time.
                            </div>
                        </div>

                    </div>

                    <div className={s.protection_block}>
                        <div className={s.data_protection}>
                            <img src={shield} alt=""/>
                            <div className={s.data_protection_title}>
                                Data protection
                            </div>
                            <div className={s.data_protection_text}>
                                LuxPay comply with the principles of data protection requirements under Law on
                                Electronic Money and Electronic Money Institutions, Law on Legal Protection of Personal
                                Data and General Data Protection Regulation. Company is registered in State Data
                                Protection Inspectorate (reg. code P6412).
                            </div>
                        </div>
                        <div className={s.security_title}>
                            <span className={s.benefits_description_title_color}>Enhanced </span>
                            <span className={s.benefits_description_title_underline}>financial security </span>
                            <span className={s.benefits_description_title_color}>& </span>
                            <span className={s.benefits_description_title_underline}>peace of mind</span>
                        </div>
                        <div className={s.money_protection}>
                            <img src={security} alt=""/>
                            <div className={s.data_protection_title}>
                                Your money is safe
                            </div>
                            <div className={s.data_protection_text}>
                                Clients’ funds are stored in the Central Bank as well as in other major banks within EU.
                                According to regulatory requirements clients’ funds are stored in special accounts
                                (separately from LuxPay own funds) and cannot be used for business or lending purposes.
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </motion.div>
    );
};

export default Home;
