import React, {Dispatch, SetStateAction} from 'react';
import s from "./KycPersonalStepOne.module.css";
import personalKyc from "../../../../../../assets/img/personalAccount/personal_kyc.png";
import MyBtn from "../../../../../../components/ui/MyBtn/MyBtn";
import {
    startVerificationProcessThunk
} from "../../../../../../store/reducers/ActionCreators";
import {useAppDispatch, useAppSelector} from "../../../../../../hooks/redux";
import {motion} from 'framer-motion';
import {setDisabled} from "../../../../../../store/reducers/UserSlice";

type PropsType = {
    current: number
    setCurrent: Dispatch<SetStateAction<number>>
}

type KycStatusType = string | null;

const KycPersonalStepOne = (props: PropsType) => {

    const dispatch = useAppDispatch()
    const token = useAppSelector(state => state.userReducer.userToken)
    const accountId = useAppSelector(state => state.userReducer.chosenAccountTypeId)
    const kycStatus: KycStatusType = useAppSelector(state => state.userReducer.user.flags.kycStatus)
    const firstName = useAppSelector(state => state.userReducer.user.userData.firstName)
    const lastName = useAppSelector(state => state.userReducer.user.userData.lastName)
    const companyName = useAppSelector(state => state.userReducer.user.userData.companyName)

    const startVerificationProcess = () => {

        dispatch(startVerificationProcessThunk(token, accountId))
            .then((res) => {
                window.open(res.data.idvUrl, '_blank');
            })
            .finally(() => {
                dispatch(setDisabled(false))
            })
    }

    return (
        <motion.div
            initial={{opacity: 0, scale: 1}}
            animate={{opacity: 1, scale: 1}}
            transition={{duration: 0.5}}
        >
            {(kycStatus === 'Approved' || kycStatus ==='Rejected')
                ? <></>
                : <div className={s.verification_block}>
                    <div className={s.verification_flex}>
                        <img src={personalKyc} alt=""/>
                        <div className={s.title}>KYC for Personal Account</div>
                    </div>
                    <div className={s.button_wrapper}>
                        {/*<MyBtn title={"Verification"} isPersonalAccountBtn onClick={() => startVerificationProcess()}/>*/}
                    </div>
                </div>
            }

            {(kycStatus === 'Approved' || kycStatus ==='Rejected') &&
                <div className={s.questionnaire_list_block}>
                    <div className={s.questionnaire_title}>
                        Questionnaire List
                    </div>
                    <div className={s.questionnaire_table}>
                        <div className={s.table_header}>
                            <div className={s.header_value}>
                                Owner
                            </div>
                            <div className={s.header_value}>
                                Status
                            </div>
                        </div>
                        <div className={s.table_body}>
                            <div className={s.table_row}>
                                <div className={s.row_value}>
                                    <div className={s.name}>
                                        {firstName} {lastName} {companyName}
                                    </div>
                                </div>
                                <div className={s.row_value}>
                                    {kycStatus === 'Rejected' && <div className={s.rejected}>Rejected</div>}
                                    {kycStatus === 'Approved' && <div className={s.approved}>Approved</div>}
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className={s.questionnaire_table_mobile}>

                    </div>
                </div>
            }

        </motion.div>
    );
};

export default KycPersonalStepOne;
