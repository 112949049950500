import React, {Dispatch, SetStateAction, useState} from 'react';
import {useAppDispatch} from "../../hooks/redux";
import {useFormik} from "formik";
import {motion} from "framer-motion";
import s from "./ForgotPassword.module.css";
import MyInput from "../ui/MyInput/MyInput";
import MyBtn from "../ui/MyBtn/MyBtn";
import {Link, useNavigate} from "react-router-dom";
import {resetPasswordThunk} from "../../store/reducers/ActionCreators";
import CustomModal from "../ui/CustomModal/CustomModal";
import modalImg from '../../assets/img/personalAccount/tick-circle.svg'
// import attention from "../../assets/img/attention.svg";

type PropsType = {
    setCurrent: Dispatch<SetStateAction<number>>
}

const ForgotPassword = (props: PropsType) => {

    const dispatch = useAppDispatch()
    // const [is2FaShow, setIs2FaShow] = useState(false)
    const [isModalOpen, setIsModalOpen] = useState<boolean | null>(false);
    const [isAuthSuccess, setIsAuthSuccess] = useState(true)
    const [errorMessage, setErrorMessage] = useState("")
    const navigate = useNavigate()

    const formikForgot = useFormik({
        initialValues: {
            email: '',
            twoFaCode: ''
        },
        onSubmit: async values => {
            dispatch(resetPasswordThunk(values))
                .then((res) => {
                    if (res.data === 'New password send to email') {
                        setIsModalOpen(true)
                    }
                })
                .catch((error) => {
                    // if (error.response.data === "Need 2Fa Code") {
                    //     setIs2FaShow(true)
                    // }
                    setIsAuthSuccess(false)
                    setErrorMessage(error.response.data)

            })
        }
    })

    const closeAndRedirect = () => {
        setIsModalOpen(false)
        props.setCurrent(0)
    }

    return (
        <motion.div
            initial={{opacity: 0, scale: 1}}
            animate={{opacity: 1, scale: 1}}
            transition={{duration: 0.5}}
        >

            <CustomModal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} type='confirm'>
                <div className={s.flex}>
                    <img src={modalImg} alt="modalImg" className={s.modal_icon} />
                    <div className={s.modal_title}>
                        Success!
                    </div>
                    <div className={s.modal_subtitle}>
                        The new password was sent to your email address.
                    </div>
                    <div className={s.button_block}>
                        <MyBtn title={"Ok"} onClick={() => closeAndRedirect()} isPersonalAccountBtn large/>
                    </div>
                </div>
            </CustomModal>

            <div className={s.wrapper}>
                <form onSubmit={formikForgot.handleSubmit} className={s.wrapper_form}>
                    <div className={s.content}>
                        <div className={s.title}>
                            Forgot password
                        </div>
                        {
                            !isAuthSuccess &&
                            <div className={s.error_block}>
                                {/*<img src={attention} alt=""/>*/}
                                <div>
                                    {errorMessage}
                                </div>
                            </div>
                        }
                        <div className={s.subtitle}>
                            Enter your LuxPay account<br/>
                            email address.
                        </div>
                        <div className={s.input_block}>
                            <div className={s.input_label}>
                                Email address
                            </div>
                            <MyInput id="email"
                                     name="email"
                                     isError={formikForgot.errors.email}
                                     value={formikForgot.values.email}
                                     onChange={formikForgot.handleChange}
                            />
                            {formikForgot.errors.email &&
                                <div className={s.error_message}>invalid email address</div>}
                        </div>

                        {/* {
                            is2FaShow
                                ? <div className={s.input_block}>
                                    <div className={s.input_label}>
                                        2FA Token from Google Authenticator
                                    </div>
                                    <MyInput
                                        id="twoFaCode"
                                        name="twoFaCode"
                                        value={formikForgot.values.twoFaCode}
                                        onChange={formikForgot.handleChange}
                                        placeholder={"6 digits"}
                                    />
                                </div>
                                : <></>
                        } */}

                        <div className={s.btn_block}>
                            <MyBtn
                                title={"Continue"}
                                type={"submit"}
                                isPersonalAccountBtn
                            />
                        </div>
                    </div>
                    <div className={s.back}>
                        <div>
                            Back to
                        </div>
                        <Link className={s.link} to={"/login"} onClick={() => props.setCurrent(0)}>Sign In</Link>
                    </div>
                </form>
            </div>

        </motion.div>

    );
};

export default ForgotPassword;
