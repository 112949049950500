import { useRef } from 'react';
import s from "./PhoneCodesInput.module.css";
import "./index.css"
import IntlTelInput from 'react-intl-tel-input';
import 'react-intl-tel-input/dist/main.css';
import { useField } from "formik";
import classnames from "classnames";

type propsType = {
    initCode?: any,
    phonecodes?:any,
    value?: string,
    onChange?: any,
    setCode?: any,
    error?: any,
    id?: any,
    name?: any,
    touched?: any,
    selectable?: boolean,
    onlyCountries?: string[],
    recipientCountry?: string[],
    isRegistration?: boolean,
}

const PhoneCodesInput = (props: propsType) => {

    const inputElement = useRef<any>(null);
    const [field, meta, helpers] = useField(props.name);
    const { setValue, setTouched } = helpers;   

    const handleBlur = () => {
        setTouched(true);
    };

    const onChange = (value: any, fullNumber: any, extension: any) => {
        inputElement.current.tel.focus()

        if (field.value !== fullNumber) {
            setTimeout(() => {
                inputElement.current.tel.setSelectionRange(fullNumber.length, fullNumber.length);
            }, 0);

            setValue(fullNumber);
        }
    }

    return (
        <div>
        <IntlTelInput
            onPhoneNumberChange={(isValid: any, value: any, selectedCountryData: any, fullNumber: any, extension: any) => onChange(value, fullNumber, extension)}
            containerClassName="intl-tel-input"
            onPhoneNumberBlur={handleBlur}
            inputClassName={classnames('iti__tel-input', meta.error && meta.touched ? s.error : '')}
            value={field.value}
            formatOnInit={true}
            autoHideDialCode={true}
            autoPlaceholder={false}
            ref={inputElement}
            fieldName={props.name}
        />
        {meta.error && meta.touched ? (
            <div className={s.error_message}>{meta.error}</div>
        ) : null}
        </div>
    );
};

export default PhoneCodesInput;
