import React, {useEffect, useState} from 'react';
import s from "./ToAnLuxPayStepTwo.module.css";
import {ReactComponent as Back} from "../../../../../assets/img/personalAccount/back.svg";
import MyBtn from "../../../../../components/ui/MyBtn/MyBtn";
import {motion} from "framer-motion";
import Icon from "@ant-design/icons";
import TransparentButton from "../../../../../components/ui/TransparentButton/TransparentButton";
import {useAppDispatch, useAppSelector} from "../../../../../hooks/redux";
import {GetLimitsListThunk, SendTransferThunk} from "../../../../../store/reducers/ActionCreators";
import CustomModal from "../../../../../components/ui/CustomModal/CustomModal";
import {
    PrepareDataForLuxpayTransaction,
} from "../../../../../helpers/PrepareDataForLuxpayTransaction";
import {setDisabled} from "../../../../../store/reducers/UserSlice";

const ToAnLuxPayStepTwo = (props: any) => {

    const dispatch = useAppDispatch()
    const token = useAppSelector(state => state.userReducer.userToken)
    const accountId = useAppSelector(state => state.userReducer.chosenAccountTypeId)

    useEffect(() => {
        dispatch(GetLimitsListThunk(token, props.transferData.accNumberFrom));
    }, [dispatch, token, props.transferData.accNumberFrom]);

    const limitsInfo = useAppSelector(state => state.userReducer.userLimits);
    const [isSuccessPopupOpen, setIsSuccessPopupOpen] = useState<boolean | null>(false)
    const [isErrorPopupOpen, setIsErrorPopupOpen] = useState<boolean | null>(false)
    const [isLimitPopupOpen, setIsLimitPopupOpen] = useState<boolean | null>(false)
    const [limitType, setLimitType] = useState<string | null>('');

    const areLimitsExceeded = () => {
        if (limitsInfo.currentDailyAmount >= limitsInfo.dailyLimit)
        {
            setLimitType('daily')
            setIsLimitPopupOpen(true)
        }
        else if (limitsInfo.currentMonthlyAmount >= limitsInfo.monthlyLimit) {
            setLimitType('monthly')
            setIsLimitPopupOpen(true)
        }
    }

    const sendTransferData = async () => {
        // areLimitsExceeded()
        dispatch(setDisabled(true))
        const tempData = await PrepareDataForLuxpayTransaction(props.transferData, accountId, props.code, props.withdrawalStatus)

        dispatch(SendTransferThunk(token, tempData, accountId))
            .then((res) => {
                if (res.data === 'Created') {
                    setIsSuccessPopupOpen(true)
                } else {
                    setIsErrorPopupOpen(true)
                }
            })
            .finally(() => {
                dispatch(setDisabled(false))
            })

    }

    const closePopupAndRedirect = () => {
        setIsSuccessPopupOpen(false)
        localStorage.removeItem('iberbanco-transfer-form')
        props.setCurrent(0)
    }

    return (
        <motion.div
            initial={{opacity: 0, scale: 1}}
            animate={{opacity: 1, scale: 1}}
            transition={{duration: 0.5}}
        >

            <CustomModal isModalOpen={isSuccessPopupOpen} isNotify setIsModalOpen={setIsSuccessPopupOpen}>
                <div className={s.modal_content}>
                    <div className={s.popup_subtitle}>
                        <span className={s.success}>Success!</span> Your request has been sent successfully.
                    </div>
                    <div className={s.popup_button_block}>
                        <MyBtn title={'Ok'} isPersonalAccountBtn medium onClick={() => closePopupAndRedirect()}/>
                    </div>
                </div>
            </CustomModal>

            <CustomModal type="error" isNotify isModalOpen={isErrorPopupOpen} setIsModalOpen={setIsErrorPopupOpen}>
                <div className={s.modal_content}>
                    <div className={s.popup_subtitle}>
                        <span className={s.error}>Failed!</span> Your request was rejected. Please try again.
                    </div>
                    <div className={s.popup_button_block}>
                        <MyBtn buttonType="error" isPersonalAccountBtn title={"Ok"} onClick={() => {setIsErrorPopupOpen(false)}}/>
                    </div>
                </div>
            </CustomModal>

            <CustomModal type="error" isNotify isModalOpen={isLimitPopupOpen} setIsModalOpen={setIsLimitPopupOpen}>
                <div className={s.modal_content}>
                    <div className={s.popup_subtitle}>
                        <span className={s.error}>Failed!</span> The transaction is impossible due to exceeding {limitType} limit.
                        Please contact <a className={s.popup_link} href='mailto:support@luxpay.lt'>support@luxpay.lt</a>
                        if you have any questions.
                    </div>
                    <div className={s.popup_button_block}>
                        <MyBtn buttonType="error" isPersonalAccountBtn title={"Ok"} onClick={() => setIsLimitPopupOpen(false)} />
                    </div>
                </div>
            </CustomModal>

            <div className={s.title}>
                Transfer Information
            </div>
            <div className={s.data_block}>
                <div className={s.row}>
                    <span className={s.row_name}>Payer account</span>
                    <span className={s.row_value}>
                        {
                            props.transferData.accNumberFrom !== ''
                                ? props.transferData.accNumberFrom
                                : '-'
                        }
                    </span>
                </div>

                <div className={s.row}>
                    <span className={s.row_name}>Recipient’s account</span>
                    <span className={s.row_value}>
                        {
                            props.transferData.accNumberTo !== ''
                                ? props.transferData.accNumberTo
                                : '-'
                        }
                    </span>
                </div>

                <div className={s.row}>
                    <span className={s.row_name}>Recipient’s phone number</span>
                    <span className={s.row_value}>
                        {
                            props.transferData.phoneNumber !== ''
                                ? props.transferData.phoneNumber
                                : '-'
                        }
                    </span>
                </div>

                <div className={s.row}>
                    <span className={s.row_name}>Recipient’s email</span>
                    <span className={s.row_value}>
                        {
                            props.transferData.email !== ''
                                ? props.transferData.email
                                : '-'
                        }
                    </span>
                </div>

                <div className={s.row}>
                    <span className={s.row_name}>Amount</span>
                    <span className={s.row_value}>
                        {
                            props.transferData.amount !== ''
                                ? `${props.transferData.amount} ${props.transferData.transferCurrency}`
                                : '-'
                        }
                    </span>
                </div>

                <div className={s.row}>
                    <span className={s.row_name}>Reference</span>
                    <span className={s.row_value}>
                        {
                            props.transferData.reference !== ''
                                ? props.transferData.reference
                                : '-'
                        }
                    </span>
                </div>

                <div className={s.row}>
                    <span className={s.row_name}>Template name</span>
                    <span className={s.row_value}>
                        {
                            props.transferData.templateName !== ''
                                ? props.transferData.templateName
                                : '-'
                        }
                    </span>
                </div>

                {
                    (props.transferData.invoice !== '' && props.transferData.invoice?.name!== undefined) &&
                    <div className={s.row}>
                        <span className={s.row_name}>Invoice</span>
                        <span className={s.row_value}>
                            {props.transferData.invoice?.name}
                        </span>
                    </div>
                }

            </div>
            <div className={s.buttons_block}>
                <div className={s.button_wrapper}>
                    <TransparentButton
                        icon={<Icon component={() => <Back/>} rev={undefined}/>}
                        large
                        title={"Back"}
                        isPersonalAccountBtn
                        onClick={() => props.setCurrent(0)}/>
                </div>

                <div className={s.button_wrapper}>
                    <MyBtn
                        title={"Transfer"}
                        large
                        isPersonalAccountBtn onClick={() => sendTransferData()}/>
                </div>
            </div>
        </motion.div>
    );
};

export default ToAnLuxPayStepTwo;
