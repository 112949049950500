import React, {useState} from 'react';
import ExchangeStepOne from "./ExchangeStepOne/ExchangeStepOne";
import ExchangeStepTwo from "./ExchangeStepTwo/ExchangeStepTwo";

const CurrencyExchange = () => {

    const [current, setCurrent] = useState(0);
    const [exchangeData, setExchangeData] = useState([])

    const steps = [
        {
            content: <ExchangeStepOne setCurrent={setCurrent}
                                      current={current}
                                      setExchangeData={setExchangeData}

            />
        },
        {
            content: <ExchangeStepTwo setCurrent={setCurrent}
                                      current={current}
                                      exchangeData={exchangeData}
            />
        }
    ]

    return (
        <div>
            {steps[current].content}
        </div>
    )
};

export default CurrencyExchange;
